import React from "react";
import "./HomePage.css"; // Import custom styles
import SchedulerDrawer from "../organisms/Drawer/HomePageDrawer";

const AdminPage = () => (
  <div >

    <div className="content-wrapper">
      <div className="sidebar">
        <SchedulerDrawer />
      </div>

      <div className="main-content">
        <h3>Admin Page</h3>
      </div>
    </div>
  </div>
);

export default AdminPage;

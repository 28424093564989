export const patients = [
    {
      id: 1,
      name: "Jenny Joshi",
      age: 26,
      gender: "Female",
      recareDue: "-",
      schedule: "6/15/2024",
      visits: [
        {
          date: "11/28/2022",
          time: "10:00 AM",
          chair: "Hyg1",
          duration: "60 min",
          status: "Complete",
          clinician: "NS",
          treatment: "CmpEx, 4BW, Pro",
          note: "Note about appointment",
        },
        {
          date: "11/28/2022",
          time: "03:20 PM",
          chair: "Hyg1",
          duration: "60 min",
          status: "Complete",
          clinician: "NS",
          treatment: "CmpEx, 4BW, Pro",
          note: "Note about appointment",
        },
      ],
    },
    {
      id: 2,
      name: "Nini Joshi",
      age: 26,
      gender: "Female",
      recareDue: "-",
      schedule: "6/15/2024",
      visits: [],
    },
    {
      id: 3,
      name: "Rani Joshi",
      age: 26,
      gender: "Female",
      recareDue: "-",
      schedule: "6/15/2024",
      visits: [],
    },
  ];
  
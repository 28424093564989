import { Checkbox } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "src/components/atoms/Button";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import FormActionsBar from "src/components/organisms/FormActionBar";
import { getAllergies } from "src/services/health-info";

const Allergies = ({ patientId }) => {
  const [allergies, setAllergies] = useState([]);
  const { clinicId } = useParams();

  useEffect(() => {
    getAllergies(clinicId, patientId).then(setAllergies);
  }, [clinicId, patientId]);

  const columns = [
    { field: "allergy", title: "Allergy", width: "250px" },
    { field: "symptoms", title: "Symptoms", width: "250px" },
    { field: "status", title: "Status", width: "250px" }
  ];

  return (
    <div style={{ height: "500px" }}>
      <FormActionsBar>
        <Checkbox label={"Inactive"} />
        <Button icon="plus" themeColor="primary">
          Allergies
        </Button>
      </FormActionsBar>
      <DynamicGrid key={allergies.length} columns={columns} rows={allergies} />
      <div
        className="k-form-buttons"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
          marginBottom: "10px"
        }}
      >
        <Button themeColor="primary">Save</Button>
      </div>
    </div>
  );
};

export default Allergies;

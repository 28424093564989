import React, { useEffect, useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { ProcedureGroupService } from "src/services/SettingsService";

const SettingsProcedureGroup = () => {
  const [procedureGroup, setProcedureGroup] = useState([]);

  useEffect(() => {
    const data = ProcedureGroupService.getProcedureGroup();
    setProcedureGroup(data);
  }, []);
  const columns = [
    { field: "group", title: "group" },
    { field: "adaCodes", title: "ADA Codes" },
    { field: "coverage", title: "Coverage" },
    { field: "electronicBenefits", title: "Electronic Benefits" }
  ];

  return (
    <DatatableLayout title="Procedure Group">
      <DynamicGrid
        columns={columns}
        rows={procedureGroup}
        allowSort={false}
        allowFilter={false}
        actions={[]}
      />
    </DatatableLayout>
  );
};

export default SettingsProcedureGroup;

import httpClient from "./http-client";

const BASE_URL = "http://localhost:5093/api/clinic";

const apiService = {
  getClinics: () => {
    return Promise.resolve([
      [
        { text: "Smiles Clinic", id: 1 },
        { text: "Eastern Clinic", id: 2 },
        { text: "Western Clinic", id: 3 }
      ]
    ]);
    // return httpClient("GET", `${BASE_URL}/clinics`);
  },

  getDoctors: (accessToken) => {
    const doc = ["AL", "TC", "TKD", "JP", "AH", "SD", "AA", "AC"];
    return doc;
  }
};

export default apiService;

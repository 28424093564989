import React, { useEffect, useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { CreditCardAndPayService } from "../../../services/SettingsService";
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import Dropdown from "src/components/atoms/Dropdown";
import Label from "src/components/atoms/Label";
import "./SettingsCreditCardAndPay.css";
import InputField from "src/components/atoms/InputField";

const SettingsCreditCardAndPay = () => {
  const [creditCardData, setCreditCardData] = useState([]);

  useEffect(() => {
    const data = CreditCardAndPayService.getCreditCardAndPay();
    setCreditCardData(data);
  }, []);

  const columns = [{ field: "name", title: "Terminal Name" }];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "save", // Delete Icon
      title: "Save",
      onClick: (dataItem) => alert(`Save ${dataItem.template || dataItem.treatment}`)
    }
  ];

  const [transactionFees, setTransactionFees] = useState(1);

  const handleAddTerminal = () => {
    setTerminals([...terminals, { name: "" }]);
  };

  const handleRemoveTerminal = (index) => {
    const updatedTerminals = terminals.filter((_, i) => i !== index);
    setTerminals(updatedTerminals);
  };
  return (
    <DatatableLayout
      title="Credit Card and Pay-by-Text Setup"
      onAdd={() => console.log("Add new terminal")}
      addBtnLabel="Terminal"
      extraBtnLabel={["Preferences"]}
      saveButton="Save"
    >
      <div className="credit-card-and-pay-conainer">
        {/* Left Column */}
        <div className="credit-card-and-pay-left-column">
          <div style={{ marginBottom: "10px" }}>
            <Label>Credit Card Processing</Label>
            <InputField placeholder="800000052932:80039986:uFTiJUIERjcMLNiGyKmgGo232YJ1ekhn" />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <Label>Customer Statements (Pay-By-Text):</Label>
            <InputField placeholder="220419316514:93933e4b252649aa95eb30b7a3dae57b" />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <Label>Customer Statements (Pay-By-Text):</Label>
            <InputField placeholder="220419316514:93933e4b252649aa95eb30b7a3dae57b" />
          </div>
        </div>

        {/* Right Column */}
        <div className="credit-card-and-pay-right-column">
          <h4>Credentials</h4>
          <div className="credit-card-and-pay-right-column-label-plus-component">
            <Label style={{ width: "100px" }}>User name:</Label>
            <InputField style={{ width: "40%" }} placeholder="User name" />
          </div>
          <div className="credit-card-and-pay-right-column-label-plus-component">
            <Label style={{ width: "100px" }}>Password:</Label>
            <InputField style={{ width: "40%" }} placeholder="Password" type="password" />
          </div>
          <div className="credit-card-and-pay-right-column-label-plus-component">
            <Label style={{ width: "100px" }}>Access portal:</Label>
            <InputField style={{ width: "40%" }} placeholder="http://hsdshdjhdlsdsdhijsdskso.com" />
          </div>
          <div className="credit-card-and-pay-right-column-label-plus-component">
            <Label style={{ width: "150px" }}>Transaction Fee (%):</Label>
            <NumericTextBox
              value={transactionFees}
              onChange={(e) => setTransactionFees(e.value)}
              format="n0"
              style={{ width: "40%" }}
            />
          </div>
          <div className="credit-card-and-pay-right-column-label-plus-component">
            <Label style={{ width: "170px" }}>Global Payments Enabled:</Label>
            <Checkbox />
          </div>
        </div>
      </div>
      <DynamicGrid
        columns={columns}
        rows={creditCardData}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default SettingsCreditCardAndPay;

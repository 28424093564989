import React from 'react';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import './IntervalSection.css';

const IntervalSection = ({ value = {}, onChange }) => {
  // Handle change for each NumericTextBox
  const handleIntervalChange = (val, fieldName) => {
    // Update the value while keeping other fields intact
    onChange({
      ...value,  // Retain other values in the `value` object
      [fieldName]: val,  // Only update the field that changed
    });
  };

  return (
    <div className="interval-section">
      <div className="interval-row">
        <div className="interval-item">
          <NumericTextBox
            name="intervalYears"
            value={value?.intervalYears || 0}  // Use default value 0 if not provided
            placeholder="0"
            width="50px"
            min={0}
            onChange={(e) => handleIntervalChange(e.value, 'intervalYears')}
          />
          <span>Year</span>
        </div>
        <div className="interval-item">
          <NumericTextBox
            name="intervalWeeks"
            value={value?.intervalWeeks || 0}
            placeholder="0"
            width="50px"
            min={0}
            onChange={(e) => handleIntervalChange(e.value, 'intervalWeeks')}
          />
          <span>Weeks</span>
        </div>
      </div>

      <div className="interval-row">
        <div className="interval-item">
          <NumericTextBox
            name="intervalMonths"
            value={value?.intervalMonths || 0}
            placeholder="0"
            width="50px"
            min={0}
            onChange={(e) => handleIntervalChange(e.value, 'intervalMonths')}
          />
          <span>Months</span>
        </div>
        <div className="interval-item">
          <NumericTextBox
            name="intervalDays"
            value={value?.intervalDays || 0}
            placeholder="0"
            width="50px"
            min={0}
            onChange={(e) => handleIntervalChange(e.value, 'intervalDays')}
          />
          <span>Days</span>
        </div>
      </div>
    </div>
  );
};

export default IntervalSection;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { HealhIssueService } from "../../../services/AdminService";

const AdminHealthIssue = () => {
  const [healthissues, setHealthIssues] = useState([]);

  useEffect(() => {
    const data = HealhIssueService.getHealthIssues();
    setHealthIssues(data);
  }, []);

  const columns = [
    { field: "issue", title: "Health Issue" },
    { field: "show", title: "Show on From" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  const statuses = ["Active", "Inactive"];

  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Health Issue"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new Health Issue")}
      addBtnLabel="Health Issue"
      extraBtnLabel={["Show on Form", "Hide on Form"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={healthissues}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminHealthIssue;

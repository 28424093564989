import React, { useState } from "react";
import CommonPopup from "src/components/molecules/CommonPopup";
import DynamicForm from "src/components/organisms/DynamicForm";
import DynamicGrid from "src/components/organisms/DynamicGrid";

const InsuranceGrid = ({ show, onClose }) => {
  const [formData, setFormData] = useState({});

  const handleFormSubmit = (data) => {
    console.log("Form submitted with data:", data);
    setFormData(data);
  };

  // Columns for the DynamicGrid
  const gridColumns = [
    { field: "employer", title: "Employer", width: "120px" },
    { field: "insurance", title: "Insurance", width: "150px" },
    { field: "phone", title: "Phone", width: "150px" },
    { field: "address", title: "Address", width: "150px" },
    { field: "city", title: "City", width: "120px" },
    { field: "state", title: "State", width: "100px" },
    { field: "zip", title: "Zip", width: "100px" },
    { field: "groupName", title: "Group Name", width: "120px" },
    { field: "groupId", title: "Group ID", width: "120px" }
  ];

  // Sample data for DynamicGrid rows (to match the screenshot)
  const gridData = [
    {
      employer: "Amazon",
      insurance: "Adventist Teeth",
      phone: "(800) 441-2524",
      address: "85 Broad Street",
      city: "New York",
      state: "NY",
      zip: "10004",
      groupName: "WOFM2584DOM",
      groupId: "10004"
    },
    {
      employer: "Amazon",
      insurance: "Adventist Teeth",
      phone: "(800) 441-2524",
      address: "85 Broad Street",
      city: "New York",
      state: "NY",
      zip: "10004",
      groupName: "WOFM2584DOM",
      groupId: "10004"
    },
    {
      employer: "Amazon",
      insurance: "Adventist Teeth",
      phone: "(800) 441-2524",
      address: "85 Broad Street",
      city: "New York",
      state: "NY",
      zip: "10004",
      groupName: "WOFM2584DOM",
      groupId: "10004"
    },
    {
      employer: "Amazon",
      insurance: "Adventist Teeth",
      phone: "(800) 441-2524",
      address: "85 Broad Street",
      city: "New York",
      state: "NY",
      zip: "10004",
      groupName: "WOFM2584DOM",
      groupId: "10004"
    },
    {
      employer: "Amazon",
      insurance: "Adventist Teeth",
      phone: "(800) 441-2524",
      address: "85 Broad Street",
      city: "New York",
      state: "NY",
      zip: "10004",
      groupName: "WOFM2584DOM",
      groupId: "10004"
    },
    {
      employer: "Amazon",
      insurance: "Adventist Teeth",
      phone: "(800) 441-2524",
      address: "85 Broad Street",
      city: "New York",
      state: "NY",
      zip: "10004",
      groupName: "WOFM2584DOM",
      groupId: "10004"
    }
  ];

  const formStructure = {
    title: "Insurance Plan",
    tabs: [
      {
        name: "Search",
        sections: [
          {
            sectionName: "Search Criteria",
            fields: [
              {
                label: "Insurance",
                name: "insurance",
                type: "input",
                placeholder: "ADA 2024 (Default)"
              },
              {
                label: "Group Name",
                name: "groupName",
                type: "input",
                placeholder: "ADA 2024 (Default)"
              },
              { label: "Employer", name: "employer", type: "input", placeholder: "68195" },
              { label: "Group ID", name: "groupId", type: "input", placeholder: "68195" },
              {
                label: "",
                name: "status",
                type: "checkboxGroup",
                options: [
                  { label: "Active", value: "active" },
                  { label: "Inactive", value: "inactive" }
                ]
              }
            ],
            sectionFooter: {
              right: [
                {
                  type: "button",
                  text: "Clear All",
                  icon: "clear",
                  onClick: () => setFormData({})
                }
              ]
            }
          },
          {
            customSection: (
              <DynamicGrid
                columns={gridColumns}
                rows={gridData}
                allowCheckable={true}
                noRecordMessage="No insurance plans available"
              />
            )
          }
        ]
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Insurance Plan">
      <DynamicForm
        tabs={formStructure.tabs}
        handleFormSubmit={handleFormSubmit}
        formData={formData}
        handleInputChange={(value, fieldName) =>
          setFormData((prev) => ({ ...prev, [fieldName]: value }))
        }
      />
    </CommonPopup>
  );
};

export default InsuranceGrid;

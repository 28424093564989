import React, { useState } from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";

const SettingsTreatmentInterval = () => {
  const [data, setData] = useState({
    BitewingCodes: ["Assessment of Patient"],
    PanoFMXCodes: ["Assessment of Patient"],
    ExamCodes: ["Assessment of Patient"],
    ProphyCodes: ["Assessment of Patient"],
    FluorideCodes: ["Assessment of Patient"],
    SealantCodes: ["Assessment of Patient"],
    CrownCodes: ["Assessment of Patient"],
    SRPCodes: ["Assessment of Patient"]
  });

  const options = ["Assessment of Patient", "Treatment Plan", "Follow-Up"];

  const handleChange = (key, values) => {
    setData({ ...data, [key]: values });
  };

  return (
    <DatatableLayout title="Treatment Interval" saveButton="Save">
      <div style={{ padding: "20px", color: "white" }}>
        {Object.keys(data).map((key) => (
          <div
            key={key}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px"
            }}
          >
            <label
              style={{
                width: "170px",
                fontWeight: "bold",
                marginRight: "10px"
              }}
            >
              {key.replace(/([A-Z])/g, " $1").trim()}:
            </label>
            <MultiSelect
              data={options}
              value={data[key]}
              onChange={(e) => handleChange(key, e.value)}
              placeholder="Select codes..."
              style={{ width: "100%" }}
            />
          </div>
        ))}
      </div>
    </DatatableLayout>
  );
};

export default SettingsTreatmentInterval;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { DepositSlipService } from "../../../services/AdminService";

const AdminDepositSlips = () => {
  const [depositSlips, setDepositSlips] = useState([]);

  useEffect(() => {
    const data = DepositSlipService.getDepositSlips();
    setDepositSlips(data);
  }, []);

  const columns = [
    { field: "date", title: "Date" },
    { field: "type", title: "Type" },
    { field: "payer", title: "Payer" },
    { field: "method", title: "Method" },
    { field: "payment", title: "Payment" }
  ];

  return (
    <DatatableLayout
      title="Deposit Slip"
      onAdd={() => console.log("Add new Deposit Slip")}
      addBtnLabel="Deposit Slip"
    >
      <DynamicGrid columns={columns} rows={depositSlips} allowSort={false} allowFilter={false} />
    </DatatableLayout>
  );
};

export default AdminDepositSlips;

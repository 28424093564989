import React, { useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";

const SettingsOfficeDocument = () => {
  const [status, setStatus] = useState("Active");

  const folderData = [
    { folderName: "Office Policies", inactive: "-" },
    { folderName: "Marketing", inactive: "-" },
    { folderName: "Signs and Flyers", inactive: "-" },
    { folderName: "Payroll", inactive: "-" },
    { folderName: "Admin 1231", inactive: "-" },
    { folderName: "Employee Docs", inactive: "-" },
    { folderName: "www", inactive: "-" },
    { folderName: "test", inactive: "-" },
    { folderName: "test", inactive: "-" }
  ];

  const columns = [
    { field: "folderName", title: "folderName" },
    { field: "inactive", title: "inactive" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];
  return (
    <DatatableLayout
      title="Office Documents"
      statusOptions={["Active", "Inactive"]}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new chair")}
      addBtnLabel="Folder"
      saveButton="Save"
    >
      <DynamicGrid
        columns={columns}
        rows={folderData}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default SettingsOfficeDocument;

import React from "react";
import DynamicGrid from "src/components/organisms/DynamicGrid";

const NonEditableCell = (props) => {
  return <td>{props.dataItem[props.field]}</td>;
};

const CoverageTab = {
  name: "Coverage",
  layout: "coverage",
  sections: [
    {
      sectionName: "Coverage Period",
      boxLayout: "first",
      fields: [
        { label: "Calendar", name: "calendar", type: "checkbox" },
        { label: "Month", name: "month", type: "numerictextbox", icon: "percent" }
      ]
    },
    {
      sectionName: "Treatment Intervals",
      boxLayout: "second",
      fields: [
        {
          label: "BWs",
          name: "bws",
          type: "numerictextbox",
          icon: "percent",
          unitOptions: ["Months", "Years"]
        },
        {
          label: "Pano/FMX",
          name: "panoFmx",
          type: "numerictextbox",
          icon: "percent",
          unitOptions: ["Per Year"]
        },
        {
          label: "Exams",
          name: "exams",
          type: "numerictextbox",
          icon: "percent",
          unitOptions: ["Per Year"]
        }
      ]
    },
    {
      customSection: (
        <div className="patient-family-section">
          <DynamicGrid
            editField="inEdit"
            columns={[
              {
                field: "label",
                title: " ",
                width: "150px",
                editable: false,
                cell: NonEditableCell
              }, // Empty header for label column
              { field: "patient", title: "Patient", width: "150px", editor: "numeric" },
              { field: "family", title: "Family", width: "150px", editor: "numeric" }
            ]}
            rows={[
              {
                label: "Annual Coverage",
                patient: 200,
                family: 0,
                inEdit: true
              },
              {
                label: "General Deductible",
                patient: 50,
                family: 0,
                inEdit: true
              },
              {
                label: "Fluoride Age Limit",
                patient: 0,
                family: 0,
                inEdit: true
              }
            ]}
            noRecordMessage="No patient or family data available"
          />
        </div>
      )
    }
  ]
};

export default CoverageTab;

import React, { useEffect, useState } from "react";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import { PatientFormService } from "src/services/SettingsService";

const SettingsPatientFormAutomation = () => {
  const [newformAutomation, setPatientFormAutomation] = useState([]);

  useEffect(() => {
    const data = PatientFormService.getPatientFormAutomation();
    setPatientFormAutomation(data);
  }, []);

  const columns = [
    { field: "scenario", title: "Scenario" },
    { field: "form", title: "Form" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];
  return (
    <DatatableLayout
      title="Patient Form Automation"
      onAdd={() => console.log("Add new visit")}
      addBtnLabel="Scenario"
    >
      <DynamicGrid
        columns={columns}
        rows={newformAutomation}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default SettingsPatientFormAutomation;

import React, { useState } from "react";
import { Switch, Checkbox, Input } from "@progress/kendo-react-inputs";
import NavbarTemplate from "src/components/templates/NavbarTemplate";
import "./PatientPortalAndCheckIn.css";
import ActionButton from "src/components/atoms/ActionButton";
import Label from "src/components/atoms/Label";
import Dropdown from "src/components/atoms/Dropdown";

const PatientPortalAndCheckIn = () => {
  const [checkin, setCheckIn] = useState("Select Check-In Scenario");
  const checkInScenarios = ["Select Check-In Scenario", "Option 1", "Option 2"];

  return (
    <NavbarTemplate>
      <div className="check-in-container">
        <h3>Patient Portal and Check-In</h3>
        <div className="patient-portal-check-in">
          {/* Patient Portal Section */}
          <div className="patient-portal">
            <p>Patient Portal</p>
            <div className="patient-portal-check-in-label-plus-component">
              <Label>Hide Billing:</Label>
              <Switch onLabel="Yes" offLabel="No" />
            </div>
            <div className="patient-portal-check-in-label-plus-component">
              <Label>Hide Visits:</Label>
              <Switch onLabel="Yes" offLabel="No" />
            </div>
            <div className="patient-portal-check-in-label-plus-component">
              <Label>Hide Treatment Plans:</Label>
              <Switch onLabel="Yes" offLabel="No" />
            </div>
            <div className="patient-portal-check-in-label-plus-component">
              <Label>Hide Membership Plans:</Label>
              <Switch onLabel="Yes" offLabel="No" />
            </div>
            <div>
              <Label>Add New Patients to Patient Portal:</Label>
              <Checkbox style={{ width: "20px" }} />
            </div>
          </div>

          {/* Patient Check-In Section */}
          <div className="patient-portal">
            <p>Patient Check-In</p>
            <div className="patient-portal-check-in-label-plus-component">
              <Label>Check-In Scenario:</Label>
              <Dropdown options={checkInScenarios} onChange={setCheckIn} value={checkin} />
            </div>
            <div className="patient-portal-check-in-label-plus-component">
              <Label>Merge Fields:</Label>
              <Dropdown options={checkInScenarios} onChange={setCheckIn} value={checkin} />
            </div>
            <Input placeholder=" " style={{ marginLeft: "1rem" }} />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="patient-portal-check-in-actions">
          <ActionButton primary={true}>Save Message</ActionButton>
          <ActionButton primary={true}>Save and Close</ActionButton>
        </div>
      </div>
    </NavbarTemplate>
  );
};

export default PatientPortalAndCheckIn;

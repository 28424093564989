import React, { useState, useEffect } from "react";
import DynamicGrid from "../../../organisms/DynamicGrid"
import { PatientService } from "../../../../services/patient-service"

const Letters = () => {
  const [letters, setLetters] = useState([]);

  useEffect(() => {
    PatientService.fetchLetters().then(setLetters);
  }, []);

  const columns = [
    { field: "letters", title: "Letters", width: '250px'},
  ]
  
  return <div>
    <DynamicGrid
      columns={columns}
      rows={letters}
      key={letters.length || 0}
    />
  </div>
}
export default Letters
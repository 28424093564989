import React, { useEffect, useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import "./PatientPage.css"; // Import custom styles
import Profile from "./TabComponents/Profile";
import ExamsAndHygiene from "./TabComponents/ExamsAndHygiene";
import PatientBackground from "./TabComponents/PatientBackground";
import Insurance from "./TabComponents/Insurance";
import HealthInfoTabs from "./HealthInfo";
import CommonPopup from "../../molecules/CommonPopup";
import InsurancePlan from "./PopUps/Insurance/InsurancePlan"; // Import AddInsurancePlan component
import AddEditPatient from "./PopUps/AddPatient";
import PatientForms from "./Forms/PatientForms";
import usePatient from "src/hooks/use-patient";
import Conversations from "./Conversations";
import Letters from "./Letters";
import NavbarTemplate from "src/components/templates/NavbarTemplate";
import ActionButton from "src/components/atoms/ActionButton";
import PatientVisitPopup from "./PopUps/PatientVisit";
import FillChairHygieneRecarePopup from "./PopUps/HygineRecare";
import NoteTemplatePopup from "./PopUps/NoteTemplates";
import AlertListComponent from "./PopUps/AlertListComponent";
import usePopupVisibility from "src/hooks/usePopupVisibility"; // Import usePopupVisibility hook
import { patients } from "../PatientData"; // Adjust path as necessary
import { useDispatch } from "react-redux";
import { fetchPatient } from "src/store/actions/patientAction";
import PatientCard from "./PatientPanel/PatientCard";

const existingInsuranceData = {
  insurance: "Provident Life And Accident Insurance Company",
  phone: "800-835-8670",
  address: "P.O. Box 27100",
  city: "Phoenix",
  state: "AZ",
  zip: "85061",
  note: "This insurance plan covers a wide range of dental services, including preventive care and restorative procedures.",
  inactive: false,
  employer: "Amazon",
  groupName: "Amazon Employees Group",
  groupId: "12377",
  participation: "Private",
  inNetworkFees: "Private",
  outNetworkFees: "None",
  coPayFees: "Standard",
  benefitsCoordination: "CoPay",
  subscriberName: "John Doe",
  subscriberId: "S123456",
  eligibilityStatus: "Active",
  effectiveDate: new Date("2023-01-01"),
  claimNumber: "C123456789",
  claimStatus: "Processed"
};
const existingPatientData = {
  // Demographics Section
  preferredOffice: { label: "Smiles Clinic", value: "smiles" }, // Preferred Office - ID or value of the selected office
  middleName: "James", // Middle Name
  type: "regular", // Patient Type (e.g., Regular, VIP)
  birthdate: new Date("1985-07-20"), // Birthdate in Date format

  // Family Section
  guarantor: "Jane Doe", // Guarantor Name
  self: true, // Checkbox to indicate if the patient is self-responsible

  // Address Section
  street: "123 Main St", // Street Address
  city: "Los Angeles", // City
  state: "CA", // State
  postalCode: "90001" // Postal Code
};

const PatientPage = ({ patientId }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { popups, openPopup, closePopup } = usePopupVisibility(); // Using the hook for managing popups

  const dispatch = useDispatch();

  const patient = usePatient();
  //  const selectedPatient = useSelector(selectPatient);
  console.log("selectedPatient", patient);
  useEffect(() => {
    // Dispatch fetchPatient action on component mount
    dispatch(fetchPatient(patientId));
  }, [dispatch, patientId]);

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  const onHealthInfoClose = () => {
    setSelectedTab(0);
  };

  // Rendering different buttons for each tab and showing relevant popups
  const renderTabButtons = () => {
    if (selectedTab === 0) {
      return (
        <>
          <ActionButton icon="edit" onClick={() => openPopup("editPatient")}>
            Edit
          </ActionButton>
          <ActionButton icon="delete">Delete</ActionButton>
        </>
      );
    } else if (selectedTab === 1) {
      return (
        <>
          <ActionButton icon="calendar" onClick={() => openPopup("patientVisit")}>
            All Visit
          </ActionButton>
          <ActionButton icon="plus" onClick={() => openPopup("hygieneRecare")}>
            Recare
          </ActionButton>
        </>
      );
    } else if (selectedTab === 2) {
      return (
        <>
          <ActionButton icon="chat" onClick={() => openPopup("noteTemplate")}>
            Note template
          </ActionButton>
          <ActionButton icon="bell" onClick={() => openPopup("AlertListComponent")}>
            Alert
          </ActionButton>
        </>
      );
    } else {
      return (
        <>
          <ActionButton icon="edit" onClick={() => openPopup("editInsurancePlan")}>
            Edit
          </ActionButton>
          <ActionButton icon="plus" onClick={() => openPopup("addInsurancePlan")}>
            Add Plan
          </ActionButton>
        </>
      );
    }
  };

  return (
    <NavbarTemplate>
      {/* Main content */}
      <PatientCard patient={patient}/>
      <div className="patient-page-main-content">
        <div className="tab-buttons-container">
          <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
            <TabStripTab title="Profile" />
            <TabStripTab title="Exams and Hygiene" />
            <TabStripTab title="Patient Background" />
            <TabStripTab title="Insurance" />
            <TabStripTab title="Health Info" />
            <TabStripTab title="Conversations" />
            <TabStripTab title="Letters" />
            <TabStripTab title="Patient Forms" />
          </TabStrip>
          <div className="patient-tab-buttons">{renderTabButtons()}</div>
        </div>

        {/* Tab Content */}
        <div className="patient-tab-content">
          {selectedTab === 0 && <Profile />}
          {selectedTab === 1 && <ExamsAndHygiene />}
          {selectedTab === 2 && <PatientBackground />}
          {selectedTab === 3 && <Insurance />}
          {selectedTab === 4 && (
            <CommonPopup title={"Health Info"} onClose={onHealthInfoClose} show>
              <HealthInfoTabs />
            </CommonPopup>
          )}
          {selectedTab === 5 && (
            <CommonPopup title={"Conversations"} onClose={onHealthInfoClose} show>
              <Conversations />
            </CommonPopup>
          )}
          {selectedTab === 6 && (
            <CommonPopup title={"Letters"} onClose={onHealthInfoClose} show>
              <Letters />
            </CommonPopup>
          )}
          {selectedTab === 7 && (
            <CommonPopup
              title={`Patient Forms For ${patient?.name}`}
              onClose={onHealthInfoClose}
              show
            >
              <PatientForms />
            </CommonPopup>
          )}
        </div>
      </div>

      {/* Popups */}
      {popups.editPatient && (
        <AddEditPatient
          show={popups.editPatient}
          onClose={() => closePopup("editPatient")}
          mode="edit"
          initialData={existingPatientData}
        />
      )}

      {popups.patientVisit && (
        <PatientVisitPopup
          show={popups.patientVisit}
          onClose={() => closePopup("patientVisit")}
          patients={patients}
        />
      )}

      {popups.hygieneRecare && (
        <FillChairHygieneRecarePopup
          show={popups.hygieneRecare}
          onClose={() => closePopup("hygieneRecare")}
        />
      )}

      {popups.noteTemplate && (
        <NoteTemplatePopup show={popups.noteTemplate} onClose={() => closePopup("noteTemplate")} />
      )}

      {popups.AlertListComponent && (
        <AlertListComponent
          show={popups.AlertListComponent}
          onClose={() => closePopup("AlertListComponent")}
        />
      )}

      {popups.addInsurancePlan && (
        <InsurancePlan
          show={popups.addInsurancePlan}
          onClose={() => closePopup("addInsurancePlan")}
        />
      )}

      {popups.editInsurancePlan && (
        <InsurancePlan
          show={popups.editInsurancePlan}
          onClose={() => closePopup("editInsurancePlan")}
          mode="edit"
          initialData={existingInsuranceData}
        />
      )}
    </NavbarTemplate>
  );
};

export default PatientPage;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAlerts } from "src/store/actions/alertAction";
import usePopupVisibility from "src/hooks/usePopupVisibility";
import AlertPopup from "./AlertPopup";
import CommonPopup from "../../../molecules/CommonPopup";
import DynamicForm from "../../../organisms/DynamicForm";
import DynamicGrid from "../../../organisms/DynamicGrid";
import "./Alert.css";

const AlertListComponent = ({ show, onClose }) => {
  const { popups, openPopup, closePopup } = usePopupVisibility();
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alerts?.alerts) || [];
  const [selectedAlert, setSelectedAlert] = useState(null); // State to store selected alert for editing
  const [selectedRows, setSelectedRows] = useState([]);

  React.useEffect(() => {
    dispatch(fetchAlerts());
  }, [dispatch]);

  const columns = [
    { field: "patient", title: "Patient" },
    { field: "alertText", title: "Alert" },
    { field: "paused", title: "Paused" }
  ];

  const gridActions = [
    {
      icon: "edit",
      onClick: (alert) => {
        setSelectedAlert(alert); // Set the selected alert data
        openPopup("AddAlert"); // Open the popup for editing
      }
    }
  ];
  const handleSelectedRowsChange = (selectedRowIds) => {
    console.log("selec", selectedRowIds);
    setSelectedRows(selectedRowIds);
  };
  const formStructure = {
    title: "Alerts",
    tabs: [
      {
        header: {
          right: [
            {
              label: "Inactive",
              name: "Inactive",
              type: "checkbox",
              labelPosition: "internal"
            },
            {
              label: "",
              name: "addAlert",
              type: "button",
              icon: "plus",
              text: "Alert",
              className: "add-alert-button",
              onClick: () => {
                setSelectedAlert(null); // Clear selected alert data when adding a new alert
                openPopup("AddAlert");
              }
            }
          ]
        },
        sections: [
          {
            customSection: (
              <DynamicGrid
                columns={columns}
                rows={alerts}
                actions={gridActions}
                allowCheckable={true}
                onSelectedRowsChange={handleSelectedRowsChange}
              />
            )
          }
        ]
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Fast Fill" className="alertList">
      <DynamicForm tabs={formStructure.tabs} formData={{}} handleFormSubmit={() => {}} />
      {popups.AddAlert && (
        <AlertPopup
          show={popups.AddAlert}
          onClose={() => {
            closePopup("AddAlert");
            setSelectedAlert(null); // Clear selected alert data on close
          }}
          onSubmit={(data) => {
            if (selectedAlert) {
              // Handle edit submission
              // Dispatch an action to update the alert, e.g., dispatch(updateAlert(data))
            } else {
              // Handle add submission
              // Dispatch an action to add a new alert, e.g., dispatch(addAlert(data))
            }
            closePopup("AddAlert");
          }}
          initialData={selectedAlert} // Pass selected alert data to AlertPopup for editing
        />
      )}
    </CommonPopup>
  );
};

export default AlertListComponent;

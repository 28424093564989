import React, { useState, useRef } from "react";
import DynamicForm from "../../../organisms/DynamicForm";
import DynamicGrid from "../../../organisms/DynamicGrid";
import CommonPopup from "../../../molecules/CommonPopup";
import { useGridPrint, useGridExportToExcel } from "src/hooks/useGridActions";

const FillChairHygieneRecarePopup = ({ show, onClose }) => {
  const [formData, setFormData] = useState({});
  const gridRef = useRef(null);

  const handlePrint = useGridPrint(gridRef);
  const handleExportToExcel = useGridExportToExcel(gridRef);

  const gridData = [
    {
      id: 1,
      date: "10/31/2024",
      patient: "Jenny Joshi",
      clinician: "NS",
      age: 29,
      type: "Prophy",
      interval: "5 mo, 1 day",
      reminders: "-",
      followUp: "-",
      mobile: "(916) 883-3675",
      note: "-"
    }
  ];

  const columns = [
    { field: "date", title: "Date", width: "120px" },
    { field: "patient", title: "Patient", width: "120px" },
    { field: "clinician", title: "Clinician", width: "80px" },
    { field: "age", title: "Age", width: "60px" },
    { field: "type", title: "Type", width: "80px" },
    { field: "interval", title: "Interval", width: "100px" },
    { field: "reminders", title: "Reminders", width: "80px" },
    { field: "followUp", title: "Follow Up", width: "80px" },
    { field: "mobile", title: "Mobile", width: "120px" },
    { field: "note", title: "Note", width: "100px" }
  ];

  const actionButtons = [
    {
      title: "Conversation",
      icon: "conversation",
      onClick: () => console.log("Conversation clicked")
    },
    { title: "Mailer", icon: "envelope", onClick: () => console.log("Mailer clicked") },
    { title: "Text", icon: "sms", onClick: () => console.log("Text clicked") },
    { title: "Export to Excel", icon: "file-excel", onClick: handleExportToExcel },
    { title: "Print", icon: "print", onClick: handlePrint }
  ];

  const formStructure = {
    title: "Hygiene Recare",
    tabs: [
      {
        name: "Hygiene Recare",
        sections: [
          {
            sectionName: "Criteria",
            fields: [
              {
                label: "Reminders",
                name: "reminders",
                type: "select",
                options: [{ label: "Status", value: "status" }]
              },
              {
                label: "Clinician or Dept",
                name: "clinicianDept",
                type: "select",
                options: [{ label: "Status", value: "status" }]
              },
              { label: "Dates", name: "dates", type: "daterangepicker" },
              {
                label: "Family Conversations",
                name: "familyConversations",
                type: "checkbox",
                labelPosition: "internal"
              },
              {
                name: "search",
                type: "searchbutton",
                icon: "search",
                onClick: () => console.log("Search clicked")
              }
            ]
          },
          {
            sectionHeader: {
              right: actionButtons.map((action) => ({
                type: "button",
                text: action.title,
                icon: action.icon,
                onClick: action.onClick
              }))
            },
            customSection: (
              <DynamicGrid ref={gridRef} columns={columns} rows={gridData} allowCheckable={true} />
            )
          }
        ]
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Hygiene Recare">
      <DynamicForm
        tabs={formStructure.tabs}
        handleFormSubmit={(data) => setFormData(data)}
        formData={formData}
        handleInputChange={(value, fieldName) =>
          setFormData((prev) => ({ ...prev, [fieldName]: value }))
        }
      />
    </CommonPopup>
  );
};

export default FillChairHygieneRecarePopup;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { OfficeFormService, FormPacketService } from "../../../services/AdminService";

const AdminFormList = () => {
  const [officeForms, setOfficeForms] = useState([]);
  const [formPackets, setFormPackets] = useState([]);
  // Sample data for Office Forms and Form Packets
  useEffect(() => {
    const data = OfficeFormService.getOfficeForms();
    setOfficeForms(data);
  }, []);

  useEffect(() => {
    const data = FormPacketService.getFormPackets();
    setFormPackets(data);
  }, []);

  const statuses = ["Active", "Inactive"];

  const [status, setStatus] = useState("Active");

  const columns = [
    { field: "form", title: "" },
    { field: "title", title: "title" }
  ];

  const columnsF = [
    { field: "packet", title: "Form Packet" },
    { field: "forms", title: "Patient Forms" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  const actionsC = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "copy", // Delete Icon
      title: "Copy",
      onClick: (dataItem) => alert(`Copy ${dataItem.template || dataItem.treatment}`)
    }
  ];

  return (
    <DatatableLayout
      title="Office Forms"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new Form")}
      addBtnLabel="New Form"
      extraBtnLabel={["Print", "Custom Font", "Template Form"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={officeForms}
        allowSort={false}
        allowFilter={false}
        actions={actionsC}
      />
      {/* Form Packets Table */}
      <DynamicGrid
        columns={columnsF}
        rows={formPackets}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminFormList;

import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5093/api';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
);

const httpClient = async (url, options = { method: 'GET', body: {}, headers: {} }) => {
  const contentType = options.body instanceof FormData ? 'multipart/form-data' : 'application/json';
  const headers = {
    'Content-Type': contentType,
    ...options.headers,
  };

  try {
    const response = await axiosInstance({ url, method: options.method, data: options.body, headers });
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      window.location.href = '/login';
    }
    throw error;
  }
};

export default httpClient;

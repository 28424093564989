import React from "react";
import SchedulerDrawer from "../organisms/Drawer/HomePageDrawer";

const NavbarTemplate = ({ children, title }) => (
  <div className="content-wrapper">
    <div className="sidebar">
      <SchedulerDrawer />
    </div>
    <main className="content">{children}</main>
  </div>
);

export default NavbarTemplate;

import React, { useState } from "react";
import { Switch, ColorPicker } from "@progress/kendo-react-inputs"; // Import Kendo components
import DynamicGrid from "src/components/organisms/DynamicGrid";
import Dropdown from "src/components/atoms/Dropdown";
import ActionButton from "src/components/atoms/ActionButton";
import "./SettingsDentalAndPerioChart.css";
import NavbarTemplate from "src/components/templates/NavbarTemplate";

const SettingsDentalAndPerioChart = () => {
  const [phaseColors, setPhaseColors] = useState({
    Diagnose: "#FF0000",
    Watch: "#00FF00",
    Complete: "#FFFFFF",
    Existing: "#FFFF00",
    "Refer Out": "#FFA500"
  });

  const [historyColors, setHistoryColors] = useState({
    "Today Visit": "#FF0000",
    "Past Visit": "#FF00FF",
    "Future Visit": "#00FFFF",
    "Follow-Up Visit": "#FFFF00",
    "To Schedule Visit": "#FF4500",
    "Combo Note": "#9400D3",
    Prescription: "#8B0000",
    "Lab Case": "#00FF7F",
    "Lab Slip": "#ADFF2F",
    Referral: "#FFD700",
    Tasks: "#4B0082",
    "Patient Form": "#00CED1",
    Conversation: "#F4A460",
    "Patient Letter": "#2F4F4F"
  });

  const statusOptions = ["Active", "Inactive"];

  const clinicalHistoryData = [
    { view: "Visits, Labs, and Conversations" },
    { view: "Rx" },
    { view: "Completed" },
    { view: "Conversations" },
    { view: "Default" }
  ];

  const clinicalHistoryColumns = [{ field: "view", title: "View" }];

  const diagnosisData = [
    { diagnosis: "Abfractions/Abrasions", abbrev: "AB" },
    { diagnosis: "Aphthous Ulcer", abbrev: "A" },
    { diagnosis: "Fluorosis", abbrev: "F" }
  ];

  const diagnosisColumns = [
    { field: "diagnosis", title: "Diagnosis" },
    { field: "abbrev", title: "Abbrev" }
  ];

  const handlePhaseColorChange = (key, color) => {
    setPhaseColors((prev) => ({ ...prev, [key]: color.value }));
  };

  const handleHistoryColorChange = (key, color) => {
    setHistoryColors((prev) => ({ ...prev, [key]: color.value }));
  };

  const handleStatusChange = (value) => {
    console.log("Status changed:", value);
  };

  const handleAddClick = (label) => {
    console.log(`Add clicked for: ${label}`);
  };

  const handleToggle = (state) => {
    console.log("Perio Chart Toggle:", state);
  };

  return (
    <NavbarTemplate>
      <div className="dental-perio-chart">
        
        {/* Left Section: Dental Phase Colors */}
        <div className="left-section custom-form">
          <div className="section-box">
            <h3 className="section-title">Dental Phase Colors</h3>
            {Object.keys(phaseColors).map((label) => (
              <div className="color-picker-row" key={label}>
                <label>{label}:</label>
                <ColorPicker
                  value={phaseColors[label]}
                  onChange={(color) => handlePhaseColorChange(label, color)}
                />
              </div>
            ))}
          </div>

          {/* Clinical History Colors */}
          <div className="section-box">
            <h3 className="section-title">Clinical History Colors</h3>
            {Object.keys(historyColors).map((label) => (
              <div className="color-picker-row" key={label}>
                <label>{label}:</label>
                <ColorPicker
                  value={historyColors[label]}
                  onChange={(color) => handleHistoryColorChange(label, color)}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Right Section */}
        <div className="right-section custom-form">
          {/* Clinical History View */}
          <div className="section-box">
            <div className="custom-section-right">
              <h3 className="section-title">Clinical History View</h3>
              <div className="header-actions">
                <Dropdown options={statusOptions} value="Active" onChange={handleStatusChange} />
                <ActionButton
                  icon="plus"
                  className="add-button"
                  onClick={() => handleAddClick("View")}
                >
                  View
                </ActionButton>
              </div>
            </div>
            <DynamicGrid
              columns={clinicalHistoryColumns}
              rows={clinicalHistoryData}
              actions={[
                {
                  icon: "edit",
                  title: "Edit",
                  onClick: (dataItem) => console.log("Edit", dataItem)
                },
                {
                  icon: "delete",
                  title: "Delete",
                  onClick: (dataItem) => console.log("Delete", dataItem)
                }
              ]}
            />
          </div>

          {/* Perio Chart Toggle */}
          <div className="toggle-section">
            <label>Perio Chart - Show Prior Exam:</label>
            <Switch onChange={handleToggle} defaultChecked={true} onLabel={"On"} offLabel={"Off"} />
          </div>

          {/* Diagnosis */}
          <div className="section-box">
            <div className="custom-section-right">
              <h3 className="section-title">Diagnosis</h3>
              <div className="header-actions">
                <Dropdown options={statusOptions} value="Active" onChange={handleStatusChange} />
                <ActionButton
                  icon="plus"
                  className="add-button"
                  onClick={() => handleAddClick("Diagnosis")}
                >
                  Diagnosis
                </ActionButton>
              </div>
            </div>
            <DynamicGrid
              columns={diagnosisColumns}
              rows={diagnosisData}
              actions={[
                {
                  icon: "edit",
                  title: "Edit",
                  onClick: (dataItem) => console.log("Edit", dataItem)
                },
                {
                  icon: "delete",
                  title: "Delete",
                  onClick: (dataItem) => console.log("Delete", dataItem)
                }
              ]}
            />
          </div>
        </div>
      </div>
    </NavbarTemplate>
  );
};

export default SettingsDentalAndPerioChart;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { InsuranceCompanyService } from "../../../services/AdminService";

const AdminInsuranceCompanies = () => {
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);

  useEffect(() => {
    const data = InsuranceCompanyService.getInsuranceCompanies();
    setInsuranceCompanies(data);
  }, []);

  const columns = [
    { field: "insurance", title: "Insurance" },
    { field: "phone", title: "Phone" },
    { field: "address", title: "Address" },
    { field: "city", title: "City" },
    { field: "state", title: "State" },
    { field: "zip", title: "Zip" },
    { field: "eclaimid", title: "eClaim ID" },
    { field: "plans", title: "Plans" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  const statuses = ["Active", "Inactive"];

  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Insurance Companies"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new Insurance Company")}
      addBtnLabel="Insurance"
      extraBtnLabel={["Merge"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={insuranceCompanies}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminInsuranceCompanies;

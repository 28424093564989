// src/components/FastFillComponent.js

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFastFills,
  addFastFill,
  updateFastFill,
  deleteFastFill
} from "src/store/actions/fastFillAction";
import DynamicForm from "../../../organisms/DynamicForm";
import DynamicGrid from "../../../organisms/DynamicGrid";
import CommonPopup from "../../../molecules/CommonPopup";
import usePopupVisibility from "src/hooks/usePopupVisibility";
// import AlertPopup from "./AlertPopup";

const FastFillComponent = ({ show, onClose }) => {
  const { popups, openPopup, closePopup } = usePopupVisibility();
  const dispatch = useDispatch();
  useSelector((state) => console.log("fastf", state));

  const fastFills = useSelector((state) => state.fastFills?.fastFills) || [];

  useEffect(() => {
    dispatch(fetchFastFills());
  }, [dispatch]);

  const columns = [
    { field: "treatment", title: "Treatment" },
    { field: "instruction", title: "Instruction" }
  ];

  const gridActions = [
    {
      icon: "edit",
      onClick: (fastFill) => openPopup("AddAlert", fastFill)
    },
    {
      icon: "delete",
      onClick: (fastFill) => dispatch(deleteFastFill(fastFill.id))
    }
  ];

  const formStructure = {
    title: "Fast Fill",
    tabs: [
      {
        header: {
          right: [
            {
              type: "select",
              label: "Status",
              name: "status",
              options: [
                { label: "Active", value: "Active" },
                { label: "Inactive", value: "Inactive" }
              ],
            },
            {
              label: "",
              name: "addFastFill",
              type: "button",
              icon: "plus",
              text: "Fast Fill",
              onClick: () => openPopup("AddAlert")
            }
          ]
        },
        sections: [
          {
            customSection: (
              <DynamicGrid
                columns={columns}
                rows={fastFills}
                actions={gridActions}
                allowCheckable={true}
              />
            )
          }
        ]
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Fast Fill">
      <DynamicForm tabs={formStructure.tabs} formData={{}} handleFormSubmit={() => {}} />
      {popups.AddAlert && (
        <AlertPopup
          show={popups.AddAlert}
          onClose={() => closePopup("AddAlert")}
          onSubmit={(data) => {
            if (data.id) {
              dispatch(updateFastFill(data));
            } else {
              dispatch(addFastFill(data));
            }
            closePopup("AddAlert");
          }}
        />
      )}
    </CommonPopup>
  );
};

export default FastFillComponent;

import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import "./DropDown.css"
const Dropdown = ({ options, value, onChange, label, style }) => (
  <DropDownList
    style={style}
    label={label}
    data={options}
    value={value}
    onChange={(e) => onChange(e.target.value)}
  />
);

export default Dropdown;

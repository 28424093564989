import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { ReferralSourceService } from "../../../services/AdminService";

const AdminReferralSources = () => {
  const [referralSources, setReferralSources] = useState([]);

  useEffect(() => {
    const data = ReferralSourceService.getReferralSources();
    setReferralSources(data);
  }, []);

  const columns = [
    { field: "name", title: "Name" },
    { field: "title", title: "Title" },
    { field: "specialty", title: "Specialty" },
    { field: "patient", title: "Patient" },
    { field: "address", title: "Address" },
    { field: "city", title: "City" },
    { field: "state", title: "State" },
    { field: "phone", title: "Phone" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  const statuses = ["Active", "Inactive"];

  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Referral Sources"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new Referral")}
      addBtnLabel="Referral"
    >
      <DynamicGrid
        columns={columns}
        rows={referralSources}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminReferralSources;

import React, { useState } from "react";
import "./SettingsClinicians.css";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import ActionButton from "src/components/atoms/ActionButton";

const cliniciansData = [
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 0,
    hygienePatients: 0,
    mobile: "(530) 306-7541"
  },
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 12,
    hygienePatients: 12,
    mobile: "(530) 306-7541"
  },
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 15,
    hygienePatients: 15,
    mobile: "(530) 306-7541"
  },
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 15,
    hygienePatients: 15,
    mobile: "(530) 306-7541"
  },
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 15,
    hygienePatients: 15,
    mobile: "(530) 306-7541"
  },
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 15,
    hygienePatients: 15,
    mobile: "(530) 306-7541"
  },
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 15,
    hygienePatients: 15,
    mobile: "(530) 306-7541"
  },
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 0,
    hygienePatients: 0,
    mobile: "(530) 306-7541"
  },
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 12,
    hygienePatients: 12,
    mobile: "(530) 306-7541"
  },
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 15,
    hygienePatients: 15,
    mobile: "(530) 306-7541"
  },
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 15,
    hygienePatients: 15,
    mobile: "(530) 306-7541"
  },
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 15,
    hygienePatients: 15,
    mobile: "(530) 306-7541"
  },
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 15,
    hygienePatients: 15,
    mobile: "(530) 306-7541"
  },
  {
    clinician: "Alfredo Lim",
    initials: "AL",
    username: "Lim",
    dentalPatients: 15,
    hygienePatients: 15,
    mobile: "(530) 306-7541"
  }
];

const cliniciansColumns = [
  { field: "clinician", title: "clinician" },
  { field: "initials", title: "initials" },
  { field: "username", title: "username" },
  { field: "dentalPatients", title: "dentalPatients" },
  { field: "hygienePatients", title: "hygienePatients" },
  { field: "mobile", title: "mobile" }
];

const actions = [
  {
    icon: "edit", // Edit Icon
    title: "Edit",
    onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
  }
];

const SettingsClinicians = () => {
  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Clinicians"
      statusOptions={["Active", "Inactive"]}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new chair")}
      addBtnLabel="Clinicians"
    >
      <DynamicGrid
        columns={cliniciansColumns}
        rows={cliniciansData}
        actions={actions}
        allowSort={false}
        allowFilter={false}
      />
      <div className="actions">
        <label>Transfer Patients to: </label>
        <select className="transfer-select">
          <option value="none">None</option>
          {/* Add more options if needed */}
        </select>
        <ActionButton className="for-dental" primary>
          For Dental
        </ActionButton>
        <ActionButton className="for-hygiene" primary>
          For Hygiene
        </ActionButton>
      </div>
    </DatatableLayout>
  );
};

export default SettingsClinicians;

import React from "react";
import "./ExamsAndHygiene.css";
import DynamicGrid from "src/components/organisms/DynamicGrid";

const examsAndHygieneData = [
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  },
  {
    treatment: "Lorem Ipsum",
    previous: "08/14/2024",
    due: "08/14/2030",
    scheduled: "07/30/2025",
    interval: "5 mo, 1 day",
    followUp: "None",
    comment: "None"
  }
];

const examsAndHygieneColumns = [
  { field: "treatment", title: "treatment" },
  { field: "previous", title: "previous" },
  { field: "due", title: "due" },
  { field: "scheduled", title: "scheduled" },
  { field: "interval", title: "interval" },
  { field: "followUp", title: "followUp" },
  { field: "comment", title: "comment" }
];

const actions = [
  {
    icon: "edit", // Edit Icon
    title: "Edit",
    onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
  },
  {
    icon: "delete", // Delete Icon
    title: "Delete",
    onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
  }
];

const ExamsAndHygiene = () => {
  return (
    <div className="exams-and-hygiene-main-wrapper">
      <DynamicGrid
        columns={examsAndHygieneColumns}
        rows={examsAndHygieneData}
        actions={actions}
        allowSort={false}
        allowFilter={false}
      />
    </div>
  );
};

export default ExamsAndHygiene;

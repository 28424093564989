import React from "react";
import CommonPopup from "../../../../molecules/CommonPopup";
import DynamicForm from "../../../../organisms/DynamicForm";
import EditInsuranceBenefits from "./EditInsuranceBenefits";
import usePopupVisibility from "src/hooks/usePopupVisibility"; // Import the custom hook

const InsurancePlan = ({ show, onClose, mode = "add", initialData = {} }) => {
  // Using the hook to manage multiple popups by name
  const { popups, openPopup, closePopup } = usePopupVisibility();

  const formStructure = {
    title: mode === "edit" ? "Edit Insurance Plan" : "Add Insurance Plan",
    tabs: [
      {
        name: "Insurance",
        sections: [
          {
            sectionName: "Insurance",
            fields: [
              {
                label: "Insurance",
                name: "insurance",
                type: "input",
                placeholder: "Provident Life And Accident..."
              },
              { label: "Phone", name: "phone", type: "input", placeholder: "800-835-8670" },
              { label: "Address", name: "address", type: "input", placeholder: "P.O. Box 27100" },
              { label: "City", name: "city", type: "input", placeholder: "Arizona-AZ" },
              {
                label: "State",
                name: "state",
                type: "select",
                placeholder: "Select State",
                options: [
                  { label: "Arizona-AZ", value: "AZ" },
                  { label: "California-CA", value: "CA" },
                  { label: "Texas-TX", value: "TX" }
                ]
              },
              { label: "Zip", name: "zip", type: "input", placeholder: "85061" },
              { label: "Note", name: "note", type: "textarea", placeholder: "Enter Note" },
              { label: "Inactive", name: "inactive", type: "checkbox" }
            ],
            sectionFooter: {
              right: [
                {
                  type: "button",
                  text: "Delete Plan",
                  onClick: () => alert("Delete Plan clicked"),
                  icon: "delete"
                },
                {
                  type: "button",
                  text: "Insurance Company",
                  onClick: () => alert("Insurance Company clicked"),
                  icon: "plus"
                },
                {
                  type: "button",
                  text: "Insurance Plan",
                  onClick: () => alert("Insurance Plan clicked"),
                  icon: "plus"
                }
              ]
            }
          }
        ]
      },
      {
        name: "Plan Details",
        sections: [
          {
            sectionName: "Plan Details",
            fields: [
              { label: "Employer", name: "employer", type: "input", placeholder: "Amazon" },
              {
                label: "Group Name",
                name: "groupName",
                type: "input",
                placeholder: "Amazon Employees Group"
              },
              { label: "Group ID", name: "groupId", type: "input", placeholder: "12377" }
            ]
          },
          {
            sectionName: "Network and Fees",
            fields: [
              {
                label: "Participation",
                name: "participation",
                type: "select",
                options: [
                  { label: "Private Ins (Regular)", value: "Private" },
                  { label: "Public Ins", value: "Public" }
                ]
              },
              {
                label: "In-Network Fees",
                name: "inNetworkFees",
                type: "select",
                options: [
                  { label: "Private Ins (Regular)", value: "Private" },
                  { label: "None", value: "None" }
                ]
              },
              {
                label: "Out-of-Network Fees",
                name: "outNetworkFees",
                type: "select",
                options: [
                  { label: "None", value: "None" },
                  { label: "Other", value: "Other" }
                ]
              },
              {
                label: "Co-Pay Fees",
                name: "coPayFees",
                type: "select",
                options: [
                  { label: "None", value: "None" },
                  { label: "Standard", value: "Standard" }
                ]
              },
              {
                label: "Benefits Coordination",
                name: "benefitsCoordination",
                type: "select",
                options: [{ label: "Co-Pay Fees", value: "CoPay" }]
              }
            ]
          }
        ]
      },
      {
        name: "Subscriber and Patient",
        sections: [
          {
            sectionName: "Subscriber",
            fields: [
              { label: "Name", name: "subscriberName", type: "input", placeholder: "Jenny Joshi" },
              {
                label: "Subscriber ID",
                name: "subscriberId",
                type: "input",
                placeholder: "1234325"
              },
              { label: "Plan Live", name: "planLiveDate", type: "date" },
              {
                label: "Note",
                name: "subscriberNote",
                type: "textarea",
                placeholder: "Enter Note"
              },
              { label: "Assign Benefits to Clinician", name: "assignBenefits", type: "checkbox" },
              { label: "HIPAA Release", name: "hipaaRelease", type: "checkbox" }
            ]
          },
          {
            sectionName: "Patient",
            fields: [
              {
                label: "Subscriber Relation",
                name: "subscriberRelation",
                type: "input",
                placeholder: "Jenny Joshi"
              },
              {
                label: "Patient ID (if any)",
                name: "patientId",
                type: "input",
                placeholder: "1234325"
              },
              {
                label: "Plan Rank",
                name: "planRank",
                type: "select",
                options: [
                  { label: "0", value: "0" },
                  { label: "1", value: "1" }
                ]
              },
              { label: "Plan Pending", name: "planPending", type: "checkbox" }
            ],
            sectionFooter: {
              right: [{ type: "button", text: "Benefits Used", icon: "document" }]
            }
          }
        ]
      },
      {
        name: "Eligibility and Benefits",
        sections: [
          {
            sectionName: "Eligibility",
            fields: [
              { label: "Eligibility Checked", name: "eligibilityCheckedDate", type: "date" },
              { label: "Benefits Checked", name: "benefitsCheckedDate", type: "date" }
            ],
            sectionFooter: {
              right: [
                {
                  type: "button",
                  text: "Edit Benefits",
                  onClick: () => openPopup("editBenefits"),
                  icon: "edit"
                },
                { type: "button", text: "Check Eligibility", icon: "check" },
                { type: "button", text: "Previous Checks", icon: "back" }
              ]
            }
          }
        ]
      },
      {
        name: "Claims",
        sections: [
          {
            sectionName: "Claims",
            fields: [
              {
                label: "Form",
                name: "claimForm",
                type: "select",
                options: [{ label: "ADA 2024 (Default)", value: "ADA2024" }]
              },
              { label: "Payor ID", name: "payorId", type: "input", placeholder: "68195" }
            ],
            sectionFooter: {
              right: [
                { type: "button", text: "Search", icon: "search" },
                { type: "button", text: "Downgrades", icon: "download" }
              ]
            }
          }
        ]
      }
    ]
  };

  return (
    <>
      <CommonPopup
        show={show}
        onClose={onClose}
        title={mode === "edit" ? "Edit Insurance Plan" : "Add Insurance Plan"}
      >
        <DynamicForm
          tabs={formStructure.tabs}
          handleFormSubmit={(data) => console.log("Form submitted with data:", data)}
          mode={mode}
          initialData={initialData}
        />
      </CommonPopup>

      {/* Edit Insurance Benefits Popup */}
      {popups.editBenefits && (
        <EditInsuranceBenefits
          show={popups.editBenefits}
          onClose={() => closePopup("editBenefits")}
        />
      )}
    </>
  );
};

export default InsurancePlan;

// redux/alertActions.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import AlertService from "src/services/alertService";

export const fetchAlerts = createAsyncThunk(
  "alerts/fetchAlerts",
  async (_, { rejectWithValue }) => {
    try {
      const data = await AlertService.fetchAlerts();
      console.log("alert serv",data)
      return data;
    } catch (error) {
      console.error("Error fetching alerts:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAlert = createAsyncThunk(
  "alerts/addAlert",
  async (alertData, { rejectWithValue }) => {
    try {
      const data = await AlertService.addAlert(alertData);
      return data;
    } catch (error) {
      console.error("Error adding alert:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const editAlert = createAsyncThunk(
  "alerts/editAlert",
  async ({ alertId, updatedData }, { rejectWithValue }) => {
    try {
      const data = await AlertService.updateAlert(alertId, updatedData);
      return data;
    } catch (error) {
      console.error("Error editing alert:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAlert = createAsyncThunk(
  "alerts/deleteAlert",
  async (alertId, { rejectWithValue }) => {
    try {
      await AlertService.deleteAlert(alertId);
      return alertId;
    } catch (error) {
      console.error("Error deleting alert:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

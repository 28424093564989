import React, { useState, useRef } from "react";
import CommonPopup from "../../molecules/CommonPopup";
import DynamicForm from "../../organisms/DynamicForm";
import DynamicGrid from "../../organisms/DynamicGrid";
import { useGridPrint, useGridExportToExcel } from "src/hooks/useGridActions";

const AppointmentsConfirmationPopup = ({ show, onClose }) => {
  const [formData, setFormData] = useState({});
  const gridRef = useRef(null);

  const handlePrint = useGridPrint(gridRef);
  const handleExportToExcel = useGridExportToExcel(gridRef);

  const gridData = [
    {
      id: 1,
      scheduled: "10/08/2024 08:30 AM",
      patient: "Jenny Joshi",
      clinician: "SP",
      followUp: "Unconfirmed",
      treatment: "LimEx #1",
      healthAlert: "-",
      visitNote: "Booked Online.",
      mobile: "(916) 883-3675"
    }
  ];

  const handleSearch = () => console.log("Searching with criteria:", formData);

  const columns = [
    { field: "scheduled", title: "Scheduled", width: "150px" },
    { field: "patient", title: "Patient", width: "120px" },
    { field: "clinician", title: "Clinician", width: "80px" },
    { field: "followUp", title: "Follow Up", width: "120px" },
    { field: "treatment", title: "Treatment", width: "100px" },
    { field: "healthAlert", title: "Health Alert", width: "100px" },
    { field: "visitNote", title: "Visit Note", width: "120px" },
    { field: "mobile", title: "Mobile", width: "120px" } ,  { field: "contactNote", title: "Contact Note", width: "150px" }
  ];

  const actionButtons = [
    { title: "Mailer", icon: "email", onClick: () => console.log("Mailer clicked") },
    { title: "Mass Text", icon: "message", onClick: () => console.log("Mass Text clicked") },
    { title: "Confirm", icon: "check", onClick: () => console.log("Confirm clicked") },
    { title: "Unconfirm", icon: "ban", onClick: () => console.log("Unconfirm clicked") },
    { title: "Export to Excel", icon: "file-excel", onClick: handleExportToExcel },
    { title: "Print", icon: "print", onClick: handlePrint }
  ];

  const formStructure = {
    title: "Appointments Confirmation",
    tabs: [
      {
        name: "Appointments Confirmation",
        sections: [
          {
            sectionName: "Search",
            fields: [
              { label: "Dates", name: "dates", type: "daterangepicker" },
              {
                label: "Order By",
                name: "orderBy",
                type: "select",
                options: [
                  { label: "Status", value: "status" },
                  { label: "Date", value: "date" }
                ]
              },
              {
                label: "Clinician or Dept",
                name: "clinicianOrDept",
                type: "select",
                options: [{ label: "All", value: "all" }]
              },
              { label: "Show Confirmed", name: "showConfirmed", type: "checkbox" },
              { name: "search", type: "searchbutton", icon: "search", onClick: handleSearch }
            ]
          },
          {
            sectionHeader: {
              right: actionButtons.map((action) => ({
                type: "button",
                text: action.title,
                icon: action.icon,
                onClick: action.onClick
              }))
            },
            customSection: (
              <DynamicGrid
                ref={gridRef}
                columns={columns}
                rows={gridData}
                allowCheckable={true}
                actions={[
                  {
                    icon: "check",
                    title: "Confirm",
                    onClick: (dataItem) => console.log("Confirm clicked", dataItem)
                  },
                  {
                    icon: "edit",
                    title: "Edit",
                    onClick: (dataItem) => console.log("Edit clicked", dataItem)
                  },
                  {
                    icon: "calendar",
                    title: "Schedule",
                    onClick: (dataItem) => console.log("Schedule clicked", dataItem)
                  },
                  {
                    icon: "phone",
                    title: "Call",
                    onClick: (dataItem) => console.log("Call clicked", dataItem)
                  }
                ]}
                noRecordMessage="No appointments to display"
              />
            )
          }
        ]
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Appointments Confirmation">
      <DynamicForm
        tabs={formStructure.tabs}
        handleFormSubmit={(data) => setFormData(data)}
        formData={formData}
        handleInputChange={(value, fieldName) =>
          setFormData((prev) => ({ ...prev, [fieldName]: value }))
        }
      />
    </CommonPopup>
  );
};

export default AppointmentsConfirmationPopup;

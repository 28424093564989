import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../../services/clinicService";

export const fetchClinics = createAsyncThunk(
  "clinics/fetchClinics",
  async (_, { getState, rejectWithValue }) => {
    // const { accessToken } = getState().auth;
    try {
      return Promise.resolve([
        { text: "Smiles Clinic", id: 1 },
        { text: "Eastern Clinic", id: 2 },
        { text: "Western Clinic", id: 3 }
      ]);
      // const response = await apiService.getClinics(accessToken);
      // return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDoctors = createAsyncThunk(
  "clinics/fetchDoctors",
  async (_, { getState, rejectWithValue }) => {
    const { accessToken } = getState().auth;
    console.log("getstate", getState());
    try {
      // const resp = await instance.acquireTokenSilent({
      //         ...loginRequest,
      //         account: accounts[0], // Account object from MSAL
      //       });
      //     console.log("att",resp)
      const response = await apiService.getDoctors(accessToken);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

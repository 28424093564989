import React, { useState } from "react";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import { Switch } from "@progress/kendo-react-inputs";
import "./SettingsNotification.css";

const SettingsNotification = () => {
  const [settings, setSettings] = useState({
    all: false,
    patientTextMessage: false,
    patientPortalMessage: false,
    partnerPortalMessage: false,
    bulkBillingPrint: false,
    newTaskAssigned: false,
    onlineVisitBooked: false,
    autoChargeFailure: false,
    visitStatus: {
      newTaskAssigned: false,
      inChair: false,
      checkOut: false
    }
  });

  const handleSwitchChange =
    (field, group = null) =>
    (event) => {
      if (group) {
        setSettings({
          ...settings,
          [group]: {
            ...settings[group],
            [field]: event.value
          }
        });
      } else {
        setSettings({
          ...settings,
          [field]: event.value
        });
      }
    };

  return (
    <DatatableLayout title="Notification" saveButton="Save">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="notification-container">
          <div className="settings-notification-label">
            <label>All</label>
            <Switch
              checked={settings.all}
              onChange={handleSwitchChange("all")}
              onLabel="Yes"
              offLabel="No"
            />
          </div>
          <div className="settings-notification-label">
            <label>Patient Text Message:</label>
            <Switch
              checked={settings.patientTextMessage}
              onChange={handleSwitchChange("patientTextMessage")}
              onLabel="Yes"
              offLabel="No"
            />
          </div>
          <div className="settings-notification-label">
            <label>Patient Portal Message:</label>
            <Switch
              checked={settings.patientPortalMessage}
              onChange={handleSwitchChange("patientPortalMessage")}
              onLabel="Yes"
              offLabel="No"
            />
          </div>
          <div className="settings-notification-label">
            <label>Partner Portal Message:</label>
            <Switch
              checked={settings.partnerPortalMessage}
              onChange={handleSwitchChange("partnerPortalMessage")}
              onLabel="Yes"
              offLabel="No"
            />
          </div>
          <div className="settings-notification-label">
            <label>Bulk Patient Billing Print:</label>
            <Switch
              checked={settings.bulkBillingPrint}
              onChange={handleSwitchChange("bulkBillingPrint")}
              onLabel="Yes"
              offLabel="No"
            />
          </div>
        </div>

        <div className="notification-container">
          <div className="settings-notification-label">
            <label>New Task Assigned:</label>
            <Switch
              checked={settings.newTaskAssigned}
              onChange={handleSwitchChange("newTaskAssigned")}
              onLabel="Yes"
              offLabel="No"
            />
          </div>
          <div className="settings-notification-label">
            <label>Online Visit Booked:</label>
            <Switch
              checked={settings.onlineVisitBooked}
              onChange={handleSwitchChange("onlineVisitBooked")}
              onLabel="Yes"
              offLabel="No"
            />
          </div>
          <div className="settings-notification-label">
            <label>Auto Charge Failure:</label>
            <Switch
              checked={settings.autoChargeFailure}
              onChange={handleSwitchChange("autoChargeFailure")}
              onLabel="Yes"
              offLabel="No"
            />
          </div>
        </div>

        <div className="notification-container-visit-status">
          <p className="notification-visit-status">Visit Status</p>
          <div className="settings-notification-label">
            <label>New Task Assigned:</label>
            <Switch
              checked={settings.visitStatus.newTaskAssigned}
              onChange={handleSwitchChange("newTaskAssigned", "visitStatus")}
              onLabel="Yes"
              offLabel="No"
            />
          </div>
          <div className="settings-notification-label">
            <label>In Chair:</label>
            <Switch
              checked={settings.visitStatus.inChair}
              onChange={handleSwitchChange("inChair", "visitStatus")}
              onLabel="Yes"
              offLabel="No"
            />
          </div>
          <div className="settings-notification-label">
            <label>Check Out:</label>
            <Switch
              checked={settings.visitStatus.checkOut}
              onChange={handleSwitchChange("checkOut", "visitStatus")}
              onLabel="Yes"
              offLabel="No"
            />
          </div>
        </div>
      </div>
    </DatatableLayout>
  );
};

export default SettingsNotification;

import React, { useEffect, useState } from "react";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import { HygieneTrackService } from "src/services/SettingsService";

const SettingsHygieneTrack = () => {
  const [hygieneTrack, setHygieneTrack] = useState([]);
  const [status, setStatus] = useState("Active");

  useEffect(() => {
    const data = HygieneTrackService.getOrthoVisits();
    setHygieneTrack(data);
  }, []);

  const columns = [
    { field: "recare", title: "Recare" },
    { field: "treatment", title: "Treatment" },
    { field: "interval", title: "Interval" },
    { field: "conditions", title: "Conditions" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];
  return (
    <DatatableLayout
      title="Hygiene Track"
      onAdd={() => console.log("Add new visit")}
      addBtnLabel="Recare"
      saveButton="Save"
      onSave={() => console.log("Save new Visit")}
    >
      <DynamicGrid
        columns={columns}
        rows={hygieneTrack}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default SettingsHygieneTrack;

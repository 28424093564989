import { useCallback } from "react";

export const useGridPrint = (gridRef) => {
  const handlePrint = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.printGrid();
    }
  }, [gridRef]);

  return handlePrint;
};

export const useGridExportToExcel = (gridRef) => {
  const handleExportToExcel = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.exportExcel();
    }
  }, [gridRef]);

  return handleExportToExcel;
};

import React, { useState } from "react";
import "./SettingsOffice.css";
import SchedulerDrawer from "../../organisms/Drawer/HomePageDrawer";
import { Checkbox, NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import ActionButton from "src/components/atoms/ActionButton";
import InputField from "src/components/atoms/InputField";
import Dropdown from "src/components/atoms/Dropdown";

const SettingsOffice = () => {
  const [state, setState] = useState("OR");
  const [dentist, setDentist] = useState("Jimy Khan");
  const [clinician, setClinician] = useState("Main Tx Dentist");

  const states = ["OR", "CA", "NY"];
  const dentists = ["Jimy Khan", "Alice Roberts", "John Smith"];
  const clinicians = ["Main Tx Dentist", "Treating Clinician", "Other Clinician"];

  return (
    <div className="content-wrapper">
      <div className="sidebar">
        <SchedulerDrawer />
      </div>
      <div className="main-content">
        <div className="office-form">
          {/* Office Information */}
          <div className="settings-office-section">
            <h3 className="settings-office-section-title">Office</h3>
            <div className="field-group">
              <InputField label="Office" placeholder="Practice Cloud" />
              <InputField label="Email" placeholder="abc@gmail.com" />
              <InputField label="Phone" placeholder="(800) 845-9379" />
            </div>
            <div className="field-group">
              <InputField style={{ width: "190px" }} type="file" />
              <InputField label="Website" placeholder="http://www.google.com" />
              <InputField
                label="Patient Portal Link"
                placeholder="https://practicecloud.net Patient Portal/OfficeLogin"
              />
            </div>
          </div>

          {/* Office Location */}
          <div className="settings-office-section">
            <h3 className="settings-office-section-title">Office Location</h3>
            <div className="field-group">
              <InputField label="Address" placeholder="123 William St" />
              <InputField label="City" placeholder="Myrtle Creek" />
              <Dropdown
                style={{ width: "190px !impportant" }}
                options={states}
                label="State"
                value={state}
                onChange={setState}
              />
            </div>
            <div className="field-group">
              <InputField label="Zip" placeholder="97457" />
            </div>
          </div>

          {/* Claims Billing */}
          <div className="settings-office-section">
            <h3 className="settings-office-section-title">Claims Billing (Optional)</h3>
            <div className="field-group">
              <Checkbox label="Replace Office Location" />
              <InputField label="Address" placeholder="123 William St" />
              <InputField label="City" placeholder="Myrtle Creek" />
            </div>
            <div className="field-group">
              <Dropdown
                style={{ width: "190px !impportant" }}
                options={states}
                label="State"
                value={state}
                onChange={setState}
              />
              <InputField label="Zip" placeholder="97457" />
            </div>
          </div>

          {/* Clinicians and Billing */}
          <div className="settings-office-section">
            <h3 className="settings-office-section-title">Clinicians and Billing</h3>
            <div className="field-group">
              <Dropdown
                style={{ width: "190px" }}
                options={dentists}
                label="Main Tx Dentist"
                value={dentist}
                onChange={setDentist}
              />
              <div className="bill-under-checkboxes">
                <label className="checkbox-group-label">Bill under:</label>
                <Checkbox label="Main Tx Dentist" />
                <Checkbox label="Treating Clinician" />
                <Checkbox label="Other Clinician" />
              </div>
              <Dropdown
                style={{ width: "190px" }}
                options={clinicians}
                label="Bill Under"
                value={clinician}
                onChange={setClinician}
              />
            </div>
          </div>

          {/* Auto Log Off */}
          <div className="settings-office-section">
            <h3 className="settings-office-section-title">Auto Log Off</h3>
            <NumericTextBox label="Log Off if Idle" defaultValue={500} suffix="mins" />
          </div>

          {/* Location Access Restriction */}
          <div className="settings-office-section">
            <h3 className="settings-office-section-title">Location Access Restriction</h3>
            <div className="field-group">
              <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <p>Enable Restrictions: </p>
                <Switch />
              </div>
              <p>This Location's IP: 157.41.242.45</p>
              <ActionButton
                style={{ background: "rgba(167, 176, 69, 1)", color: "black" }}
                icon="add"
              >
                Add IP Address
              </ActionButton>
            </div>
            <div className="field-group">
              <div className="ip-item">
                <InputField placeholder="97124.252.27" />
                <ActionButton>Test This IP</ActionButton>
              </div>
              <div className="ip-item">
                <InputField placeholder="97124.252.27" />
                <ActionButton>Test This IP</ActionButton>
              </div>
            </div>
          </div>

          <ActionButton themeColor="primary" className="save-button">
            Save
          </ActionButton>
        </div>
      </div>
    </div>
  );
};

export default SettingsOffice;

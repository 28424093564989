import React, { useState } from 'react';
import DynamicForm from '../../organisms/DynamicForm';
import DynamicGrid from '../../organisms/DynamicGrid';
import CommonPopup from '../../molecules/CommonPopup';
import { Button } from '@progress/kendo-react-buttons';

const OpenSlotsPopup = ({ show, onClose }) => {

    // Sample data for the grid (empty initially)
    const gridData = [];


    // Define columns for DynamicGrid
    const columns = [
        { field: 'date', title: 'Date', width: '100px' },
        { field: 'time', title: 'Time', width: '100px' },
        { field: 'clinician', title: 'Clinician', width: '120px' },
        { field: 'chair', title: 'Chair', width: '100px' },
        { field: 'timeBlock', title: 'Time Block', width: '120px' },
    ];

    // Define the form structure with Criteria section and grid
    const formStructure = {
        title: 'Open Slots',
        tabs: [
            {
                name: 'Open Slots',
                sections: [
                    // Criteria section
                    {
                        sectionName: 'Criteria',
                        fields: [
                            { label: 'After', name: 'afterDate', type: 'date', placeholder: 'Select Date' },
                            { label: 'Before', name: 'beforeDate', type: 'date', placeholder: 'Select Date' },
                            { label: 'Days', name: 'days', type: 'select', options: [{ label: 'All Days', value: 'all' }] },
                            { label: 'Duration', name: 'duration', type: 'select', options: [{ label: '10 mins', value: '10' }] },
                            { label: 'Times', name: 'times', type: 'select', options: [{ label: 'All Times', value: 'all' }] },
                            { label: 'Clinicians', name: 'clinicians', type: 'select', options: [{ label: 'All Clinicians', value: 'all' }] },
                            { label: 'Chairs', name: 'chairs', type: 'select', options: [{ label: 'All Chairs', value: 'all' }] },
                            { label: 'Time Blocks', name: 'timeBlocks', type: 'select', options: [{ label: 'All Timeblocks', value: 'all' }] },
                        ],
                    },
                    // Open Slots Grid Section
                    {
                        customSection: (
                            <DynamicGrid
                                columns={columns}
                                rows={gridData}
                                allowCheckable={true}
                                noRecordMessage="There are no available slots"
                            />
                        ),
                    },
                ],
            },
        ],
    };

    return (
        <CommonPopup show={show} onClose={onClose} title="Open Slots">
            <DynamicForm
                tabs={formStructure.tabs}

                handleInputChange={(value, fieldName) => setFormData((prev) => ({ ...prev, [fieldName]: value }))}
            />
        </CommonPopup>
    );
};

export default OpenSlotsPopup;

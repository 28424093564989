import React, { useEffect, useState } from "react";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import { DentalVisitService } from "src/services/SettingsService";

const SettingsDentalVisits = () => {
  const [dentalVisits, setDentalVisits] = useState([]);
  const [status, setStatus] = useState("Active");

  useEffect(() => {
    const data = DentalVisitService.getDentalVisits();
    setDentalVisits(data);
  }, []);

  const columns = [
    { field: "visit", title: "Visit" },
    { field: "procedures", title: "Procedures" },
    { field: "duration", title: "Duration" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];
  return (
    <DatatableLayout
      title="Dental Visit"
      statusOptions={["Active", "Inactive"]}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new visit")}
      addBtnLabel="Visit"
      saveButton="Save"
      onSave={() => console.log("Save new Visit")}
    >
      <DynamicGrid
        columns={columns}
        rows={dentalVisits}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default SettingsDentalVisits;

import React from "react";
import { Button as KendoButton } from "@progress/kendo-react-buttons";
import "./ActionButton.css"; // Import custom styles
import { Icon } from "@progress/kendo-react-common";

const ActionButton = ({ icon, children, className, ...props }) => (
  <KendoButton {...props} className={className}>
    {icon && <Icon name={icon} className="button-icon" />} {/* Render Kendo icon */}
    {children}
  </KendoButton>
);

export default ActionButton;

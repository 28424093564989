  // ImportedSpecialBenefitsTab.js
  import React from "react";
  import DynamicGrid from "src/components/organisms/DynamicGrid";


  const gridActions = [
    {
      icon: "edit",
      onClick: (alert) => {
        setSelectedAlert(alert); // Set the selected alert data
        openPopup("AddAlert"); // Open the popup for editing
      }
    }
  ];

  const importedBenefitsColumns = [
    { field: "appliesTo", title: "Applies To", width: "120px" },
    { field: "form", title: "Form", width: "120px" },
    { field: "percent", title: "Percent", width: "80px" },
    { field: "amount", title: "Amount", width: "80px" },
    { field: "units", title: "Units", width: "120px" },
    { field: "duration", title: "Duration", width: "120px" },
    { field: "scope", title: "Scope", width: "80px" },
    { field: "onlyPt", title: "Only Pt", width: "80px" }
  ];

  const importedBenefitsData = [
    {
      appliesTo: "D0150-CmpEx",
      form: "Limitations",
      percent: "-",
      amount: "-",
      units: "1 times per year",
      duration: "CalendarYear",
      scope: "-",
      onlyPt: "-"
    },
    {
      appliesTo: "Implants",
      form: "-",
      percent: "50%",
      amount: "-",
      units: "-",
      duration: "CalendarYear",
      scope: "-",
      onlyPt: "-"
    }
  ];

  const ImportedSpecialBenefitsTab = {
    name: "Imported or Special Benefits",
    sections: [
      {
        sectionHeader: {
          right: [
            { type: "button", text: "Add", icon: "add", onClick: () => console.log("Add clicked") },
            {
              type: "button",
              text: "Delete",
              icon: "delete",
              onClick: () => console.log("Delete clicked")
            }
          ]
        },
        customSection: (
          <DynamicGrid
            columns={importedBenefitsColumns}
            rows={importedBenefitsData}
            allowCheckable={true}
            noRecordMessage="No special benefits to display"
            actions={gridActions}
          />
        ),

      }
    ]
  };

  export default ImportedSpecialBenefitsTab;

import React, { useEffect } from "react";
import Button from "../../atoms/Button";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Icon } from "@progress/kendo-react-common";
import "./HomePageSubHeader.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchDoctors } from "../../../store/actions/clinicAction";
import { useMsal } from "@azure/msal-react";

const HomePageSubHeader = () => {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { doctors } = useSelector((state) => state.clinics);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchDoctors(instance, accounts)).then((x) => {});
    }
  }, [isAuthenticated, dispatch]);

  const opsOptions = [
    { text: "8 ops", value: 1 },
    { text: "6 ops", value: 2 },
  ];  

  return (
    <div className="submenu-container">
      <Button icon="user" className="submenu-button">
        Doctors
      </Button>

      <div className="hyperlinks">
        {doctors?.map((doctor) => (
          <a href="#" style={{ color: "red" }}>
            {doctor}
          </a>
        ))}
      </div>

      <div className="spacer"></div>

      <div className="submenu-right">
        <Button className="submenu-button">To Schedule (26)</Button>
        <Button className="submenu-button">Waiting</Button>
      </div>

      <DropDownList
        data={opsOptions}
        defaultItem="8 ops"
        className="submenu-dropdown"
      />

      <div className="icon-group">
        <Icon name="bell" className="submenu-icon" />
        <Icon name="comment" className="submenu-icon" />
        <Icon name="gear" className="submenu-icon" />
      </div>
    </div>
  );
};

export default HomePageSubHeader;

import { getter } from "@progress/kendo-react-common";
import { getSelectedState } from "@progress/kendo-react-data-tools";
import { useCallback, useState } from "react";
import Button from "src/components/atoms/Button";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import FormActionsBar from "src/components/organisms/FormActionBar";

const Header = () => {
  return (
    <FormActionsBar>
      <Button icon="email" themeColor="primary">
        Email and Text
      </Button>
      <span>
        <Button icon="delete" themeColor="primary" style={{ marginRight: "10px" }}>
          Delete
        </Button>
        <Button icon="plus" themeColor="primary">
          Add Forms
        </Button>
      </span>
    </FormActionsBar>
  );
};

const Footer = () => {
  return (
    <FormActionsBar style={{ justifyContent: "flex-end" }}>
      <Button themeColor="primary">Save</Button>
    </FormActionsBar>
  );
};

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const PatientForms = () => {
  const [patientForms, setPatientForms] = useState([
    { id: 1, title: "Form 1", type: "Type 1", selected: false },
    { id: 2, title: "Form 2", type: "Type 2", selected: false }
  ]);

  const [selectedState, setSelectedState] = useState({});
  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const columns = [
    {
      field: SELECTED_FIELD,
      width: "40px",
      headerSelectionValue: patientForms.findIndex((item) => !selectedState[idGetter(item)]) === -1
    },
    { field: "title", title: "Form" },
    { field: "type", title: "Type" }
  ];

  return (
    <div style={{ height: "500px", padding: "10px", display: "flex", flexDirection: "column" }}>
      <Header />
      <div style={{ flex: 1 }}>
        <DynamicGrid
          key={patientForms.length}
          columns={columns}
          rows={patientForms.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)]
          }))}
          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          selectable={{ enabled: true, drag: true, cell: true, mode: "multiple" }}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
        />
      </div>
      <Footer />
    </div>
  );
};

export default PatientForms;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { PharmacyService } from "../../../services/AdminService";

const AdminPharmacies = () => {
  const [pharmacies, setPharmacies] = useState([]);

  useEffect(() => {
    const data = PharmacyService.getPharmacies();
    setPharmacies(data);
  }, []);

  const columns = [
    { field: "name", title: "Name" },
    { field: "address", title: "Address" },
    { field: "city", title: "City" },
    { field: "state", title: "State" },
    { field: "zip", title: "Zip" },
    { field: "phone", title: "Phone" },
    { field: "fax", title: "Fax" },
    { field: "email", title: "Email" },
    { field: "comment", title: "Comment" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  const statuses = ["Active", "Inactive"];

  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Pharmacies"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new Pharmacy")}
      addBtnLabel="Pharmacy"
    >
      <DynamicGrid
        columns={columns}
        rows={pharmacies}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminPharmacies;

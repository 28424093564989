import React from "react";
import CustomPanel from "../../molecules/CustomPanel";
import DynamicGrid from "../../organisms/DynamicGrid";
import "./SettingsOrthoLists.css";
import NavbarTemplate from "src/components/templates/NavbarTemplate";

const SettingsOrthoLists = () => {
  const sections = [
    { id: "elastics", title: "Ortho Elastics", buttonText: "Elastic" },
    { id: "appliances", title: "Ortho Appliances", buttonText: "Appliances" },
    { id: "archwire", title: "Ortho Archwire", buttonText: "Archwire" },
    { id: "tasks", title: "Ortho Tasks", buttonText: "Tasks" }
  ];

  const actions = [
    {
      icon: "edit",
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.orthoElastic || dataItem.abbreviation}`)
    },
    {
      icon: "delete",
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.orthoElastic || dataItem.abbreviation}`)
    }
  ];

  const elasticData = [
    {
      orthoElastic: "1/4” Extra Heavy (8oz) Class I Triangle",
      abbreviation: "1/4” EH Class I Triangle"
    },
    { orthoElastic: "1/4” Extra Heavy (8oz) Class II", abbreviation: "1/4” EH Class II" },
    { orthoElastic: "1/4” Extra Heavy (8oz) Class III", abbreviation: "1/4” EH Class III" },
    { orthoElastic: "1/4” Extra Heavy (8oz) Custom", abbreviation: "1/4” EH Custom" }
  ];

  const elasticColumns = [
    { field: "orthoElastic", title: "Ortho Elastic" },
    { field: "abbreviation", title: "Abbreviation" }
  ];

  const handleStatusChange = (value) => {
    console.log("Status changed to:", value);
  };

  const handleAddClick = (title) => {
    console.log(`Add clicked for ${title}`);
  };

  return (
    <NavbarTemplate>
      <div className="ortho-lists">
        {/* Title */}
        <h1 className="ortho-lists-title">Ortho Lists</h1>

        {/* Panels */}
        {sections.map((section) => (
          <CustomPanel
            key={section.id}
            title={section.title}
            dropdownOptions={["Active", "Inactive"]}
            dropdownValue="Active"
            onDropdownChange={handleStatusChange}
            buttonLabel={section.buttonText}
            onButtonClick={() => handleAddClick(section.title)}
          >
            <DynamicGrid
              columns={elasticColumns}
              rows={section.id === "elastics" ? elasticData : []} // Example: data only for elastics section
              actions={actions}
              allowCheckable={false}
              allowExpand={false}
            />
          </CustomPanel>
        ))}
      </div>
    </NavbarTemplate>
  );
};

export default SettingsOrthoLists;

import React from "react";
import CommonPopup from "src/components/molecules/CommonPopup";
import DynamicForm from "src/components/organisms/DynamicForm";
import DynamicGrid from "src/components/organisms/DynamicGrid";

const ScheduleAppointment = ({ show, onClose }) => {
  const columns = [
    { field: "treatment", title: "Treatment" },
    { field: "code", title: "Code" },
    { field: "tooth", title: "Tooth" },
    { field: "area", title: "Area" },
    { field: "fee", title: "Fee", format: "{0:c}" }
  ];

  const rows = [
    {
      treatment: "Root Canal",
      code: "RC001",
      tooth: "Molar 1",
      area: "Upper Right",
      fee: "$200"
    },
    {
      treatment: "Crown",
      code: "CR002",
      tooth: "Molar 2",
      area: "Upper Left",
      fee: "$300"
    }
  ];

  const rowsF = [
    {
      date: "12/01/2024",
      time: "10:30 AM",
      mode: "Text",
      sender: "Office",
      conversation: "Appointment reminder sent to the patient."
    },
    {
      date: "11/25/2024",
      time: "03:15 PM",
      mode: "Call",
      sender: "Dr. Smith",
      conversation: "Discussed treatment plan with the patient."
    },
    {
      date: "11/20/2024",
      time: "02:00 PM",
      mode: "Email",
      sender: "Reception",
      conversation: "Sent post-procedure care instructions."
    },
    {
      date: "11/15/2024",
      time: "09:45 AM",
      mode: "Text",
      sender: "Office",
      conversation: "Follow-up appointment confirmation sent."
    }
  ];
  const tabs = [
    {
      name: "Patient Info",
      header: {
        left: [
          { name: "hygiene", type: "checkbox", label: "Hygiene", labelPosition: "internal" },
          { name: "newPatient", type: "checkbox", label: "New Patient", labelPosition: "internal" }
        ]
      },
      sections: [
        {
          sectionName: "Patient and Contact",
          fields: [
            { name: "patientName", type: "input", label: "Patient", required: true },
            { name: "birthdate", type: "input", label: "Birthdate", required: true },
            {
              name: "gender",
              type: "select",
              label: "Gender",
              options: ["Male", "Female", "Other"]
            },
            { name: "status", type: "select", label: "Status", options: ["Single", "Married"] },
            { name: "mobilePhone", type: "input", label: "Mobile Phone" },
            { name: "homePhone", type: "input", label: "Home Phone" },
            { name: "email", type: "input", label: "E-mail" },
            { name: "reachBy", type: "input", label: "Reach By" }
          ]
        },
        {
          sectionName: "Visit Note",
          fields: [
            {
              name: "visitNote",
              type: "textarea",
              label: "Visit Note",
              placeholder: "Write visit notes here..."
            }
          ]
        }
      ]
    },
    {
      name: "Treatments",
      header: {
        left: [
          {
            name: "dentalVisit",
            type: "select",
            placeholder: "Dental Visits",
            options: [
              { label: "None", value: "None" },
              { label: "Routine Checkup", value: "Routine Checkup" },
              { label: "Emergency", value: "Emergency" },
              { label: "Follow-up", value: "Follow-up" }
            ],
            required: true
          },
          {
            name: "treatment",
            type: "select",
            placeholder: "Select Treatment",
            options: [
              { label: "Filling", value: "Filling" },
              { label: "Root Canal", value: "Root Canal" },
              { label: "Crown", value: "Crown" },
              { label: "Extraction", value: "Extraction" }
            ],
            required: true
          }
        ],
        right: [
          {
            type: "button",
            text: "Treatment",
            onClick: () => alert("Treatment clicked"),
            className: "treatment-button"
          },
          {
            type: "button",
            text: "Diagnosed",
            onClick: () => alert("Diagnosed clicked"),
            className: "diagnosed-button"
          },
          {
            type: "button",
            text: "Ortho Visit",
            onClick: () => alert("Ortho Visit clicked"),
            className: "ortho-visit-button"
          }
        ]
      },
      sections: [
        {
          customSection: (
            <DynamicGrid
              allowCheckable
              columns={columns}
              rows={rows}
              actions={[
                {
                  icon: "edit",
                  title: "Edit",
                  onClick: (dataItem) => console.log("Edit clicked for:", dataItem)
                },
                {
                  icon: "delete",
                  title: "Delete",
                  onClick: (dataItem) => console.log("Delete clicked for:", dataItem)
                }
              ]}
              pageable
              pageSize={5}
              noRecordMessage="Please add treatment to this visit."
            />
          )
        },
        {
          customSection: (
            <div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
              <span>
                <strong>Total:</strong> 0
              </span>
              <span>
                <strong>Total Fee:</strong> $0.00
              </span>
            </div>
          )
        }
      ]
    },
    {
      name: "Schedule Details",
      sections: [
        {
          sectionName: "Schedule",
          fields: [
            { name: "dateTime", type: "label", label: "Date / Time", required: true },
            {
              name: "duration",
              type: "select",
              label: "Duration",
              options: ["10 min", "20 min", "30 min"]
            },
            {
              name: "clinicianTime",
              type: "select",
              label: "Clinician Time",
              options: ["Morning", "Afternoon", "Evening"]
            },
            { name: "checkedIn", type: "time", label: "Checked In" },
            { name: "inChair", type: "time", label: "In Chair" },
            { name: "checkedOut", type: "time", label: "Check Out" },
            { name: "visitColor", type: "color", label: "Visit Color" }
          ]
        },
        {
          sectionName: "Visit Status",
          fields: [
            {
              name: "phase",
              type: "select",
              label: "Phase",
              options: ["Scheduled", "Completed", "In Progress"]
            },
            {
              name: "scheduling",
              type: "select",
              label: "Scheduling",
              options: ["Scheduled", "Rescheduled", "Cancelled"]
            },
            {
              name: "status",
              type: "select",
              label: "Status",
              options: ["Unconfirmed", "Confirmed", "Cancelled"]
            }
          ]
        },
        {
          sectionName: "Treatment",
          fields: [
            {
              name: "dentist",
              type: "select",
              label: "Dentist",
              required: true,
              options: ["NS", "Dr. Smith", "Dr. Johnson"]
            },
            {
              name: "assistant",
              type: "select",
              label: "Assistant",
              options: ["None", "Assistant A", "Assistant B"]
            },
            {
              name: "hygienist",
              type: "select",
              label: "Hygienist",
              options: ["None", "Hygienist A", "Hygienist B"]
            },
            {
              name: "chair",
              type: "select",
              label: "Chair",
              required: true,
              options: ["Root Canals", "Fillings", "Extractions"]
            },
            {
              name: "labwork",
              type: "button",
              text: "Labwork",
              onClick: () => alert("Labwork Clicked")
            }
          ]
        }
      ]
    },
    {
      name: "Follow-Up", // Name of the new tab
      header: {
        right: [
          {
            type: "button",
            text: "Conversation",
            onClick: () => alert("Conversation clicked"),
            className: "conversation-button",
            icon: "phone"
          },
          {
            type: "button",
            text: "Send Text",
            onClick: () => alert("Send Text clicked"),
            className: "send-text-button",
            icon: "comment"
          },
          {
            type: "button",
            text: "To Schedule",
            onClick: () => alert("To Schedule clicked"),
            className: "to-schedule-button",
            icon: "calendar"
          },
          {
            type: "button",
            text: "All Visits",
            onClick: () => alert("All Visits clicked"),
            className: "all-visits-button",
            icon: "calendar"
          }
        ]
      },
      sections: [
        {
          sectionName: "Follow Up", // Grid Section
          customSection: (
            <DynamicGrid
              columns={[
                { field: "date", title: "Date", filterable: true },
                { field: "time", title: "Time", filterable: true },
                { field: "mode", title: "Mode", filterable: true },
                { field: "sender", title: "Sender", filterable: true },
                { field: "conversation", title: "Conversation", filterable: true }
              ]}
              rows={rowsF} // Empty rows for now
              pageable
              pageSize={5}
              allowFilter
              allowCheckable={false} // No row selection required
              noRecordMessage="No follow-up records found."
            />
          )
        }
      ]
    }
  ];

  const handleFormSubmit = (formData) => {
    console.log("Form Submitted:", formData);
    onClose(); // Close the popup on submit
  };

  return (
      <DynamicForm
        tabs={tabs} // Pass the tabs configuration
        handleFormSubmit={handleFormSubmit} // Handle submit logic
        mode="add" // Mode of the form (add/edit)
        initialData={{}} // Initial form data (empty for now)
      />
  );
};

export default ScheduleAppointment;

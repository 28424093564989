import React from "react";
import { Icon } from "@progress/kendo-react-common";
import { useNavigate, useLocation } from "react-router-dom";
import "./HomePageDrawer.css"; // Custom styles
import { ReactComponent as DentalIcon } from "src/assets/icons/dental-sidebar.svg";
import { ReactComponent as TeethGroup } from "src/assets/icons/teethgroup.svg";
import { ReactComponent as DentailScale } from "src/assets/icons/dental-scale.svg";
import { ReactComponent as DentalChair } from "src/assets/icons/dental-chair.svg";
import { ReactComponent as Documents } from "src/assets/icons/documents.svg";
import { ReactComponent as IDCard } from "src/assets/icons/idcard.svg";
import { ReactComponent as Messages } from "src/assets/icons/messages.svg";
import { ReactComponent as Emails } from "src/assets/icons/emails.svg";
import { ReactComponent as Chats } from "src/assets/icons/chats.svg";

const Items = [
  { name: "calendar", path: "/home" },
  { name: "user", path: "/patient" },
  { name: "plus", path: "/plus", icon: <DentalIcon fill="#00ce90" /> },
  { name: "plus", path: "/plus", icon: <TeethGroup color="#00ce90" /> },
  { name: "plus", path: "/plus", icon: <DentailScale color="#00ce90" /> },
  { name: "plus", path: "/plus", icon: <DentalChair color="#00ce90" /> },
  { name: "plus", path: "/documents", icon: <Documents color="#00ce90" /> },
  { name: "plus", path: "/documents", icon: <IDCard color="#00ce90" /> }
];

const secondaryItems = [
  { name: "plus", path: "/messages", icon: <Messages color="#00ce90" /> },
  { name: "plus", path: "/emails", icon: <Emails color="#00ce90" /> },
  { name: "plus", path: "/chats", icon: <Chats color="#00ce90" /> }
];

const SchedulerDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = (path) => {
    navigate(path);
  };

  const isSelected = (path) => location.pathname === path;

  return (
    <div className="drawer-container">
      {Items.map((item, index) => (
        <div
          key={index}
          className={`icon-circle ${isSelected(item.path) ? "selected" : ""}`}
          onClick={() => onClick(item.path)}
          style={isSelected(item.path) ? { background: "#00CE90" } : {}}
        >
          {item.icon ? (
            React.cloneElement(item.icon, {
              color: isSelected(item.path) ? "#FFFFFF" : "#00ce90"
            })
          ) : (
            <Icon
              name={item.name}
              className={`drawer-icon ${isSelected(item.path) ? "selected" : ""}`}
            />
          )}
        </div>
      ))}
      <hr
        style={{
          border: "none",
          borderTop: "1px solid rgba(255, 255, 255, 0.3)",
          margin: "20px 0",
          width: "90%",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      />
      {secondaryItems.map((item, index) => (
        <div
          key={index}
          className={`icon-circle ${isSelected(item.path) ? "selected" : ""}`}
          onClick={() => onClick(item.path)}
          style={isSelected(item.path) ? { background: "#00CE90" } : {}}
        >
          {item.icon ? (
            React.cloneElement(item.icon, {
              color: isSelected(item.path) ? "#FFFFFF" : "#00ce90"
            })
          ) : (
            <Icon
              name={item.name}
              className={`drawer-icon ${isSelected(item.path) ? "selected" : ""}`}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default SchedulerDrawer;

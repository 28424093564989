import React, { useState } from "react";
import { ColorPicker } from "@progress/kendo-react-inputs";
import "@progress/kendo-theme-default/dist/all.css";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";

const SettingsTheme = () => {
  const [primaryColor, setPrimaryColor] = useState("#B91C1C"); // Default Primary Color
  const [secondaryColor, setSecondaryColor] = useState("#D97706"); // Default Secondary Color

  const handlePrimaryColorChange = (event) => {
    setPrimaryColor(event.value);
  };

  const handleSecondaryColorChange = (event) => {
    setSecondaryColor(event.value);
  };

  return (
    <DatatableLayout
      title="Theme Colors"
      onAdd={() => console.log("Add new chair")}
      addBtnLabel="Default theme"
      saveButton="Save"
    >
      <div style={{ backgroundColor: "#0E1E30", padding: "20px", color: "#FFFFFF" }}>
        <div style={{ display: "flex", gap: "150px", alignItems: "center" }}>
          {/* Primary Color Picker */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <label>Primary Color:</label>
            <ColorPicker
              value={primaryColor}
              view="palette"
              paletteSettings={{ palette: "basic" }}
              onChange={handlePrimaryColorChange}
            />
          </div>

          {/* Secondary Color Picker */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <label>Secondary Color:</label>
            <ColorPicker
              value={secondaryColor}
              view="palette"
              paletteSettings={{ palette: "basic" }}
              onChange={handleSecondaryColorChange}
            />
          </div>
        </div>

        {/* Note Section */}
        <div style={{ marginTop: "20px", fontSize: "14px", color: "#B0B0B0" }}>
          Note: To see your theme, please log out and back in after picking colors. Also, please
          choose medium dark colors for best appearance of Practice Cloud.
        </div>
      </div>
    </DatatableLayout>
  );
};

export default SettingsTheme;

import React, { useState } from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import "./CollectionsAndBilling.css";
import ActionButton from "src/components/atoms/ActionButton";

const CollectionsAndBilling = () => {
  const [daily, setDaily] = useState(10);
  const [weekly, setWeekly] = useState(100);
  const [monthly, setMonthly] = useState(1000);
  const [yearly, setYearly] = useState(10000);
  const [collectionsRate, setCollectionsRate] = useState(50);
  const [newPatients, setNewPatients] = useState(50);
  const [annualPatientValue, setAnnualPatientValue] = useState(100);

  const handleSave = () => {
    alert("Settings saved");
  };

  return (
    <div className="collections-billing-container">
      <div className="collections-billing-form">
        <p>Hygiene</p>

        <div className="collections-billing-input-group">
          <label>Daily</label>
          <NumericTextBox
            value={daily}
            onChange={(e) => setDaily(e.value)}
            format="$#,##0"
            width="100%"
          />
        </div>

        <div className="collections-billing-input-group">
          <label>Weekly</label>
          <NumericTextBox
            value={weekly}
            onChange={(e) => setWeekly(e.value)}
            format="$#,##0"
            width="100%"
          />
        </div>

        <div className="collections-billing-input-group">
          <label>Monthly</label>
          <NumericTextBox
            value={monthly}
            onChange={(e) => setMonthly(e.value)}
            format="$#,##0"
            width="100%"
          />
        </div>

        <div className="collections-billing-input-group">
          <label>Yearly</label>
          <NumericTextBox
            value={yearly}
            onChange={(e) => setYearly(e.value)}
            format="$#,##0"
            width="100%"
          />
        </div>

        <div className="collections-billing-input-group">
          <label>Collections Rate</label>
          <NumericTextBox
            value={collectionsRate}
            onChange={(e) => setCollectionsRate(e.value)}
            format="p0"
            width="100%"
          />
        </div>

        <h2>Others</h2>
        <div className="collections-billing-input-group">
          <label>New Patients</label>
          <NumericTextBox
            value={newPatients}
            onChange={(e) => setNewPatients(e.value)}
            format="$#,##0"
            width="100%"
          />
        </div>
        <div className="collections-billing-input-group">
          <label>Annual Patient Value</label>
          <NumericTextBox
            value={annualPatientValue}
            onChange={(e) => setAnnualPatientValue(e.value)}
            format="$#,##0"
            width="100%"
          />
        </div>
      </div>
      <ActionButton onClick={handleSave} primary={true} className="collections-billing-save-button">
        Save
      </ActionButton>
    </div>
  );
};

export default CollectionsAndBilling;

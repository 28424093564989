import React, { useState } from "react";
import { Switch, NumericTextBox } from "@progress/kendo-react-inputs";
import "./VisitReminders.css";
import Label from "src/components/atoms/Label";

const VisitReminderSettings = () => {
  const [initialReminderDays, setInitialReminderDays] = useState(1);
  const [confirmationReminderDays, setConfirmationReminderDays] = useState(1);
  const [finalReminderDays, setFinalReminderDays] = useState(1);

  return (
    <div className="visit-reminders-container">
      {/* Save The Date Section */}
      <div className="visit-reminders-sub-container">
        <h4 className="first">Save The Date</h4>
        <div className="visit-reminders-label-plus-content">
          <Label>Send Reminder:</Label>
          <Switch defaultChecked={true} />
        </div>
        <div style={{ display: "flex" }}>
          <Label>How should we send?</Label>
          <div>
            <div>
              <Label>
                <input type="radio" name="saveTheDate" defaultChecked /> Automated after creating a
                visit
              </Label>
            </div>
            <div>
              <Label>
                <input type="radio" name="saveTheDate" /> Prompt me when creating a visit
              </Label>
            </div>
          </div>
        </div>
      </div>

      {/* Initial Visit Reminder Section */}
      <div className="visit-reminders-sub-container">
        <h4 className="first">Initial Visit Reminder</h4>
        <div className="visit-reminders-label-plus-content">
          <Label>Send Initial Reminder:</Label>
          <Switch defaultChecked={true} />
        </div>
        <div className="visit-reminders-label-plus-content">
          <Label style={{ width: "190px" }}>Days Before Visit:</Label>
          <NumericTextBox
            value={initialReminderDays}
            onChange={(e) => setInitialReminderDays(e.value)}
            format="n0"
            spinners={true}
            min={1}
          />
        </div>
      </div>

      {/* Visit Confirmation Section */}
      <div className="visit-reminders-sub-container">
        <h4 className="first">Visit Confirmation</h4>
        <div className="visit-reminders-label-plus-content">
          <Label>Send Confirmation:</Label>
          <Switch defaultChecked={true} />
        </div>
        <div className="visit-reminders-label-plus-content">
          <Label style={{ width: "190px" }}>Days Before Visit:</Label>
          <NumericTextBox
            value={confirmationReminderDays}
            onChange={(e) => setConfirmationReminderDays(e.value)}
            format="n0"
            spinners={true}
            min={1}
          />
        </div>
      </div>

      {/* Final Visit Reminder Section */}
      <div className="visit-reminders-sub-container">
        <h4 className="second">Final Visit Reminder</h4>
        <div className="visit-reminders-label-plus-content">
          <Label>Send Final Reminder:</Label>
          <Switch defaultChecked={true} />
        </div>
        <div className="visit-reminders-label-plus-content">
          <Label style={{ width: "190px" }}>Days Before Visit:</Label>
          <NumericTextBox
            value={finalReminderDays}
            onChange={(e) => setFinalReminderDays(e.value)}
            format="n0"
            spinners={true}
            min={1}
          />
        </div>
      </div>

      {/* Text Delivery Restrictions Section */}
      <div className="visit-reminders-sub-container">
        <h4 className="second">Text Delivery Restrictions</h4>
        <div className="visit-reminders-label-plus-content">
          <Label>Saturday:</Label>
          <Switch />
        </div>
        <div className="visit-reminders-label-plus-content">
          <Label>Sunday:</Label>
          <Switch defaultChecked={true} />
        </div>
        <p>Note: If either day is turned off, texts will be sent the previous day.</p>
      </div>

      {/* After Hours Response Section */}
      <div className="visit-reminders-sub-container">
        <h4 className="second">After Hours Response</h4>
        <div className="visit-reminders-label-plus-content">
          <Label>Send After Hours Response:</Label>
          <Switch defaultChecked={true} />
        </div>
        <p>Note: Automated Reminders are sent via text and email around 8 am in your time zone.</p>
      </div>
    </div>
  );
};

export default VisitReminderSettings;

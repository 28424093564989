// health-info services
import httpClient from './http-client';
const SERVICE_BASE_URL = '/health-info';

export const getHealthInfo = (clinicId, patientId) => {
  return httpClient(`${clinicId}/${SERVICE_BASE_URL}/${patientId}`);
};

export const getAllergies = (clinicId, patientId) => {
  // return httpClient(`${clinicId}/${SERVICE_BASE_URL}/${patientId}/allergies`);
  return Promise.resolve([
    { allergy: 'Peanuts', symptoms: 'Hives, itching, swelling', status: 'Active' },
    { allergy: 'Penicillin', symptoms: 'Rash, itching, swelling', status: 'Active' },
  ]);
};

export const getFamilyHistory = (clinicId, patientId) => {
  // return httpClient(`${clinicId}/${SERVICE_BASE_URL}/${patientId}/family-history`);
  return Promise.resolve([]);
};

export const getHealthIssues = (clinicId, patientId) => {
  // return httpClient(`${clinicId}/${SERVICE_BASE_URL}/${patientId}/health-issues`);
  return Promise.resolve([
    { issue: 'Asthma', comment: 'Comment', status: 'Active' },
    { issue: 'Diabetes', comment: 'Comment', status: 'Active' },
  ]);
};

export const getMedications = (clinicId, patientId) => {
  // return httpClient(`${clinicId}/${SERVICE_BASE_URL}/${patientId}/medications`);
  return Promise.resolve([
    { medication: 'Aspirin', prescription: 'Prescription', comment: 'Comment', status: 'Active' },
    { medication: 'Ibuprofen', prescription: 'Prescription', comment: 'Comment', status: 'Active' },
  ]);
};

export const getVitals = (clinicId, patientId) => {
  // return httpClient(`${clinicId}/${SERVICE_BASE_URL}/${patientId}/vitals`);
  return Promise.resolve([]);
};

// src/store/slices/fastFillSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchFastFills,
  addFastFill,
  updateFastFill,
  deleteFastFill
} from "../actions/fastFillAction";

const fastFillSlice = createSlice({
  name: "fastFill",
  initialState: {
    fastFills: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFastFills.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFastFills.fulfilled, (state, action) => {
        state.loading = false;
        state.fastFills = action.payload;
      })
      .addCase(fetchFastFills.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addFastFill.fulfilled, (state, action) => {
        state.fastFills.push(action.payload);
      })
      .addCase(updateFastFill.fulfilled, (state, action) => {
        const index = state.fastFills.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.fastFills[index] = action.payload;
        }
      })
      .addCase(deleteFastFill.fulfilled, (state, action) => {
        state.fastFills = state.fastFills.filter((item) => item.id !== action.payload);
      });
  }
});

export default fastFillSlice.reducer;

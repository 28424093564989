import React, { useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";

const SettingsStaff = () => {
  const [status, setStatus] = useState("Active");

  const columns = [
    { field: "Staff", title: "Staff" },
    { field: "Username", title: "Username" },
    { field: "Mobile", title: "Mobile" }
  ];

  const staffData = [
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    },
    {
      Staff: "Amber Smith",
      Username: "asmith@practice-web.com",
      Mobile: "(530) 306-7541"
    }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    }
  ];

  return (
    <DatatableLayout
      title="Staff"
      statusOptions={["Active", "Inactive"]}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new staff")}
      addBtnLabel="Staff"
    >
      <DynamicGrid
        columns={columns}
        rows={staffData}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default SettingsStaff;

import React, { useState, useEffect } from "react";
import "../../../organisms/DynamicForm.css"; // Custom styles for the popup
import CommonPopup from "../../../molecules/CommonPopup";
import DynamicForm from "../../../organisms/DynamicForm";
import { Button } from "@progress/kendo-react-buttons";

const AddEditPatient = ({ show, onClose, mode = "add", initialData = {} }) => {
  const [formData, setFormData] = useState({});

  const handleFormSubmit = (data) => {
    console.log(`${mode === "add" ? "Adding" : "Updating"} patient profile with data:`, data);
    setFormData(data);
    // Implement add or update logic here, e.g., API calls
  };

  const formStructure = {
    title: "Profile",
    tabs: [
      {
        name: "Demographics",
        sections: [
          {
            sectionName: "Demographics",
            fields: [
              {
                label: "Preferred Office",
                name: "preferredOffice",
                type: "select",
                options: [{ label: "Smiles Clinic", value: "Smiles Clinic" }],
                required: true
              },
              {
                label: "Title",
                name: "title",
                type: "select",
                options: [
                  { label: "Mr.", value: "Mr." },
                  { label: "Ms.", value: "Ms." }
                ]
              },
              { label: "First Name", name: "firstName", type: "input", required: true },
              { label: "Middle Name", name: "middleName", type: "input" },
              { label: "Last Name", name: "lastName", type: "input", required: true },
              { label: "Nickname", name: "nickname", type: "input" },
              {
                label: "Type",
                name: "type",
                type: "select",
                options: [{ label: "Regular", value: "Regular" }]
              },
              {
                label: "Gender",
                name: "gender",
                type: "select",
                options: [
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" }
                ]
              },
              {
                label: "Life Status",
                name: "lifeStatus",
                type: "select",
                options: [
                  { label: "Alive", value: "Alive" },
                  { label: "Deceased", value: "Deceased" }
                ]
              },
              { label: "Birthdate", name: "birthdate", type: "date", required: true },
              { label: "SSN", name: "ssn", type: "password" }
            ]
          },
          {
            sectionName: "Family",
            fields: [
              { label: "Guarantor", name: "guarantor", type: "input" },
              {
                type: "button",
                text: "Change Family",
                name: "changeFamily",
                onClick: () => console.log("Change Family clicked")
              },
              { label: "Self", name: "self", type: "checkbox" }
            ]
          }
        ]
      },
      {
        name: "Address",
        sections: [
          {
            sectionName: "Address",
            fields: [
              { label: "Family", name: "familyAddress", type: "checkbox" },
              { label: "Address", name: "address", type: "input", placeholder: "Enter Location" },
              { label: "City", name: "city", type: "input" },
              {
                label: "State",
                name: "state",
                type: "select",
                options: [{ label: "None", value: "none" }]
              },
              { label: "Zip", name: "zip", type: "input" }
            ]
          }
        ]
      },
      {
        name: "Contact Details",
        sections: [
          {
            sectionName: "Phone and Email",
            fields: [
              { label: "Family", name: "familyContact", type: "checkbox" },
              { label: "Mobile Phone", name: "mobilePhone", type: "input" },
              {
                label: "Send Texts",
                name: "sendTexts",
                type: "radio",
                options: [
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
                  { label: "Ask", value: "ask" }
                ]
              },
              { label: "Home Phone", name: "homePhone", type: "input" },
              { label: "Work Phone", name: "workPhone", type: "input" },
              {
                label: "Send Reviews",
                name: "sendReviews",
                type: "radio",
                options: [
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" }
                ]
              },
              { label: "E-mail", name: "email", type: "input" },
              { label: "Add to Portal", name: "addToPortal", type: "checkbox" }
            ]
          },
          {
            sectionName: "Contact Preferences",
            fields: [
              { label: "Family", name: "familyPreferences", type: "checkbox" },
              {
                label: "Reach By",
                name: "reachBy",
                type: "select",
                options: [{ label: "Select", value: "" }]
              },
              {
                label: "Language",
                name: "language",
                type: "select",
                options: [{ label: "Select", value: "" }]
              },
              { label: "Contact Notes", name: "contactNotes", type: "textarea" }
            ]
          },
          {
            sectionName: "Emergency Contact",
            fields: [
              { label: "Name & Relation", name: "emergencyContactName", type: "input" },
              { label: "Phone", name: "emergencyContactPhone", type: "input" }
            ]
          }
        ]
      },
      {
        name: "Account and Treatment",
        sections: [
          {
            sectionName: "Contact Preferences",
            fields: [
              { label: "Family", name: "familyAccountPreferences", type: "checkbox" },
              {
                label: "Dentist",
                name: "dentist",
                type: "select",
                options: [{ label: "None", value: "none" }]
              },
              {
                label: "Hygienist",
                name: "hygienist",
                type: "select",
                options: [{ label: "None", value: "none" }]
              },
              {
                label: "Fees (Optional)",
                name: "fees",
                type: "select",
                options: [{ label: "None", value: "none" }]
              },
              { label: "Source", name: "source", type: "input" }
            ]
          }
        ]
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Patient Profile">
      <DynamicForm
        tabs={formStructure.tabs}
        handleFormSubmit={handleFormSubmit}
        initialData={initialData}
        mode={mode}
      />
     
    </CommonPopup>
  );
};

export default AddEditPatient;

import React, { useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Switch } from "@progress/kendo-react-inputs";
import "@progress/kendo-theme-default/dist/all.css";
import "./SettingsStatements.css";
import Label from "src/components/atoms/Label";
import InputField from "src/components/atoms/InputField";

const SettingsStatements = () => {
  const [paymentDueDate, setPaymentDueDate] = useState(15);
  const [paymentPlanBillDate, setPaymentPlanBillDate] = useState(15);
  const [noInsurance, setNoInsurance] = useState(false);
  const [centralizedBilling, setCentralizedBilling] = useState(true);
  const [showFamilyBalanceDue, setShowFamilyBalanceDue] = useState(true);
  return (
    <DatatableLayout title="Statements" saveButton="Save">
      <div className="settings-container">
        <div className="setting-row">
          <Label>Payment Due Date:</Label>
          <InputField value={paymentDueDate} onChange={(e) => setPaymentDueDate(e.value)} />
        </div>
        <div className="setting-row">
          <Label>Payment Plan Bill Date:</Label>
          <InputField
            value={paymentPlanBillDate}
            onChange={(e) => setPaymentPlanBillDate(e.value)}
          />
        </div>
        <div className="setting-row">
          <Label>Group Family By Date:</Label>
          <Checkbox />
        </div>
        <div className="setting-row">
          <Label>No Est Insurance in Total Balance:</Label>
          <Switch
            checked={noInsurance}
            onChange={(e) => setNoInsurance(e.target.value)}
            onLabel="Yes"
            offLabel="No"
          />
        </div>
        <div className="setting-row">
          <Label>Centralized Billing:</Label>
          <Switch
            checked={centralizedBilling}
            onChange={(e) => setCentralizedBilling(e.target.value)}
            onLabel="Yes"
            offLabel="No"
          />
        </div>
        <div className="setting-row">
          <Label>Show Family Balance Due:</Label>
          <Switch
            checked={showFamilyBalanceDue}
            onChange={(e) => setShowFamilyBalanceDue(e.target.value)}
            onLabel="Yes"
            offLabel="No"
          />
        </div>
      </div>
    </DatatableLayout>
  );
};

export default SettingsStatements;

import React from "react";
import { Button as KendoButton } from "@progress/kendo-react-buttons";
import "./Button.css"; // Import custom styles

const Button = (props) => (
  <KendoButton {...props} className={"custom-button " + props.className}>
    {props.children}
  </KendoButton>
);

export default Button;

import React, { useEffect, useState } from "react";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import { TimeBlockService } from "src/services/SettingsService";
import ColorCell from "src/components/atoms/ColorCell";

const SettingsTimeBlocks = () => {
  const [timeBlocks, setTimeBlocks] = useState([]);

  useEffect(() => {
    const data = TimeBlockService.getTimeBlocks();
    setTimeBlocks(data);
  }, []);

  const columns = [
    { field: "timeBlock", title: "TimeBlock" },
    { field: "color", title: "Color", cell: ColorCell },
    { field: "inactive", title: "Inactive" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    }
  ];
  return (
    <DatatableLayout title="Time Block">
      <DynamicGrid
        columns={columns}
        rows={timeBlocks}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default SettingsTimeBlocks;

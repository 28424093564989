import httpClient from "./http-client";

export const PatientService = {
  getPatientDetails: async (patientId) => {
    try {
      // const response = await httpClient.get(`/patients/${patientId}`);
      // return response.data;

      // Mocked response for local testing
      return Promise.resolve({
        data: {
          name: "Jenny Joshi",
          id: "157197",
          dob: "12/08/1999",
          age: "24y, 9mo",
          gender: "F",
          recareVisit: "07/25/24",
          image: "https://via.placeholder.com/70",
          patientSince: "09/20/23",
          source: "AB,N",
          nextVisit: "10/18/24 (N/A)",
          mobile: "(123) 456-7896",
          totalProduction: "$27,077.00",
          insurance: "Healthnet (Regular), Sub...",
          familyBalance: "$11,527.10",
          benefits: "$23,388.80",
          isGuarantor: true,
          familyMembers: [
            {
              name: "Roni Joshi",
              ageGender: "Female, 25",
              image: "https://via.placeholder.com/40"
            },
            {
              name: "Nani Joshi",
              ageGender: "Female, 25",
              image: "https://via.placeholder.com/40"
            },
            {
              name: "Rani Joshi",
              ageGender: "Female, 25",
              image: "https://via.placeholder.com/40"
            },
            {
              name: "Roniq Joshi",
              ageGender: "Female, 25",
              image: "https://via.placeholder.com/40"
            },
            {
              name: "Naniq Joshi",
              ageGender: "Female, 25",
              image: "https://via.placeholder.com/40"
            },
            {
              name: "Raniq Joshi",
              ageGender: "Female, 25",
              image: "https://via.placeholder.com/40"
            }
          ]
        }
      });
    } catch (error) {
      console.error("Error fetching patient details:", error);
      throw error;
    }
  },

  fetchPatientConversations: async (patientId) => {
    try {
      //const response = await httpClient.get(`/patients/${patientId}/conversations`);
      // return response.data;

      return Promise.resolve([
        {
          date: "2023-01-01",
          time: "10:00 AM",
          patient: "John Doe",
          office: "Main Office",
          source: "Phone",
          user: "Dr. Smith",
          topic: "Follow-up",
          method: "Call",
          conversation: "Discussed follow-up appointment."
        },
        {
          date: "2023-01-02",
          time: "11:00 AM",
          patient: "Jane Doe",
          office: "Main Office",
          source: "Email",
          user: "Nurse Joy",
          topic: "Prescription",
          method: "Email",
          conversation: "Sent prescription details."
        },
        {
          date: "2023-01-03",
          time: "09:00 AM",
          patient: "Alice Johnson",
          office: "Branch Office",
          source: "In-Person",
          user: "Dr. Brown",
          topic: "Consultation",
          method: "In-Person",
          conversation: "Consulted about symptoms."
        }
      ]);
    } catch (error) {
      console.error("Error fetching patient conversations:", error);
      throw error;
    }
  },

  fetchLetters: async (patientId) => {
    return Promise.resolve([
      { letters: "Appointment Request for Dental Check-Up" },
      { letters: "Inquiry About Teeth Whitening Services" },
      { letters: "Question Regarding Dental Insurance Coverage" },
      { letters: "Request for Emergency Dental Appointment" },
      { letters: "Follow-Up on Recent Dental Treatment" },
      { letters: "New Patient Registration Inquiry" },
      { letters: "Feedback on My Recent Visit" },
      { letters: "Request for Dental Cleaning Appointment" },
      { letters: "Questions About Orthodontic Options" },
      { letters: "Interested in Pediatric Dental Services" },
      { letters: "Clarification Needed on Treatment Plan" },
      { letters: "Scheduling a Consultation for Implants" },
      { letters: "Request for X-Ray Results" },
      { letters: "Inquiry About Preventive Care Options" },
      { letters: "Interested in Cosmetic Dentistry Services" }
    ]);
  }
};

export default PatientService;

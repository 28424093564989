import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import DentelHomePage from "./components/pages/HomePage";
import SchedulerMenu from "./components/organisms/Menu/HomePageHeader";
import AdminPage from "./components/pages/AdminPage";
import PatientPage from "./components/pages/Patient/PatientPage";
import LoginPage from "./components/pages/LoginPage";
import { loginSuccess } from "./store/slices/authSlice";
import PatientDocuments from "src/components/pages/Patient/Documents/PatientDocuments";
import { loginRequest } from "./authConfig";
import Temp from "./components/pages/Temp";
import SettingsAccess from "./components/pages/Settings/SettingsAccess";
import SettingsOffice from "./components/pages/Settings/SettingsOffice";
import SettingsClinicians from "./components/pages/Settings/SettingsClinicians";
import SettingsChairs from "./components/pages/Settings/SettingsChairs";
import SettingsTimeBlocks from "./components/pages/Settings/SettingsTimeBlocks";
import SettingsDentalVisits from "./components/pages/Settings/SettingsDentalVisit";
import SettingsStaff from "./components/pages/Settings/SettingsStaff";
// eslint-disable-next-line max-len
import SettingsOfficeGoals from "./components/pages/Settings/SettingOfficeGoals/SettingsOfficeGoals";
import SettingsAuditLog from "./components/pages/Settings/SettingsAuditLog";
import SettingsOfficeDocument from "./components/pages/Settings/SettingsOfficeDocument";
import TimePunches from "./components/pages/Settings/TimePunches";
import PatientPortalAndCheckIn from "./components/pages/Settings/PatientPortalAndCheckIn";
import SettingsNotification from "./components/pages/Settings/SettingsNotification";
import SettingsTheme from "./components/pages/Settings/SettingsTheme";
import SettingsProfile from "./components/pages/Settings/SettingsProfile";
import SettingsCaseRanking from "./components/pages/Settings/SettingsCaseRanking";
import SettingsProcedureGroup from "./components/pages/Settings/SettingsProcedureGroup";
import SettingsDentalProduct from "./components/pages/Settings/SettingsDentalProduct";
import SettingsMembershipPlan from "./components/pages/Settings/SettingsMembershipPlan";
import SettingsePrescribing from "./components/pages/Settings/SettingsePrescribing";
import SettingsOrthoVisits from "./components/pages/Settings/SettingsOrthoVisist";
import SettingsHygieneTrack from "./components/pages/Settings/SettingsHygieneTrack";
import SettingsPatientFormAutomation from "./components/pages/Settings/SettingsPatientFormAutomation";
import SettingsPatientDocuments from "./components/pages/Settings/SettingsPatientDocuments";
import SettingsCasePresenter from "./components/pages/Settings/SettingsCasePresenter";
import SettingsTreatmentFavorites from "./components/pages/Settings/SettingsTreatmentFavorites";
import SettingsXRayImaging from "./components/pages/Settings/SettingsXRayImaging";
import SettingsPatientEducation from "./components/pages/Settings/SettingsPatientEducation";
import SettingsPlanAndFees from "./components/pages/Settings/SettingsPlanAndFees";
import SettingsInsuranceVerification from "./components/pages/Settings/SettingsInsuranceVerification";
import SettingssPhoneIntegration from "./components/pages/Settings/SettingssPhoneIntegration";
import SettingsStatements from "./components/pages/Settings/SettingsStatements";
import SettingsAdjustment from "./components/pages/Settings/SettingsAdjustment";
import SettingsPaymentsAndClaims from "./components/pages/Settings/SettingsPaymentsAndClaims";
import SettingsTreatmentInterval from "./components/pages/Settings/SettingsTreatmentInterval";
import SettingsOnlineReviews from "./components/pages/Settings/SettingsOnlineReviews";
import SettingsCreditCardAndPay from "./components/pages/Settings/SettingsCreditCardAndPay";
import SettingsAutomatedReminders from "./components/pages/Settings/SettingsAutomatedReminders/SettingsAutomatedReminders";
import SettingsTextTemplate from "./components/pages/Settings/SettingsTextTemplate/SettingsTextTemplate";
import SettingsDentalAndPerioChart from "./components/pages/Settings/SettingsDentalAndPerioChart.js";
import SettingsOrthoLists from "./components/pages/Settings/SettingsOrthoLists";
import AdminFormList from "./components/pages/Admin/AdminFormList";
import AdminPrescriptions from "./components/pages/Admin/AdminPrescriptions";
import AdminHealthIssue from "./components/pages/Admin/AdminHealthIssue";
import AdminMedications from "./components/pages/Admin/AdminMedications";
import AdminAllergies from "./components/pages/Admin/AdminAllergies";
import AdminInsuranceCompanies from "./components/pages/Admin/AdminInsuranceCompanies";
import AdminEmployees from "./components/pages/Admin/AdminEmployees";
import AdminPaidClaimWoEOB from "./components/pages/Admin/AdminPaidClaimWoEOB";
import AdminBillingClinics from "./components/pages/Admin/AdminBillingClinics";
import AdminPharmacies from "./components/pages/Admin/AdminPharmacies";
import AdminLaboratories from "./components/pages/Admin/AdminLaboratories";
import AdminOfficeNews from "./components/pages/Admin/AdminOfficeNews";
import Accounts from "./components/pages/Accounts";
import AdminTreatmentAndFees from "./components/pages/Admin/AdminTreatmentAndFees";
import AdminNoteTemplates from "./components/pages/Admin/AdminNoteTemplates";
import AdminInsurancePlans from "./components/pages/Admin/AdminInsurancePlans";
import AdminDepositSlips from "./components/pages/Admin/AdminDepositSlips";
import AdminTextEmailMarketing from "./components/pages/Admin/AdminTextEmailMarketing";
import AdminInternalPatientReview from "./components/pages/Admin/AdminInternalPatientReview";
import AdminAutomatedCharges from "./components/pages/Admin/AdminAutomatedCharges";
import AdminConnections from "./components/pages/Admin/AdminConnections";
import AdminReferralSources from "./components/pages/Admin/AdminReferralSources";

// A PrivateRoute component to protect your routes
const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.log("private route", isAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const AppRouter = () => {
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // Silent token acquisition and login state management
  useEffect(() => {
    if (inProgress === "none" && accounts.length > 0 && !isAuthenticated) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        })
        .then((tokenResponse) => {
          const profile = accounts[0].idTokenClaims;
          const user = {
            displayName: profile.given_name + " " + profile.family_name,
            email: profile.emails[0],
            accessToken: tokenResponse.accessToken
          };
          dispatch(loginSuccess(user));
        });
    }
  }, [inProgress, accounts, instance, dispatch, isAuthenticated]);

  return (
    <div className="scheduler-homepage">
      <SchedulerMenu />
      <Routes>
        {/* Protected Routes */}
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <DentelHomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/patient"
          element={
            <PrivateRoute>
              <PatientPage />
            </PrivateRoute>
          }
        />
        {/* Login Route */}
        <Route path="/" element={<LoginPage />} />
        <Route path="/documents" element={<PatientDocuments />} />
        <Route path="/temp" element={<Temp />} />
        <Route path="/settings/access" element={<SettingsAccess />} />
        <Route path="/settings/office" element={<SettingsOffice />} />
        <Route path="/settings/clinicians" element={<SettingsClinicians />} />
        <Route path="/settings/chair" element={<SettingsChairs />} />
        <Route path="/settings/timeblock" element={<SettingsTimeBlocks />} />
        <Route path="/settings/dentalvisit" element={<SettingsDentalVisits />} />
        <Route path="/settings/orthovisit" element={<SettingsOrthoVisits />} />
        <Route path="/settings/hygienetrack" element={<SettingsHygieneTrack />} />
        <Route path="/settings/patientformautomation" element={<SettingsPatientFormAutomation />} />
        <Route path="/settings/patientdocument" element={<SettingsPatientDocuments />} />
        <Route path="/settings/goals" element={<SettingsOfficeGoals />} />
        <Route path="/settings/staff" element={<SettingsStaff />} />
        <Route path="/settings/auditlog" element={<SettingsAuditLog />} />
        <Route path="/settings/officeDocs" element={<SettingsOfficeDocument />} />
        <Route path="/settings/timePunches" element={<TimePunches />} />
        <Route path="/settings/checkin" element={<PatientPortalAndCheckIn />} />
        <Route path="/settings/notification" element={<SettingsNotification />} />
        <Route path="/settings/theme" element={<SettingsTheme />} />
        <Route path="/settings/profile" element={<SettingsProfile />} />
        <Route path="/settings/caseRanking" element={<SettingsCaseRanking />} />
        <Route path="/settings/procedure" element={<SettingsProcedureGroup />} />
        <Route path="/settings/dentalProducts" element={<SettingsDentalProduct />} />
        <Route path="/settings/membership" element={<SettingsMembershipPlan />} />
        <Route path="/settings/ePrescribing" element={<SettingsePrescribing />} />
        <Route path="/settings/casepresenter" element={<SettingsCasePresenter />} />
        <Route path="/settings/treatmentFavorites" element={<SettingsTreatmentFavorites />} />
        <Route path="/settings/xray" element={<SettingsXRayImaging />} />
        <Route path="/settings/patienteducation" element={<SettingsPatientEducation />} />
        <Route path="/settings/plan" element={<SettingsPlanAndFees />} />
        <Route path="/settings/insuranceVerification" element={<SettingsInsuranceVerification />} />
        <Route path="/settings/phone" element={<SettingssPhoneIntegration />} />
        <Route path="/settings/statements" element={<SettingsStatements />} />
        <Route path="/settings/adjustment" element={<SettingsAdjustment />} />
        <Route path="/settings/payment" element={<SettingsPaymentsAndClaims />} />
        <Route path="/settings/treatmentInterval" element={<SettingsTreatmentInterval />} />
        <Route path="/settings/onlineReviews" element={<SettingsOnlineReviews />} />
        <Route path="/settings/creditcard" element={<SettingsCreditCardAndPay />} />
        <Route path="/settings/automatedReminders" element={<SettingsAutomatedReminders />} />
        <Route path="/settings/textTemplate" element={<SettingsTextTemplate />} />
        <Route path="/settings/orthoLists" element={<SettingsOrthoLists />} />
        <Route path="/settings/dentalperiochart" element={<SettingsDentalAndPerioChart />} />
        <Route path="/admin/formlist" element={<AdminFormList />} />
        <Route path="/admin/prescriptions" element={<AdminPrescriptions />} />
        <Route path="/admin/healthissues" element={<AdminHealthIssue />} />
        <Route path="/admin/medications" element={<AdminMedications />} />
        <Route path="/admin/allergies" element={<AdminAllergies />} />
        <Route path="/admin/insurancecompanies" element={<AdminInsuranceCompanies />} />
        <Route path="/admin/employees" element={<AdminEmployees />} />
        <Route path="/admin/paidclaimwoeob" element={<AdminPaidClaimWoEOB />} />
        <Route path="/admin/billingclinics" element={<AdminBillingClinics />} />
        <Route path="/admin/pharmacies" element={<AdminPharmacies />} />
        <Route path="/admin/laboratories" element={<AdminLaboratories />} />
        <Route path="/admin/officenews" element={<AdminOfficeNews />} />
        <Route path="/account" element={<Accounts />} />
        <Route path="/admin/treatmentandfees" element={<AdminTreatmentAndFees />} />
        <Route path="/admin/notetemplates" element={<AdminNoteTemplates />} />
        <Route path="/admin/insuranceplans" element={<AdminInsurancePlans />} />
        <Route path="/admin/depositslips" element={<AdminDepositSlips />} />
        <Route path="/admin/textemailmarketing" element={<AdminTextEmailMarketing />} />
        <Route path="/admin/internalpatientreview" element={<AdminInternalPatientReview />} />
        <Route path="/admin/automatedcharges" element={<AdminAutomatedCharges />} />
        <Route path="/admin/connections" element={<AdminConnections />} />
        <Route path="/admin/referralsources" element={<AdminReferralSources />} />
      </Routes>
    </div>
  );
};

export default AppRouter;

import React, { useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import Label from "src/components/atoms/Label";
import { Checkbox } from "@progress/kendo-react-inputs";
import Dropdown from "src/components/atoms/Dropdown";
import InputField from "src/components/atoms/InputField";
import "./SettingsPaymentsAndClaims.css";

const SettingsPaymentsAndClaims = () => {
  const [paymentAssignment, setPaymentAssignment] = useState("Auto Suggested");
  const [defaultTo, setDefaultTo] = useState("Family");
  const [saveCardDetails, setSaveCardDetails] = useState(false);
  const [signatureOnFile, setSignatureOnFile] = useState(false);
  const [showTreatingClinician, setShowTreatingClinician] = useState(false);
  const [autoCharge, setAutoCharge] = useState("Balance");

  const paymentAssignmentOptions = ["Auto Suggested", "Manual"];
  const defaultToOptions = ["Family", "Individual"];
  return (
    <DatatableLayout
      title="Payments and Claims"
      onAdd={() => console.log("Add new Payments")}
      addBtnLabel="Payment Method"
      extraBtnLabel={["Account Statuses", "Claim Statuses"]}
      onExtraAction={() => console.log("Add new Account Claim")}
      saveButton="Save"
    >
      <div className="payments-and-claims-container">
        <h4>Sender</h4>
        <div className="payments-and-claims-sub-container">
          <div className="payments-and-claims-label-plus-component">
            <Label style={{ width: "300px" }}>Payment Assignment:</Label>
            <Dropdown
              options={paymentAssignmentOptions}
              value={paymentAssignment}
              onChange={setPaymentAssignment}
            />
          </div>

          <Checkbox
            label="Signature On File For Treating Clinician"
            checked={signatureOnFile}
            onChange={(e) => setSignatureOnFile(e.value)}
          />
          <Checkbox
            label="Save Credit Card Details"
            checked={saveCardDetails}
            onChange={(e) => setSaveCardDetails(e.value)}
          />
        </div>
        <div className="payments-and-claims-sub-container">
          <div className="payments-and-claims-label-plus-component">
            <Label style={{ width: "150px" }}>Default to:</Label>
            <Dropdown options={defaultToOptions} value={defaultTo} onChange={setDefaultTo} />
          </div>
          <div>
            <Checkbox
              label="Show Treating Clinician For Procedures"
              checked={showTreatingClinician}
              onChange={(e) => setShowTreatingClinician(e.value)}
            />
          </div>
          <div className="payments-and-claims-label-plus-component">
            <Label>Auto Charge:</Label>
            <div>
              <Label style={{ marginRight: "10px" }}>
                <input
                  type="radio"
                  value="Balance"
                  checked={autoCharge === "Balance"}
                  onChange={(e) => setAutoCharge(e.target.value)}
                />
                Balance
              </Label>
              <Label>
                <input
                  type="radio"
                  value="Install + Interest"
                  checked={autoCharge === "Install + Interest"}
                  onChange={(e) => setAutoCharge(e.target.value)}
                />
                Install + Interest
              </Label>
            </div>
          </div>
        </div>
        <div className="payments-and-claims-label-plus-component">
          <Label style={{ width: "200px" }}>Financing Terms:</Label>
          <InputField
            style={{
              width: "100%",
              height: "80px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              padding: "10px",
              resize: "none"
            }}
            defaultValue="The treatment financing plan terms and schedule of payments are listed above. Signing below means that you have already asked us any questions you have and fully understand the conditions of this plan."
          />
        </div>
      </div>
    </DatatableLayout>
  );
};

export default SettingsPaymentsAndClaims;

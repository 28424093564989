import React, { useState } from "react";
import DynamicForm from "../../organisms/DynamicForm";
import DynamicGrid from "../../organisms/DynamicGrid";
import CommonPopup from "../../molecules/CommonPopup";
import { Button } from "@progress/kendo-react-buttons";
import { labels } from "@progress/kendo-react-common";

const LabWorkPopup = ({ show, onClose }) => {
  const [formData, setFormData] = useState({});

  // Sample data for the grid
  const gridData = [
    // No records initially as shown in the screenshot
  ];

  const handleFormSubmit = (data) => {
    console.log("Form submitted with data:", data);
    setFormData(data);
  };

  const handleSearch = () => {
    console.log("Searching with criteria:", formData);
    // Implement search functionality here based on `formData`
  };

  // Define columns for DynamicGrid
  const columns = [
    { field: "visit", title: "Visit", width: "100px" },
    { field: "treatment", title: "Treatment", width: "100px" },
    { field: "patient", title: "Patient", width: "100px" },
    { field: "caseStatus", title: "Case Status", width: "100px" },
    { field: "lab", title: "Lab", width: "100px" },
    { field: "labContact", title: "Lab Contact", width: "100px" },
    { field: "note", title: "Note", width: "100px" },
  ];

  // Define the form structure with Criteria section and grid
  const formStructure = {
    title: "Lab Work",
    tabs: [
      {
        name: "Lab Work",
        sections: [
          // Criteria section
          {
            sectionName: "Search",
            fields: [
              {
                label: "Dates",
                name: "dates",
                type: "daterangepicker" // Use a custom type to identify the DateRangePicker

              },
              { label: "No Visit", name: "noVisit", type: "checkbox" },
              { label: "Completed", name: "completed", type: "checkbox" },

            ],
          },
          {
            sectionHeader: {
              right: [
                {
                  type: "button", text: "print",
                  icon: "print", onClick: () => console.log("Print clicked"),

                },
              ],

            },
          },
          // Lab Work Grid Section
          {
            customSection: (
              <DynamicGrid
                columns={columns}
                rows={gridData}
                allowCheckable={false}
                noRecordMessage="There are no family health issues"
              />
            ),
          },
        ],
      },
    ],
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Lab Work">
      {/* Render the DynamicForm inside CommonPopup */}
      <DynamicForm
        tabs={formStructure.tabs}
        handleFormSubmit={handleFormSubmit}
        formData={formData}
        handleInputChange={(value, fieldName) => setFormData((prev) => ({ ...prev, [fieldName]: value }))}
      />
    </CommonPopup>
  );
};

export default LabWorkPopup;

import React, { useState } from "react";
import Label from "src/components/atoms/Label";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import "./SettingsPlanAndFees.css";
import { Checkbox } from "@progress/kendo-react-inputs";
import Dropdown from "src/components/atoms/Dropdown";

const SettingsPlanAndFees = () => {
  const [option, setOption] = useState("Option 1");
  const options = ["Option 1", "Option 2", "Option 3"];

  return (
    <DatatableLayout title="Plan and Fees" saveButton="Save">
      <div className="plan-and-fees-container">
        <div className="plan-and-fees-sub-container">
          <Label style={{ width: "40%" }}>Update Out of Network Fees:</Label>
          <Checkbox />
        </div>
        <div className="plan-and-fees-sub-container">
          <Label style={{ width: "40%" }}>UCR Fees on Claims:</Label>
          <Checkbox />
        </div>
        <div className="plan-and-fees-sub-container">
          <Label style={{ width: "40%" }}>Assign Benefits to Clinician:</Label>
          <Checkbox />
        </div>
        <div className="plan-and-fees-sub-container">
          <Label style={{ width: "50%" }}>Benefits Coordination:</Label>
          <Dropdown options={options} value={option} onChange={setOption} />
        </div>
      </div>
    </DatatableLayout>
  );
};

export default SettingsPlanAndFees;

export const ChairsService = {
  getChairs: () => [
    {
      id: 1,
      chair: "DrPain",
      abbreviation: "AP",
      clinician: "Felicia Esteban",
      hygienist: "Jenny Joshi",
      hygiene: "Yes"
    },
    {
      id: 2,
      chair: "Broken Appointment",
      abbreviation: "BP",
      clinician: "Felicia Esteban",
      hygienist: "Jenny Joshi",
      hygiene: "Yes"
    },
    {
      id: 3,
      chair: "Broken Appointment",
      abbreviation: "BP",
      clinician: "Felicia Esteban",
      hygienist: "Jenny Joshi",
      hygiene: "Yes"
    }
    // more rows...
  ]
};

export const TimeBlockService = {
  getTimeBlocks: () => [
    { timeBlock: "DO NOT SCHEDULE HERE", color: "#4CAF50", inactive: "-" },
    { timeBlock: "Dr. Kang", color: "#FF5252", inactive: "Yes" },
    { timeBlock: "Emergency - Medical", color: "#FFC107", inactive: "-" }
  ]
};

export const DentalVisitService = {
  getDentalVisits: () => [
    { visit: "New Patient", procedures: "PreEx, LimEx", duration: "95 mins" },
    { visit: "Edit Patient", procedures: "PreEx, LimEx", duration: "95 mins" },
    { visit: "Recall", procedures: "PreEx, LimEx", duration: "95 mins" }
  ]
};

export const EPrescribingService = {
  getEPrescribing: () => [
    { office: "Eastern Clinic (EC)", officeID: "24744", officeKey: "GT4SQY7RDS2Q5FATJ4JWGT4HF2S5" },
    {
      office: "Smiles Clinic (SC)",
      officeID: "24744",
      officeKey: "GT4SQY7RDS2Q5FATJ4JWGT4HF2S5CYL"
    },
    {
      office: "Western Clinic (WC)",
      officeID: "35587",
      officeKey: "5U3ESFJXJAET2N95YYULNMTJD6LXP2"
    },
    { office: "Eastern Clinic (EC)", officeID: "24744", officeKey: "GT4SQY7RDS2Q5FATJ4JWGT4HF2S5" },
    {
      office: "Smiles Clinic (SC)",
      officeID: "24744",
      officeKey: "GT4SQY7RDS2Q5FATJ4JWGT4HF2S5CYL"
    },
    {
      office: "Western Clinic (WC)",
      officeID: "35587",
      officeKey: "5U3ESFJXJAET2N95YYULNMTJD6LXP2"
    },
    { office: "Eastern Clinic (EC)", officeID: "24744", officeKey: "GT4SQY7RDS2Q5FATJ4JWGT4HF2S5" },
    {
      office: "Smiles Clinic (SC)",
      officeID: "24744",
      officeKey: "GT4SQY7RDS2Q5FATJ4JWGT4HF2S5CYL"
    },
    {
      office: "Western Clinic (WC)",
      officeID: "35587",
      officeKey: "5U3ESFJXJAET2N95YYULNMTJD6LXP2"
    }
  ]
};

export const OrthoVisitService = {
  getOrthoVisits: () => [
    {
      visit: "Adjustment 2 Unit",
      procedures: "DetailedEval, 001",
      duration: "40",
      abbreviation: "Adj2"
    },
    {
      visit: "Adjustment 2 Unit",
      procedures: "DetailedEval, 001",
      duration: "40",
      abbreviation: "Adj2"
    },
    {
      visit: "Adjustment 2 Unit",
      procedures: "DetailedEval, 001",
      duration: "40",
      abbreviation: "Adj2"
    }
  ]
};

export const MembershipPlanService = {
  getMembershipPlan: () => [
    {
      planTitle: "Test",
      fees: "Standard Update",
      subscription: "Dental Membership",
      autoCharge: "NO",
      frequency: "Monthly",
      payment: "$20.00",
      patient: 3,
      feesDiscount: "Discount: 25% Off of Office Fees.",
      annualAllowances: "1 BWs, 1 Pano/FMX, 2 Exam"
    },
    {
      planTitle: "Test Plan",
      fees: "Standard Update",
      subscription: "Dental Membership",
      autoCharge: "yes",
      frequency: "Monthly",
      payment: "$20.00",
      patient: 2,
      feesDiscount: "Discount: 25% Off of Office Fees.",
      annualAllowances: "1 BWs, 1 Pano/FMX, 2 Exam"
    },
    {
      planTitle: "Test 1",
      fees: "Standard Update",
      subscription: "Dental Membership",
      autoCharge: "Yes",
      frequency: "Monthly",
      payment: "$20.00",
      patient: 1,
      feesDiscount: "Discount: 25% Off of Office Fees.",
      annualAllowances: "1 BWs, 1 Pano/FMX, 2 Exam"
    },
    {
      planTitle: "Test",
      fees: "Standard Update",
      subscription: "Dental Membership",
      autoCharge: "NO",
      frequency: "Monthly",
      payment: "$20.00",
      patient: 3,
      feesDiscount: "Discount: 25% Off of Office Fees.",
      annualAllowances: "1 BWs, 1 Pano/FMX, 2 Exam"
    },
    {
      planTitle: "Test Plan",
      fees: "Standard Update",
      subscription: "Dental Membership",
      autoCharge: "yes",
      frequency: "Monthly",
      payment: "$20.00",
      patient: 2,
      feesDiscount: "Discount: 25% Off of Office Fees.",
      annualAllowances: "1 BWs, 1 Pano/FMX, 2 Exam"
    },
    {
      planTitle: "Test 2",
      fees: "Standard Update",
      subscription: "Dental Membership",
      autoCharge: "Yes",
      frequency: "Monthly",
      payment: "$20.00",
      patient: 1,
      feesDiscount: "Discount: 25% Off of Office Fees.",
      annualAllowances: "1 BWs, 1 Pano/FMX, 2 Exam"
    },
    {
      planTitle: "Test",
      fees: "Standard Update",
      subscription: "Dental Membership",
      autoCharge: "NO",
      frequency: "Monthly",
      payment: "$20.00",
      patient: 3,
      feesDiscount: "Discount: 25% Off of Office Fees.",
      annualAllowances: "1 BWs, 1 Pano/FMX, 2 Exam"
    },
    {
      planTitle: "Test Plan",
      fees: "Standard Update",
      subscription: "Dental Membership",
      autoCharge: "yes",
      frequency: "Monthly",
      payment: "$20.00",
      patient: 2,
      feesDiscount: "Discount: 25% Off of Office Fees.",
      annualAllowances: "1 BWs, 1 Pano/FMX, 2 Exam"
    },
    {
      planTitle: "Test 3",
      fees: "Standard Update",
      subscription: "Dental Membership",
      autoCharge: "Yes",
      frequency: "Monthly",
      payment: "$20.00",
      patient: 1,
      feesDiscount: "Discount: 25% Off of Office Fees.",
      annualAllowances: "1 BWs, 1 Pano/FMX, 2 Exam"
    }
  ]
};

export const HygieneTrackService = {
  getOrthoVisits: () => [
    {
      recare: "Prophy",
      treatment: "D0150",
      interval: "5 mo, 1 day",
      conditions: "Adj2"
    },
    {
      recare: "Prophy",
      treatment: "D0150",
      interval: "5 mo, 1 day",
      conditions: "Adj2"
    },
    {
      recare: "Prophy",
      treatment: "D0150",
      interval: "5 mo, 1 day",
      conditions: "Adj2"
    }
  ]
};

export const DentalProductService = {
  getDentalProducts: () => [
    { dentalProduct: "Whitening Gel", procedureCodes: "T456", inactive: "-" },
    { dentalProduct: "Electric Tooth Brush", procedureCodes: "N200", inactive: "-" },
    { dentalProduct: "Test Dental Prod", procedureCodes: "D3312", inactive: "-" },
    { dentalProduct: "Whitening Gel", procedureCodes: "T456", inactive: "-" },
    { dentalProduct: "Electric Tooth Brush", procedureCodes: "N200", inactive: "-" },
    { dentalProduct: "Test Dental Prod", procedureCodes: "D3312", inactive: "-" },
    { dentalProduct: "Whitening Gel", procedureCodes: "T456", inactive: "-" },
    { dentalProduct: "Electric Tooth Brush", procedureCodes: "N200", inactive: "-" },
    { dentalProduct: "Test Dental Prod", procedureCodes: "D3312", inactive: "-" }
  ]
};

export const CaseRankingService = {
  getCaseRanking: () => [
    { id: 1, caseRanking: "Visit 1", color: "green", inactive: false },
    { id: 2, caseRanking: "Visit 2", color: "red", inactive: true },
    { id: 3, caseRanking: "Visit 3", color: "pink", inactive: false },
    { id: 4, caseRanking: "Option1", color: "yellow", inactive: false },
    { id: 5, caseRanking: "Option2", color: "gray", inactive: false },
    { id: 6, caseRanking: "Option3", color: "lightgray", inactive: false },
    { id: 7, caseRanking: "Option4", color: "pink", inactive: false },
    { id: 8, caseRanking: "Option5", color: "purple", inactive: false },
    { id: 9, caseRanking: "Option6", color: "gray", inactive: false }
  ]
};

export const ProcedureGroupService = {
  getProcedureGroup: () => [
    { group: "General", adaCodes: "D0000-D9999", coverage: "100%", electronicBenefits: "General" },
    {
      group: "Diagnostic",
      adaCodes: "D0000-D9999",
      coverage: "100%",
      electronicBenefits: "Diagnostic"
    },
    {
      group: "Preventive",
      adaCodes: "D0000-D9999",
      coverage: "100%",
      electronicBenefits: "RoutinePreventive"
    },
    {
      group: "Restorative",
      adaCodes: "D0000-D9999",
      coverage: "100%",
      electronicBenefits: "Restorative"
    },
    { group: "Endo", adaCodes: "D0000-D9999", coverage: "100%", electronicBenefits: "Endo" },
    { group: "Perio", adaCodes: "D0000-D9999", coverage: "100%", electronicBenefits: "Perio" },
    {
      group: "Oral Surgery",
      adaCodes: "D0000-D9999",
      coverage: "100%",
      electronicBenefits: "Oral Surgery"
    },
    { group: "Crowns", adaCodes: "D0000-D9999", coverage: "100%", electronicBenefits: "Crowns" },
    { group: "Prosth", adaCodes: "D0000-D9999", coverage: "100%", electronicBenefits: "Prosth" }
  ]
};

export const PatientFormService = {
  getPatientFormAutomation: () => [
    {
      scenario: "Patient Last Seen 2 Months Ago (Spanish)",
      form: "Checkbox Group Test, Consent - Composite Restoration (08-31-2022, 17:34:11), Amber Test"
    },
    {
      scenario: "Patient Last Seen 2 Months Ago (Spanish)",
      form: "Checkbox Group Test, Consent - Composite Restoration (08-31-2022, 17:34:11), Amber Test"
    },
    {
      scenario: "Patient Last Seen 2 Months Ago (Spanish)",
      form: "Checkbox Group Test, Consent - Composite Restoration (08-31-2022, 17:34:11), Amber Test"
    }
  ]
};

export const PatientDocumentService = {
  getPatientDocumentService: () => [
    {
      folder: "Profile Picture",
      autosave: "Tooth Charts",
      inactive: "-"
    },
    {
      folder: "folder",
      autosave: "Tooth Charts",
      inactive: "-"
    },
    {
      folder: "folder",
      autosave: "Tooth Charts",
      inactive: "-"
    }
  ]
};

export const TreatmentFavorites = {
  getTreatmentFavorites: () => [
    {
      TreatmentFavorite: "SRP for less teeth",
      Procedures: "SRP1-3, SRP1-3,",
      Inactive: false,
      ShowAboveChart: "Yes",
      ButtonIcon: ["edit", "delete"]
    },
    {
      TreatmentFavorite: "Test",
      Procedures: "PerEX, PA+, 3BW",
      Inactive: false,
      ShowAboveChart: "Yes",
      ButtonIcon: ["edit", "delete"]
    },
    {
      TreatmentFavorite: "UR",
      Procedures: "SRP",
      Inactive: false,
      ShowAboveChart: "Yes",
      ButtonIcon: ["edit", "delete"]
    },
    {
      TreatmentFavorite: "SRP for less teeth",
      Procedures: "SRP1-3, SRP1-3,",
      Inactive: false,
      ShowAboveChart: "Yes",
      ButtonIcon: ["edit", "delete"]
    },
    {
      TreatmentFavorite: "Test",
      Procedures: "PerEX, PA+, 3BW",
      Inactive: false,
      ShowAboveChart: "Yes",
      ButtonIcon: ["edit", "delete"]
    },
    {
      TreatmentFavorite: "UR",
      Procedures: "SRP",
      Inactive: false,
      ShowAboveChart: "Yes",
      ButtonIcon: ["edit", "delete"]
    },
    {
      TreatmentFavorite: "SRP for less teeth",
      Procedures: "SRP1-3, SRP1-3,",
      Inactive: false,
      ShowAboveChart: "Yes",
      ButtonIcon: ["edit", "delete"]
    },
    {
      TreatmentFavorite: "Test",
      Procedures: "PerEX, PA+, 3BW",
      Inactive: false,
      ShowAboveChart: "Yes",
      ButtonIcon: ["edit", "delete"]
    },
    {
      TreatmentFavorite: "UR",
      Procedures: "SRP",
      Inactive: false,
      ShowAboveChart: "Yes",
      ButtonIcon: ["edit", "delete"]
    }
  ]
};

export const PhoneIntegrationService = {
  getPhoneIntegration: () => [
    {
      extension: "101",
      name: "Back Office B",
      users: "Jenny Joshi, Rani Joshi",
      description: "Trial for 708-387-2020 - Local extension 1001"
    },
    {
      extension: "102",
      name: "Nirav",
      users: "Jenny Joshi, Rani Joshi",
      description: "This is a test ext."
    },
    {
      extension: "103",
      name: "Test",
      users: "Jenny Joshi, Rani Joshi",
      description: "This is a test ext."
    },
    {
      extension: "104",
      name: "Back Office B",
      users: "Jenny Joshi, Rani Joshi",
      description: "Trial for 708-387-2020 - Local extension 1001"
    },
    {
      extension: "105",
      name: "Nirav",
      users: "Jenny Joshi, Rani Joshi",
      description: "This is a test ext."
    },
    {
      extension: "106",
      name: "Test",
      users: "Jenny Joshi, Rani Joshi",
      description: "This is a test ext."
    },
    {
      extension: "107",
      name: "Back Office B",
      users: "Jenny Joshi, Rani Joshi",
      description: "Trial for 708-387-2020 - Local extension 1001"
    },
    {
      extension: "108",
      name: "Nirav",
      users: "Jenny Joshi, Rani Joshi",
      description: "This is a test ext."
    },
    {
      extension: "109",
      name: "Test",
      users: "Jenny Joshi, Rani Joshi",
      description: "This is a test ext."
    }
  ]
};

export const AdjustmentService = {
  getAdjustments: () => [
    { adjustment: "Referral Discount", type: "Credit", inactive: false },
    { adjustment: "Write off", type: "Credit", inactive: false },
    { adjustment: "Senior Discount", type: "Credit", inactive: false },
    { adjustment: "Discount", type: "Credit", inactive: false },
    { adjustment: "Refund", type: "Credit", inactive: false },
    { adjustment: "Prof Discount", type: "Credit", inactive: false },
    { adjustment: "Discount", type: "Credit", inactive: false },
    { adjustment: "Refund", type: "Credit", inactive: false },
    { adjustment: "Prof Discount", type: "Credit", inactive: false }
  ]
};

export const CreditCardAndPayService = {
  getCreditCardAndPay: () => [
    { name: "Whitening Gel" },
    { name: "Electric Tooth Brush" },
    { name: "Test Dental Prod" },
    { name: "Whitening Gel" },
    { name: "Electric Tooth Brush" },
    { name: "Test Dental Prod" }
  ]
};

export const OnDemandService = {
  getOnDemandData: () => [
    { name: "Default" },
    { name: "Recall Reminder" },
    { name: "Prom Special" },
    { name: "Test" },
    { name: "Test 1" },
    { name: "Default" },
    { name: "Recall Reminder" },
    { name: "Prom Special" },
    { name: "Test" },
    { name: "Test 1" }
  ]
};

export const AccountLedgerService = {
  getAccountLedgerData: () => [
    {
      date: "07/03/2023",
      patient: "Jeeny Joshi",
      type: "Tooth",
      activity: "D1517 space maintainer",
      tooth: 2,
      amount: 160,
      payment: 16,
      balance: 160
    },
    {
      date: "07/03/2023",
      patient: "Jeeny Joshi",
      type: "Tooth",
      activity: "D1517 space maintainer",
      tooth: 2,
      amount: 160,
      payment: 16,
      balance: 160
    },
    {
      date: "07/03/2023",
      patient: "Jeeny Joshi",
      type: "Tooth",
      activity: "D1517 space maintainer",
      tooth: 2,
      amount: 160,
      payment: 16,
      balance: 160
    },
    {
      date: "07/03/2023",
      patient: "Jeeny Joshi",
      type: "Tooth",
      activity: "D1517 space maintainer",
      tooth: 2,
      amount: 160,
      payment: 16,
      balance: 160
    },
    {
      date: "07/03/2023",
      patient: "Jeeny Joshi",
      type: "Tooth",
      activity: "D1517 space maintainer",
      tooth: 2,
      amount: 160,
      payment: 16,
      balance: 160
    }
    // Add more rows as needed
  ]
};

export const FinancingService = {
  getFinancingData: () => [
    {
      date: "07/03/2023",
      guarantor: "Jeeny Joshi",
      patient: "Jeeny Joshi",
      title: "Patient",
      planType: "Patient",
      loan: 3471.01,
      total: 3471.01,
      paid: 3471.01,
      balance: 3471.01,
      due: 3471.01
    },
    {
      date: "07/03/2023",
      guarantor: "Jeeny Joshi",
      patient: "Jeeny Joshi",
      title: "Patient",
      planType: "Patient",
      loan: 3471.01,
      total: 3471.01,
      paid: 3471.01,
      balance: 3471.01,
      due: 3471.01
    },
    {
      date: "07/03/2023",
      guarantor: "Jeeny Joshi",
      patient: "Jeeny Joshi",
      title: "Patient",
      planType: "Patient",
      loan: 3471.01,
      total: 3471.01,
      paid: 3471.01,
      balance: 3471.01,
      due: 3471.01
    },
    {
      date: "07/03/2023",
      guarantor: "Jeeny Joshi",
      patient: "Jeeny Joshi",
      title: "Patient",
      planType: "Patient",
      loan: 3471.01,
      total: 3471.01,
      paid: 3471.01,
      balance: 3471.01,
      due: 3471.01
    },
    {
      date: "07/03/2023",
      guarantor: "Jeeny Joshi",
      patient: "Jeeny Joshi",
      title: "Patient",
      planType: "Patient",
      loan: 3471.01,
      total: 3471.01,
      paid: 3471.01,
      balance: 3471.01,
      due: 3471.01
    },
    {
      date: "07/03/2023",
      guarantor: "Jeeny Joshi",
      patient: "Jeeny Joshi",
      title: "Patient",
      planType: "Patient",
      loan: 3471.01,
      total: 3471.01,
      paid: 3471.01,
      balance: 3471.01,
      due: 3471.01
    }
  ]
};

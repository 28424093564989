// FamilyMemberFields.js
const FamilyMemberFields = (prefix = "") => [
  { label: "First Name", name: `${prefix}firstName`, type: "input" },
  { label: "Last Name", name: `${prefix}lastName`, type: "input" },
  {
    label: "Gender",
    name: `${prefix}gender`,
    type: "select",
    options: [{ label: "Unknown", value: "Unknown" }]
  },
  {
    label: "Status",
    name: `${prefix}status`,
    type: "select",
    options: [{ label: "Married", value: "Married" }]
  },
  {
    label: "Birthdate",
    name: `${prefix}birthdate`,
    type: "date",
    placeholder: "month/day/year"
  },
  {
    label: "Insurance",
    name: `${prefix}insurance`,
    type: "checkboxGroup",
    options: [
      { label: "Ins plan 1", value: "Ins Plan 1" },
      { label: "Ins plan 2", value: "Ins Plan 2" }
    ]
  },
  { label: "Email", name: `${prefix}email`, type: "input" },
  {
    label: "Dentist",
    name: `${prefix}dentist`,
    type: "select",
    options: [{ label: "NS", value: "NS" }]
  },

  { label: "Add to Portal", name: `${prefix}addToPortal`, type: "checkbox" }
];

export default FamilyMemberFields;

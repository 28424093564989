import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import dataReducer from "./slices/dataSlice";
import clinicReducer from "./slices/clinicSlice";
import patientReducer from "./slices/patientSlice";
import alerReducer from "./slices/alertSlice.js";
import fastfillReducer  from "./slices/fastFillSlice";
const store = configureStore({
  reducer: {
    auth: authReducer,
    data: dataReducer,
    clinics: clinicReducer,
    patient: patientReducer,
    alerts: alerReducer,
    fastFills:fastfillReducer
  }
});

export default store;

import React, { useState } from "react";
import InputField from "src/components/atoms/InputField";
import Label from "src/components/atoms/Label";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import { Icon } from "@progress/kendo-react-common";
import "./SettingsXRayImaging.css";

const SettingsXRayImaging = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <DatatableLayout title="X-Ray Imaging" saveButton="Save">
      <div className="login-form">
        <div className="form-field">
          <Label style={{ width: "30%" }}>XWeb URL:</Label>
          <InputField type="text" placeholder="Enter XWeb URL" />
        </div>
        <div className="form-field">
          <Label style={{ width: "30%" }}>Username:</Label>
          <InputField type="text" placeholder="Enter Username" />
        </div>
        <div className="form-field password-field">
          <Label style={{ width: "30%" }}>Password:</Label>
          <div className="password-input-wrapper">
            <InputField
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter Password"
              
            />
            <Icon
              name={showPassword ? "eye-slash" : "eye"}
              className="password-toggle-icon"
              onClick={togglePasswordVisibility}
            />
          </div>
        </div>
      </div>
    </DatatableLayout>
  );
};

export default SettingsXRayImaging;

import React, { useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import "./TimePunches.css";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const TimePunches = () => {
  const [status, setStatus] = useState("Active");
  const totalHoursOptions = ["Hours and min", "Decimal"];
  const overtimeAfterOptions = ["Hours Weekly", "Hours Daily"];
  const payrollCycleOptions = ["Weekly", "Bi-Weekly", "Monthly", "Custom"];

  return (
    <DatatableLayout
      title="Time Punches"
      statusOptions={["Active", "Inactive"]}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new chair")}
      addBtnLabel="Folder"
      saveButton="Save"
    >
      <div className="time-punches">
        <GridLayout
          columns={2}
          gap={{ rows: 20, columns: 10 }}
          align={{ horizontal: "center", vertical: "middle" }}
        >
          <GridLayoutItem className="time-punches-grid-layout">
            <label className="time-punches-label">Total Hours:</label>
            <DropDownList data={totalHoursOptions} style={{ width: "200px" }} />
          </GridLayoutItem>

          <GridLayoutItem className="time-punches-grid-layout">
            <label className="time-punches-label">Overtime After:</label>
            <div className="overtime-group">
              <Input defaultValue={8} type="number" />
              <DropDownList data={overtimeAfterOptions} style={{ width: "200px" }} />
            </div>
          </GridLayoutItem>

          <GridLayoutItem className="time-punches-grid-layout">
            <label className="time-punches-label">Payroll Cycle:</label>
            <DropDownList
              data={payrollCycleOptions}
              defaultValue="Custom"
              style={{ width: "200px" }}
            />
          </GridLayoutItem>

          <GridLayoutItem className="time-punches-grid-layout">
            <label className="time-punches-label">Begin On:</label>
            <DatePicker defaultValue={new Date("2024-01-05")} style={{ width: "200px" }} />
          </GridLayoutItem>

          <GridLayoutItem className="time-punches-grid-layout">
            <label className="time-punches-label">Custom Dates:</label>
            <div className="custom-dates-group">
              <DatePicker defaultValue={new Date("2023-11-01")} style={{ width: "200px" }} />
              <DatePicker defaultValue={new Date("2023-11-10")} style={{ width: "200px" }} />
            </div>
          </GridLayoutItem>

          <GridLayoutItem className="time-punches-grid-layout">
            <label className="time-punches-label">Daily Break (in Min):</label>
            <Input defaultValue={30} type="number" style={{ width: "200px" }} />
          </GridLayoutItem>

          <GridLayoutItem className="time-punches-grid-layout">
            <label className="time-punches-label">Paid Break:</label>
            <Switch />
          </GridLayoutItem>
        </GridLayout>
      </div>
    </DatatableLayout>
  );
};

export default TimePunches;

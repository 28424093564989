import React, { useState, useEffect } from "react"
import { Checkbox } from "@progress/kendo-react-inputs"
import DynamicGrid from '../../../../organisms/DynamicGrid';
import FormActionsBar from '../../../../organisms/FormActionBar';
import Button from '../../../../atoms/Button';
import { getHealthIssues } from "../../../../../services/health-info";

const HealthIssues = () => {
  const [healthIssues, setHealthIssues] = useState([]);

  useEffect(() => {
    // if (clinicId && patientId) {
      getHealthIssues().then(setHealthIssues);
    // }
  }, []);

  const columns = [
    {
        field:"issue",
        title: "Health Issue",
        width:"250px"
    },
    {
        field: "comment",
        title: "Comment",
        width: "250px"
    },
    {
      field: "status",
      title: "Status",
      width: "250px"
    }
  ];

  return <div >
    {/* <FormActionsBar>
      <Checkbox label={"Inactive"} />
      <Button icon="plus" themeColor="primary"> Health Issues </Button>
    </FormActionsBar> */}
    <DynamicGrid 
      columns={columns}
      rows={healthIssues}
      key={healthIssues.length}
      >
    </DynamicGrid>
    {/* <div className="k-form-buttons" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginBottom: '10px' }}>
      <Button themeColor="primary">Save</Button>
    </div> */}
  </div>
}

export default HealthIssues

import React, { useEffect, useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { AdjustmentService } from "../../../services/SettingsService";
import Label from "src/components/atoms/Label";
import Dropdown from "src/components/atoms/Dropdown";
import InputField from "src/components/atoms/InputField";
import { Switch } from "@progress/kendo-react-inputs";
import "./SettingsAdjustment.css";

const SettingsAdjustment = () => {
  const [adjustmentData, setAdjustmentData] = useState([]);
  const adjustmentOptions = ["Missed App", "Other Adjustment"];
  const [selectedAdjustment, setSelectedAdjustment] = useState("Missed App");
  const [missedVisitFee, setMissedVisitFee] = useState(25);
  const [showPriorExam, setShowPriorExam] = useState(true);

  useEffect(() => {
    const data = AdjustmentService.getAdjustments();
    setAdjustmentData(data);
  }, []);

  const columns = [
    { field: "adjustment", title: "Adjustment" },
    { field: "type", title: "Type" },
    { field: "inactive", title: "Inactive" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    }
  ];
  return (
    <DatatableLayout
      title="Adjustment"
      onAdd={() => console.log("Add new Adjustment")}
      addBtnLabel="Adjustment"
      extraBtnLabel={["Print"]}
      onExtraAction={() => console.log("Add new Adjustment")}
      saveButton="Save"
    >
      <DynamicGrid
        columns={columns}
        rows={adjustmentData}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
      <div className="settings-adjustment-actions-container">
        <div className="settings-adjustments-action">
          <Label style={{ marginRight: "10px" }}>Perio Chart - Show Prior Exam:</Label>
          <Switch checked={showPriorExam} onChange={(e) => setShowPriorExam(e.target.value)} />
        </div>
        <div className="settings-adjustments-action">
          <Label style={{ marginRight: "10px", width: "330px" }}>Missed Visit Adjustment:</Label>
          <Dropdown
            options={adjustmentOptions}
            value={selectedAdjustment}
            onChange={setSelectedAdjustment}
          />
        </div>
        <div className="settings-adjustments-action">
          <Label style={{ marginRight: "10px", width: "200px" }}>Missed Visit Fee:</Label>
          <InputField
            type="number"
            value={missedVisitFee}
            onChange={(e) => setMissedVisitFee(Number(e.target.value))}
          />
        </div>
      </div>
    </DatatableLayout>
  );
};

export default SettingsAdjustment;

import React, { useEffect, useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { MembershipPlanService } from "src/services/SettingsService";

const SettingsMembershipPlan = () => {
  const [status, setStatus] = useState("Active");
  const [membershipPlan, setMembershipPlan] = useState([]);

  useEffect(() => {
    const data = MembershipPlanService.getMembershipPlan();
    setMembershipPlan(data);
  }, []);

  const columns = [
    { field: "planTitle", title: "Plan Title" },
    { field: "fees", title: "Fees" },
    { field: "subscription", title: "Subscription" },
    { field: "autoCharge", title: "Auto Charge" },
    { field: "frequency", title: "Frequency" },
    { field: "payment", title: "Payment" },
    { field: "patient", title: "Patient" },
    { field: "feesDiscount", title: "Fees Discount" },
    { field: "annualAllowances", title: "Annual Allowances" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];
  return (
    <DatatableLayout
      title="Membership Plan"
      statusOptions={["Active", "Inactive"]}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new chair")}
      addBtnLabel="Membership Plans"
      saveButton="Save"
    >
      <DynamicGrid
        columns={columns}
        rows={membershipPlan}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default SettingsMembershipPlan;

import React, { useRef, useState } from "react";
import CommonPopup from "../../../molecules/CommonPopup"; // Import the reusable CommonPopup component
import DynamicForm from "../../../organisms/DynamicForm"; // Import the dynamic form component
import PatientVisitGrid from "./PatientVisitsGrid"; // Import the custom patient visit grid component


const PatientVisitPopup = ({ show, onClose, patients=[] }) => {
  const [selectedPatient, setSelectedPatient] = useState();
  const gridRef = useRef();

  // Handle patient change (e.g., through dropdown selection)
  const handlePatientChange = (event) => {
    const selectedPatientId = event.value;
    const patient = patients.find((p) => p.id === selectedPatientId);
    setSelectedPatient(patient);
  };
   const handlePrint = () => {
    console.log("print gridd",gridRef)
     gridRef.current.printGrid();
   };

  // Define the dynamic form structure for the selected patient
  const formStructure = {
    title: "Patient Visit",
    tabs: [
      {
        name: "Patient Info",
        header: {
          left: [
            {
              label: "Patient",
              name: "patient",
              type: "select",
              placeholder: "Select a patient",
              options: patients.map((patient) => ({
                label: patient.name,
                value: patient.id
              })),
              onChange: handlePatientChange
            }
          ],
          right: [
            {
              type: "button",
              text: "Family Recare",
              onClick: () => alert("Family Recare clicked"),
              icon: "add"
            },
            {
              type: "button",
              text: "Patient Recare",
              onClick: () => alert("Patient Recare clicked"),
              icon: "add"
            },
            {
              type: "button",
              text: "Print",
              onClick: () => handlePrint(),
              icon: "print"
            }
          ]
        },
        sections: [
          {
            customSection: (
              <PatientVisitGrid
                gridRef={gridRef}
                data={patients} // Pass visit data to the grid
              />
            )
          }
        ]
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title={"Patient Visit"}>
      {/* Dynamic Form for Patient Information */}
      <DynamicForm
        tabs={formStructure.tabs}
        handleFormSubmit={() => alert("Form Submitted")}
      />
    </CommonPopup>
  );
};

export default PatientVisitPopup;



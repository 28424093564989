import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { TextEmailMarketingService } from "../../../services/AdminService";

const AdminTextEmailMarketing = () => {
  const [textEmailMarketing, setTextEmailMarketing] = useState([]);

  useEffect(() => {
    const data = TextEmailMarketingService.getTextEmailMarketing();
    setTextEmailMarketing(data);
  }, []);

  const columns = [
    { field: "group", title: "Date" },
    { field: "note", title: "Type" },
    { field: "emailpatient", title: "Email Patient" },
    { field: "mobilepatient", title: "Mobile Patient" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  return (
    <DatatableLayout
      title="Marketing Group"
      onAdd={() => console.log("Add new Group")}
      addBtnLabel="Group"
      extraBtnLabel={["Sync Contact", "Invalid Email Report"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={textEmailMarketing}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminTextEmailMarketing;

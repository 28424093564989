import React, { useEffect, useState } from "react";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import { PatientDocumentService } from "src/services/SettingsService";

const SettingsPatientDocuments = () => {
  const [patientDocuments, setPatientDocuments] = useState([]);

  useEffect(() => {
    const data = PatientDocumentService.getPatientDocumentService();
    setPatientDocuments(data);
  }, []);

  const columns = [
    { field: "folder", title: "Folder" },
    { field: "autosave", title: "AutoSave" },
    { field: "inactive", title: "Inactive" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    }
  ];
  return (
    <DatatableLayout onAdd={() => console.log("Add new visit")} addBtnLabel="Scenario">
      <DynamicGrid
        columns={columns}
        rows={patientDocuments}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default SettingsPatientDocuments;

import React, { useEffect, useState } from "react";
import "@progress/kendo-theme-default/dist/all.css";
import { OnDemandService } from "../../../../../services/SettingsService";
import Dropdown from "src/components/atoms/Dropdown";
import Label from "src/components/atoms/Label";
import Button from "src/components/atoms/Button";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import InputField from "src/components/atoms/InputField";
import "./OnDemand.css";
import { Hint } from "@progress/kendo-react-labels";
import pinIcon from "../../../../../assets/images/pin.png";
import chatIcon from "../../../../../assets/images/chat.png";
import smileyIcon from "../../../../../assets/images/smile.png";
import hatIcon from "../../../../../assets/images/hat.png";

const OnDemand = () => {
  const max = 300;
  const [value, setValue] = useState("");
  const [template, setTemplates] = useState([]);
  const [status, setStatus] = useState("Active");
  const [clinic, setClinic] = useState("Clinic 1");
  const [mergeField, setMergeField] = useState("Merge 1");
  const [textTemplate, setTextTemplate] = useState("Merge 1");

  const statusOptions = ["Actve", "InActive"];
  const clinicOptions = ["Clinic 1", "Clinic 2"];
  const mergeOptions = ["Merge 1", "Merge 2"];
  const textTemplateOptions = ["Actve", "InActive"];

  const handleChange = (e) => {
    setValue(e.value);
  };
    const renderIcons = () => (
      <div className="icons-container">
        <img src={pinIcon} alt="Pin" className="icon" title="Pin" />
        <img src={chatIcon} alt="Chat" className="icon" title="Chat" />
        <img src={smileyIcon} alt="Smiley" className="icon" title="Smiley" />
        <img src={hatIcon} alt="Hat" className="icon" title="Hat" />
      </div>
    );

  useEffect(() => {
    const data = OnDemandService.getOnDemandData();
    setTemplates(data);
  }, []);

  const columns = [{ field: "name", title: "Template" }];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  return (
    <div className="on-demand-container">
      {/* Left Column */}
      <div className="on-demand-column">
        <div className="on-demand-label-plus-component">
          <Label>Clinic</Label>
          <Dropdown
            style={{ width: "200px" }}
            onChange={setClinic}
            options={clinicOptions}
            value={clinic}
          />
        </div>

        <div className="on-demand-label-plus-component">
          <Label style={{ width: "100px" }}>Text Template</Label>
          <Dropdown
            style={{ width: "200px" }}
            onChange={setTextTemplate}
            value={textTemplate}
            options={textTemplateOptions}
          />
        </div>

        <div className="on-demand-sub-container">
          <h4 style={{ top: "270px" }}>English Text</h4>
          <div className="on-demand-label-plus-component">
            <Label style={{ width: "100px" }}>Merge Fields</Label>
            <Dropdown
              style={{ width: "200px" }}
              options={mergeOptions}
              value={mergeField}
              onChange={setMergeField}
            />
            {renderIcons()}
          </div>
          <InputField maxLength={max} value={value} onChange={handleChange} />
          <Hint>
            {value.length} / {max}
          </Hint>
          <Button className="add-template-on-demand">Translate</Button>
        </div>

        <div className="on-demand-sub-container">
          <h4 style={{ top: "464px" }}>Spanish Text</h4>
          <div className="on-demand-label-plus-component">
            <Label style={{ width: "100px" }}>Merge Fields</Label>
            <Dropdown
              style={{ width: "200px" }}
              options={mergeOptions}
              value={mergeField}
              onChange={setMergeField}
            />
          </div>
          <InputField maxLength={max} value={value} onChange={handleChange} />
          <Hint>
            {value.length} / {max}
          </Hint>
          <Button className="add-template-on-demand">Translate</Button>
        </div>
      </div>

      {/* Right Column */}
      <div className="on-demand-column">
        <div className="on-demand-status-plus-button">
          <Label>Status</Label>
          <Dropdown
            style={{ width: "150px" }}
            options={statusOptions}
            value={status}
            onChange={setStatus}
          />
          <Button className="add-template" icon="plus">
            Add Template
          </Button>
        </div>
        <DynamicGrid
          columns={columns}
          rows={template}
          allowSort={false}
          allowFilter={false}
          actions={actions}
        />
      </div>
    </div>
  );
};

export default OnDemand;

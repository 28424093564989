// InsurancePlanFields.js


const InsurancePlanFields = (prefix) => [
  {
    label: "Subscriber",
    name: `${prefix}subscriber`,
    type: "select",
    options: [{ label: "None", value: "None" }]
  },
  {
    label: "Subscriber ID",
    name: `${prefix}subscriberId`,
    type: "input"
  },
  {
    label: "Insurance",
    name: `${prefix}insurance`,
    type: "composite",
    subFields: [
      {
        name: `${prefix}insuranceText`,
        type: "input",
        placeholder: "Enter Insurance"
      },
      {
        name: `${prefix}addInsuranceButton`,
        type: "iconButton",
        icon: "plus",
        title: "Add Insurance",
        onClick: () => console.log("Add insurance clicked")
      }
    ]
  },
  {
    label: "Phone",
    name: `${prefix}phone`,
    type: "input"
  },
  {
    label: "Employer",
    name: `${prefix}employer`,
    type: "input"
  },
  {
    label: "Group Name",
    name: `${prefix}groupName`,
    type: "input"
  },
  {
    label: "Group ID",
    name: `${prefix}groupId`,
    type: "input"
  }
];

export default InsurancePlanFields;

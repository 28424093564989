// components/pages/LoginPage.js
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Navigate } from 'react-router-dom';

const LoginPage = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    if (isAuthenticated) {
        // Redirect to Azure AD B2C login page if not authenticated
        return <Navigate to="/home"/>

  }


  return (
    <div className="login-page" style={{height:"100vh"}}>
      <h2 style={{color:"#fff"}}>Login to continue </h2>
    </div>
  );
};

export default LoginPage;

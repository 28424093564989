import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPatient } from "../actions/patientAction";

const patientSlice = createSlice({
  name: "patient",
  initialState: {
    patient: {
      id: null,
      name: null,
      dob: null,
      gender: null,
      email: null,
      phone: null,
      mobile: null,
      address: {
        street: null,
        city: null,
        state: null,
        zip: null
      },
      recareVisit: null,
      patientSince: null,
      nextVisit: null,
      source: null,
      totalProduction: null,
      insurance: null,
      familyBalance: null,
      benefits: null,
      familyMembers: [] // Array for family members
    },
    loading: false,
    error: null
  },

  reducers: {
    setPatient(state, action) {
      state.patient = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchPatient.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchPatient.fulfilled, (state, action) => {
      state.loading = false;
      state.patient = action.payload;
    });

    builder.addCase(fetchPatient.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  }
});

export const selectPatient = (state) => state.patient.patient;
export const { setPatient } = patientSlice.actions;
export default patientSlice.reducer;

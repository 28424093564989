import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import CommonPopup from "src/components/molecules/CommonPopup";
import CardUploadSection from "src/components/molecules/CardUploadSection"; // Import the new component
import "./Insurance.css";

const UploadInsuranceCardPopup = ({ show, onClose }) => {
  return (
    <CommonPopup show={show} onClose={onClose} title="Upload Ins 1 Card">
      <div className="upload-insurance-card-container">
        <div className="upload">
          <CardUploadSection
            title="Upload Front of Card"
            placeholderText="Drag & Drop your front card here"
            icon="file"
          />
          <CardUploadSection
            title="Upload Back of Card"
            placeholderText="Drag & Drop your back card here"
            icon="file"
          />
        </div>

        <div className="upload-actions">
          <Button look="outline" icon="close" onClick={onClose} className="add-button">
            Cancel
          </Button>
          <Button primary={true} icon="save" className="add-button">
            Save
          </Button>
        </div>
      </div>
    </CommonPopup>
  );
};

export default UploadInsuranceCardPopup;

import React, { useEffect, useState } from "react";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import { TreatmentFavorites } from "src/services/SettingsService";

const SettingsTreatmentFavorites = () => {
  const [status, setStatus] = useState("Test");
  const [treatmentFavorites, setTreatmentFavorites] = useState([]);
  useEffect(() => {
    const data = TreatmentFavorites.getTreatmentFavorites();
    setTreatmentFavorites(data);
  }, []);

  const settingsTreatmentFavoritesColumns = [
    { field: "TreatmentFavorite", title: "Treatment favorite" },
    { field: "Procedures", title: "Procedures" },
    { field: "Inactive", title: "Inactive" },
    { field: "ShowAboveChart", title: "Show Above Chart" },
    { field: "ButtonIcons", title: "Button icons" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Edit Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];
  return (
    <DatatableLayout
      title="Treatment Favorites"
      statusOptions={["Active", "Test"]}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new chair")}
      addBtnLabel={"Treatment Favorite"}
      extraBtnLabel={["Favorite Groups", "Smart Procedures"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
      saveButton="Save"
    >
      <DynamicGrid
        columns={settingsTreatmentFavoritesColumns}
        rows={treatmentFavorites}
        actions={actions}
        allowCheckable={true}
        allowSort={false}
        allowFilter={false}
      />
    </DatatableLayout>
  );
};

export default SettingsTreatmentFavorites;

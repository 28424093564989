import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { BillingClinicService } from "../../../services/AdminService";

const AdminBillingClinics = () => {
  const [billingClinics, setBillingClinics] = useState([]);

  useEffect(() => {
    const data = BillingClinicService.getBillingClinics();
    setBillingClinics(data);
  }, []);

  const columns = [
    { field: "clinicname", title: "Clinic Name" },
    { field: "abbreviation", title: "Abbreviation" }
  ];

  const actions = [
    {
      icon: "share", // Edit Icon
      title: "Share",
      onClick: (dataItem) => alert(`Share ${dataItem.template || dataItem.treatment}`)
    }
  ];

  return (
    <DatatableLayout title="Billing Clinic">
      <DynamicGrid
        columns={columns}
        rows={billingClinics}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminBillingClinics;

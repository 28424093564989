// src/components/atoms/Label/Label.js
import React from 'react';
import { Label as KendoLabel } from '@progress/kendo-react-labels';
import './Label.css'; // Optional: Create a CSS file for custom styles

const Label = ({ htmlFor, children, className, style }) => {
  return (
    <KendoLabel htmlFor={htmlFor} className={className} style={style}>
      {children}
    </KendoLabel>
  );
};

export default Label;

import React, { useState } from "react";
import DynamicForm from "../../../organisms/DynamicForm"; // Assuming DynamicForm is your form component
import DynamicGrid from "../../../organisms/DynamicGrid"; // Assuming DynamicGrid is your grid component
import CommonPopup from "../../../molecules/CommonPopup"; // Assuming you have CommonPopup component
import "./NoteTemplates.css";

// Mock data for grids
const noteTemplates = [
  { id: 1, template: "{Claim Note} [Filling Cause] Hello testing note", scheduled: "Testprompt" },
  { id: 2, template: "This is test for patient fun fact", scheduled: "Funfact" }
];

const groupData = [
  { id: 1, treatment: "Account" },
  { id: 2, treatment: "Medication" },
  { id: 3, treatment: "Rx" },
  { id: 4, treatment: "New Adjustment" },
  { id: 5, treatment: "Statement" },
  { id: 6, treatment: "ChartTx Notes" },
  { id: 7, treatment: "Payment Plan" },
  { id: 8, treatment: "Visit Notes" }
];

// Custom actions for the grid (Edit, Delete)
const actions = [
  {
    icon: "edit",
    title: "Edit",
    onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
  },
  {
    icon: "delete",
    title: "Delete",
    onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
  }
];

// Columns configuration for the grids
const noteTemplateColumns = [
  { field: "template", title: "Template" },
  { field: "scheduled", title: "Scheduled" }
];

const groupColumns = [{ field: "treatment", title: "Treatment" }];

const NoteTemplateManager = ({ show, onClose }) => {
  const [noteStatus, setNoteStatus] = useState("Active");
  const [groupStatus, setGroupStatus] = useState("Active");

  const formStructure = {
    title: "Note Template Manager",
    tabs: [
      {
        name: "Note Template Manager",
        sections: [
          {
            sectionHeader: {
              left: [
                {
                  type: "select",
                  label: "Status",
                  name: "noteStatus",
                  options: [
                    { label: "Active", value: "Active" },
                    { label: "Inactive", value: "Inactive" }
                  ],
                  value: noteStatus,
                  onChange: (event) => setNoteStatus(event.target.value)
                }
              ],
              right: [
                {
                  type: "button",
                  text: "+ Note",
                  onClick: () => alert("Add Note clicked")
                }
              ]
            },
            customSection: (
              <DynamicGrid
                columns={noteTemplateColumns}
                rows={noteTemplates}
                actions={actions}
                allowSort={false}
                allowFilter={false}
              />
            )
          },
          {
            sectionHeader: {
              left: [
                {
                  type: "select",
                  label: "Status",
                  name: "groupStatus",
                  options: [
                    { label: "Active", value: "Active" },
                    { label: "Inactive", value: "Inactive" }
                  ],
                  value: groupStatus,
                  onChange: (event) => setGroupStatus(event.target.value)
                }
              ],
              right: [
                {
                  type: "button",
                  text: "+ Group",
                  onClick: () => alert("Add Group clicked")
                }
              ]
            },
            customSection: (
              <DynamicGrid
                columns={groupColumns}
                rows={groupData}
                actions={actions}
                allowSort={false}
                allowFilter={false}
              />
            )
          }
        ],
        footer: {
          right: [
            {
              type: "button",
              text: "AI Text Generator",
              onClick: () => alert("AI Text Generator clicked")
            },
            {
              type: "button",
              text: "Manage Fast Fills",
              onClick: () => alert("Manage Fast Fills clicked")
            }
          ]
        }
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Note Template Manager" className="noteTemp">
      <DynamicForm tabs={formStructure.tabs} formData={{}} handleFormSubmit={() => {}} />
    </CommonPopup>
  );
};

export default NoteTemplateManager;

// PreventiveAndDiagnosticTab.js
const PreventiveAndDiagnosticTab = {
  name: "Preventive and Diagnostic",
  layout: "preventative",
  sections: [
    {
      sectionName: "Preventive and Diagnostic",
      fields: [
        { label: "Interval", name: "interval", type: "numerictextbox", icon: "percent" },
        { label: "Diagnostic", name: "diagnostic", type: "numerictextbox", icon: "percent" },
        { label: "X-Ray", name: "xray", type: "numerictextbox", icon: "percent" },
        { label: "All", name: "allPrevention", type: "numerictextbox", icon: "percent" }
      ]
    },
    {
      sectionName: "Preventive and Diagnostic",
      fields: [
        { label: "Restorative", name: "restorative", type: "numerictextbox", icon: "percent" },
        { label: "Endodontics", name: "endodontics", type: "numerictextbox", icon: "percent" },
        { label: "Periodontics", name: "periodontics", type: "numerictextbox", icon: "percent" },
        { label: "Oral Surgery", name: "oralSurgery", type: "numerictextbox", icon: "percent" },
        { label: "All", name: "allDiagnostic", type: "numerictextbox", icon: "percent" }
      ]
    },
    {
      sectionName: "Major Restorative Care",
      fields: [
        { label: "Crowns", name: "crowns", type: "numerictextbox", icon: "percent" },
        {
          label: "Prosthodontics",
          name: "prosthodontics",
          type: "numerictextbox",
          icon: "percent"
        },
        { label: "All", name: "allRestorative", type: "numerictextbox", icon: "percent" },
        { label: "Maxillofacial", name: "maxillofacial", type: "numerictextbox", icon: "percent" },
        { label: "Accident", name: "accident", type: "numerictextbox", icon: "percent" }
      ]
    },
    {
      sectionName: "P&D Deductibles (optional)",
      layout: "custom",
      fields: [
        {
          label: "Patient",
          name: "pdPatient",
          type: "composite",
          subFields: [
            { name: "pdPatient1", type: "numerictextbox", icon: "percent" },
            { name: "pdPatient2", type: "numerictextbox", icon: "percent" },
            { name: "pdPatient3", type: "numerictextbox", icon: "percent" }
          ]
        },
        {
          label: "Family",
          name: "pdFamily",
          type: "composite",
          subFields: [
            { name: "pdFamily1", type: "numerictextbox", icon: "percent" },
            { name: "pdFamily2", type: "numerictextbox", icon: "percent" },
            { name: "pdFamily3", type: "numerictextbox", icon: "percent" }
          ]
        }
      ]
    },
    {
      sectionName: "Orthodontics",
      fields: [
        { label: "Coverage", name: "orthoCoverage", type: "numerictextbox", icon: "percent" },
        {
          label: "Insurance Share",
          name: "insuranceShare",
          type: "numerictextbox",
          icon: "percent"
        },
        { label: "Comments", name: "comments", type: "textarea" }
      ]
    }
  ]
};

export default PreventiveAndDiagnosticTab;

import React, { useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import Label from "src/components/atoms/Label";
import { Switch } from "@progress/kendo-react-inputs";
import "./SettingsProfile.css";

const SettingsProfile = () => {
  const [switches, setSwitches] = useState({
    showHealthAlert: true,
    showRecareVisit: true,
    showSource: true,
    showMobile: true,
    showFamilyBalance: true,
    showBenefits: true,
    showBirthDetails: true,
    showPatientSince: true,
    showLastNextVisit: true,
    showTotalProduction: true,
    showInsurance: true
  });

  // Handler to toggle each switch
  const handleToggle = (key) => {
    setSwitches((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  };

  return (
    <DatatableLayout title="Profile" saveButton="Save">
      <div className="settings-profile-container">
        <div>
          <div className="settings-profile-label-plus-switch">
            <Label style={{ marginRight: "0.5rem" }}>Show Health Alert:</Label>
            <Switch
              onLabel="Yes"
              checked={switches.showHealthAlert}
              onChange={() => handleToggle("showHealthAlert")}
            />
          </div>
          <div className="settings-profile-label-plus-switch">
            <Label style={{ marginRight: "0.5rem" }}>Show Recare Visit:</Label>
            <Switch
              onLabel="Yes"
              checked={switches.showRecareVisit}
              onChange={() => handleToggle("showRecareVisit")}
            />
          </div>
          <div className="settings-profile-label-plus-switch">
            <Label style={{ marginRight: "0.5rem" }}>Show Source:</Label>
            <Switch
              onLabel="Yes"
              checked={switches.showSource}
              onChange={() => handleToggle("showSource")}
            />
          </div>
        </div>

        <div>
          <div className="settings-profile-label-plus-switch">
            <Label style={{ marginRight: "0.5rem" }}>Show Mobile:</Label>
            <Switch
              onLabel="Yes"
              checked={switches.showMobile}
              onChange={() => handleToggle("showMobile")}
            />
          </div>
          <div className="settings-profile-label-plus-switch">
            <Label style={{ marginRight: "0.5rem" }}>Show Family Balance:</Label>
            <Switch
              onLabel="Yes"
              checked={switches.showFamilyBalance}
              onChange={() => handleToggle("showFamilyBalance")}
            />
          </div>
          <div className="settings-profile-label-plus-switch">
            <Label style={{ marginRight: "0.5rem" }}>Show Benefits:</Label>
            <Switch
              onLabel="Yes"
              checked={switches.showBenefits}
              onChange={() => handleToggle("showBenefits")}
            />
          </div>
        </div>

        <div>
          <div className="settings-profile-label-plus-switch">
            <Label style={{ marginRight: "0.5rem" }}>Show Birth Details:</Label>
            <Switch
              onLabel="Yes"
              checked={switches.showBirthDetails}
              onChange={() => handleToggle("showBirthDetails")}
            />
          </div>
          <div className="settings-profile-label-plus-switch">
            <Label style={{ marginRight: "0.5rem" }}>Show Patient Since:</Label>
            <Switch
              onLabel="Yes"
              checked={switches.showPatientSince}
              onChange={() => handleToggle("showPatientSince")}
            />
          </div>
          <div className="settings-profile-label-plus-switch">
            <Label style={{ marginRight: "0.5rem" }}>Show Last/Next Visit:</Label>
            <Switch
              onLabel="Yes"
              checked={switches.showLastNextVisit}
              onChange={() => handleToggle("showLastNextVisit")}
            />
          </div>
        </div>

        <div>
          <div className="settings-profile-label-plus-switch">
            <Label style={{ marginRight: "0.5rem" }}>Show Total Production:</Label>
            <Switch
              onLabel="Yes"
              checked={switches.showTotalProduction}
              onChange={() => handleToggle("showTotalProduction")}
            />
          </div>
          <div className="settings-profile-label-plus-switch">
            <Label style={{ marginRight: "0.5rem" }}>Show Insurance:</Label>
            <Switch
              onLabel="Yes"
              checked={switches.showInsurance}
              onChange={() => handleToggle("showInsurance")}
            />
          </div>
        </div>
      </div>
    </DatatableLayout>
  );
};

export default SettingsProfile;

// src/services/fastFillService.js

const fastFillData = [
  { id: 1, treatment: "Account", instruction: "Account" },
  { id: 2, treatment: "Allergies", instruction: "Allergies" },
  { id: 3, treatment: "Allergy", instruction: "Allergy" },
  { id: 4, treatment: "Bleeding", instruction: "Bleeding" },
  { id: 5, treatment: "Composite", instruction: "Composite" },
  { id: 6, treatment: "Denture", instruction: "Denture" },
  { id: 7, treatment: "Exam Default", instruction: "Exam Default" },
  { id: 8, treatment: "Filling Cause", instruction: "Filling Cause" },
  { id: 9, treatment: "Financial Summary", instruction: "Financial Summary" }
];

const fastFillService = {
  fetchFastFills: async () => {
    // Here you could replace this with a real API call like:
    // const response = await httpClient.get('/fast-fills');
    // return response.data;
    return Promise.resolve(fastFillData);
  },

  addFastFill: async (fastFill) => {
    // Mock adding to backend
    return Promise.resolve({ ...fastFill, id: Date.now() });
  },

  updateFastFill: async (fastFill) => {
    // Mock update
    return Promise.resolve(fastFill);
  },

  deleteFastFill: async (id) => {
    // Mock delete
    return Promise.resolve(id);
  }
};

export default fastFillService;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { TreatmentAndFeeService } from "../../../services/AdminService";

const AdminTreatmentAndFees = () => {
  const [treatmentAndFees, setTreatmentAndFees] = useState([]);

  useEffect(() => {
    const data = TreatmentAndFeeService.getTreatmentAndFees();
    setTreatmentAndFees(data);
  }, []);

  const columns = [
    { field: "group", title: "Group" },
    { field: "adacode", title: "ADA Code" },
    { field: "treatment", title: "Treatment" },
    { field: "officecode", title: "Office Code" },
    { field: "feeschedule", title: "Fee Schedule" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    }
  ];

  const statuses = ["Office Fee", "Fee"];

  const [status, setStatus] = useState("Office Fee");

  return (
    <DatatableLayout
      title="Treatment and Fees"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new Treatment")}
      addBtnLabel="New Treatment"
      extraBtnLabel={["Fee Schedules", "Export", "Mass Edit Fees"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={treatmentAndFees}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminTreatmentAndFees;

import React from "react";
import Label from "src/components/atoms/Label";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import "./SettingsInsuranceVerification.css";
import { NumericTextBox } from "@progress/kendo-react-inputs";

const SettingsInsuranceVerification = () => {
  return (
    <DatatableLayout title="Insurance Verification" saveButton="Save">
      <div className="insurance-verification-container">
        <h4>Criteria</h4>

        <div className="insurance-verification-sub-container">
          <Label style={{ width: "15%" }}>Visits Within The Next:</Label>
          <NumericTextBox defaultValue={0} width="60px" />
          <span style={{ margin: "0 10px" }}> - </span>
          <NumericTextBox defaultValue={0} width="60px" />
          <span style={{ marginLeft: "10px" }}>Days</span>
        </div>

        <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
          <Label style={{ width: "15%" }}>Eligibility Not Checked In:</Label>
          <NumericTextBox defaultValue={0} width="60px" />
          <span style={{ marginLeft: "10px" }}>Days</span>
        </div>

        <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
          <Label style={{ width: "15%" }}>Benefits Not Checked In:</Label>
          <NumericTextBox defaultValue={0} width="60px" />
          <span style={{ marginLeft: "10px" }}>Days</span>
        </div>
      </div>
    </DatatableLayout>
  );
};

export default SettingsInsuranceVerification;

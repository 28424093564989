// services/AlertService.js
import httpClient from "./http-client";

// Dummy data for fetching alerts
const dummyAlerts = [
  {
    id: "1",
    patient: "Heena Raval",
    alertText: "New alert added for testing",
    paused: false,
    createdDate: "9/29/2024 4:26 PM",
    createdUser: "132"
  },
  {
    id: "2",
    patient: "Heena Raval",
    alertText: "Test alert",
    paused: false,
    createdDate: "9/29/2024 4:26 PM",
    createdUser: "132"
  },
  {
    id: "3",
    patient: "John Doe",
    alertText: "Follow-up appointment needed",
    paused: true,
    createdDate: "9/30/2024 2:15 PM",
    createdUser: "456"
  }
];

const AlertService = {
  // Fetch all alerts (using dummy data)
  fetchAlerts: async () => {
    // Simulate async operation with Promise.resolve
    return Promise.resolve(dummyAlerts);
  },

  // Add a new alert
  addAlert: async (alertData) => {
    const response = await httpClient.post("/alerts", alertData);
    return response.data;
  },

  // Edit an existing alert by ID
  updateAlert: async (alertId, updatedData) => {
    const response = await httpClient.put(`/alerts/${alertId}`, updatedData);
    return response.data;
  },

  // Delete an alert by ID
  deleteAlert: async (alertId) => {
    const response = await httpClient.delete(`/alerts/${alertId}`);
    return response.data;
  }
};

export default AlertService;

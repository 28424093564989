import React, { useState } from "react";
import DynamicForm from "../../organisms/DynamicForm";
import DynamicGrid from "../../organisms/DynamicGrid";
import CommonPopup from "../../molecules/CommonPopup";
import { Button } from "@progress/kendo-react-buttons";
import { DateRangePicker } from "@progress/kendo-react-dateinputs"; // Import DateRangePicker

const InsuranceVerificationPopup = ({ show, onClose }) => {
  const [formData, setFormData] = useState({});

  // Sample data for the grid
  const gridData = [
    {
      id: 1,
      subscriber: "Jenny Joshi",
      visit: "10/18/2024 10:30 AM",
      insurance: "Healthnet",
      needToCheck: "Eligibility/Benefits",
      previousCheck: "02/23/2024",
      status: "-",
      date: "-"
    },
    {
      id: 2,
      subscriber: "Jenny Joshi",
      visit: "10/18/2024 10:30 AM",
      insurance: "Healthnet",
      needToCheck: "Eligibility/Benefits",
      previousCheck: "02/23/2024",
      status: "-",
      date: "-"
    }
    // Add more sample rows as needed
  ];

  const handleFormSubmit = (data) => {
    console.log("Form submitted with data:", data);
    setFormData(data);
  };

  const handleSearch = () => {
    console.log("Searching with criteria:", formData);
    // Implement search functionality here based on `formData`
  };

  // Define columns for DynamicGrid
  const columns = [
    { field: "subscriber", title: "Subscriber", width: "100px" },
    { field: "visit", title: "Visit", width: "150px" },
    { field: "insurance", title: "Insurance", width: "120px" },
    { field: "needToCheck", title: "Need to Check", width: "150px" },
    { field: "previousCheck", title: "Previous Check", width: "120px" },
    { field: "status", title: "Status", width: "100px" },
    { field: "date", title: "Date", width: "100px" }
  ];

  // Define grid action buttons to be passed as a prop
  const gridActions = [
    { icon: "edit", title: "Edit", onClick: (dataItem) => console.log("Edit clicked", dataItem) },
    {
      icon: "calendar",
      title: "Schedule",
      onClick: (dataItem) => console.log("Schedule clicked", dataItem)
    },
    {
      icon: "check",
      title: "Verify",
      onClick: (dataItem) => console.log("Verify clicked", dataItem)
    }
  ];

  // Define the form structure with Criteria section, action buttons, and grid
  const formStructure = {
    title: "Insurance Verification",
    tabs: [
      {
        name: "Insurance Verification",
        sections: [
          // Criteria section
          {
            sectionName: "Criteria",
            fields: [
              {
                label: "Visit Dates",
                name: "visitDates",
                type: "daterangepicker" // Use a custom type to identify the DateRangePicker
              },
              {
                label: "Eligibility Not Checked In",
                suffix: "Days",
                name: "eligibilityNotCheckedIn7",
                type: "numerictextbox",
                placeholder: "7"
              },
              {
                label: "Benifits Not Checked In",
                suffix: "Days",
                name: "benifitsNotCheckedIn30",
                type: "numerictextbox",
                placeholder: "30"
              },
              { name: "search", type: "searchbutton", icon: "search", onClick: handleSearch }
            ]
          },
          // Insurance Verification Grid Section
          {
            customSection: (
              <DynamicGrid
                columns={columns}
                rows={gridData}
                actions={gridActions}
                allowCheckable={true}
              />
            )
          },
          // Insurance Plan Section
          {
            sectionName: "Insurance Plan",
            fields: [
              { label: "Subscriber", name: "subscriber", type: "label", placeholder: "Healthnet" },
              { label: "Phone", name: "phone", type: "label", placeholder: "1(800)822-5353" },
              { label: "Group ID", name: "groupId", type: "label" },
              { label: "Group Name", name: "groupName", type: "label" , value:"grp"},
              { label: "Employer", name: "employer", type: "label", placeholder: "Healthnet" },
              {
                label: "Plan Note",
                name: "planNote",
                type: "textarea",
                placeholder: "Enter Plan Note"
              }
            ]
          },
          // Subscriber Section
          {
            sectionName: "Subscriber",
            fields: [
              { label: "Name", name: "name", type: "label", placeholder: "Healthnet" },
              { label: "Birthdate", name: "birthdate", type: "date", placeholder: "12/08/1999" },
              { label: "SSN", name: "ssn", type: "label" },
              { label: "Sub ID", name: "subId", type: "label" },
              {
                label: "State",
                name: "state",
                type: "select",
                options: [{ label: "None", value: "none" }]
              },
              { label: "Note", name: "note", type: "textarea", placeholder: "Enter Note" }
            ]
          }
        ]
      }
    ]
  };



  return (
    <CommonPopup show={show} onClose={onClose} title="Insurance Verification">
      {/* Render the DynamicForm inside CommonPopup */}
      <DynamicForm
        tabs={formStructure.tabs}
        handleFormSubmit={handleFormSubmit}
        formData={formData}
        handleInputChange={(value, fieldName) =>
          setFormData((prev) => ({ ...prev, [fieldName]: value }))
        }
      />
    </CommonPopup>
  );
};

export default InsuranceVerificationPopup;

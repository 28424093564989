import { Children } from "react";

const FormActionsBar = ({ children, style }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: Children.count(children) == 1 ? "flex-end" : "space-between",
        marginBottom: "10px",
        height: "50px",
        alignItems: "center",
        ...style
      }}
    >
      {children}
    </div>
  );
};

export default FormActionsBar;

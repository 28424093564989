import React from "react";
import { Upload } from "@progress/kendo-react-upload";
import IconButton from "src/components/atoms/IconButton";
import "./CardUploadSection.css";
const CardUploadSection = ({ title, placeholderText, icon }) => {
  return (
    <div className="upload-section">
      <h3>{title}</h3>
      <div className="custom-upload-overlay">
        <Upload
          batch={false}
          multiple={false}
          withCredentials={false}
          saveUrl={"https://your-save-url.com"}
          removeUrl={"https://your-remove-url.com"}
          className="custom-upload"
        />
        <div className="custom-placeholder">
          <IconButton icon={icon || "file"} className="add-button" />
          <div>{placeholderText}</div>
        </div>
      </div>
    </div>
  );
};

export default CardUploadSection;

import React from "react";
import "./ColorCell.css";
// Custom cell component for displaying color with background
const ColorCell = (props) => {
  const { color } = props.dataItem;
  return (
    <td>
      <div className="color-cell" style={{ backgroundColor: color }} />
    </td>
  );
};

export default ColorCell;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { MedicationService } from "../../../services/AdminService";

const AdminMedications = () => {
  const [medications, setMedications] = useState([]);

  useEffect(() => {
    const data = MedicationService.getMedications();
    setMedications(data);
  }, []);

  const columns = [
    { field: "bDrug", title: "Branded Drug" },
    { field: "gDrug", title: "Generic Drug" },
    { field: "note", title: "Generic Note" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  const statuses = ["Active", "Inactive"];

  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Medication"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new Medication")}
      addBtnLabel="Brand"
      extraBtnLabel={["Generic Drug"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={medications}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminMedications;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { AllergyService } from "../../../services/AdminService";

const AdminAllergies = () => {
  const [allergies, setAllergies] = useState([]);

  useEffect(() => {
    const data = AllergyService.getAllergies();
    setAllergies(data);
  }, []);

  const columns = [
    { field: "allergy", title: "Allergy" },
    { field: "show", title: "Show on Form" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  return (
    <DatatableLayout
      title="Allergy"
      onAdd={() => console.log("Add new Allergy")}
      addBtnLabel="Allergy"
      extraBtnLabel={["Show on Form", "Hide on Form"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={allergies}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminAllergies;

import React, { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import SchedulerDrawer from "../../../organisms/Drawer/HomePageDrawer";
import OnDemand from "./SubPages/OnDemand";
import AutomatedText from "./SubPages/AutomatedText";

const SettingsTextTemplate = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  return (
    <div className="content-wrapper">
      <div className="sidebar">
        <SchedulerDrawer />
      </div>

      {/* Main content */}
      <div className="settings-goal-main-content">
        <div className="tab-buttons-container">
          <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
            <TabStripTab title="On Demand" />
            <TabStripTab title="Automated Text" />
          </TabStrip>
        </div>

        {/* Tab Content */}
        <div className="settings-goal-tab-content">
          {selectedTab === 0 && <OnDemand />}
          {selectedTab === 1 && <AutomatedText />}
        </div>
      </div>
    </div>
  );
};

export default SettingsTextTemplate;

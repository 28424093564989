import React, { useEffect, useState } from "react";
import SchedulerDrawer from "src/components/organisms/Drawer/HomePageDrawer";
import { Icon, SvgIcon } from "@progress/kendo-react-common";
import docFolderIcon from "src/assets/images/docFolderIcon.png";
import { InputClearValue, InputSeparator, TextBox } from "@progress/kendo-react-inputs";
import { searchIcon } from "@progress/kendo-svg-icons";
import CommonBtn from "src/components/atoms/Button";
import DocumentsService from "src/services/doucments";
import { ReactComponent as DentalIcon } from "src/assets/icons/dental.svg";
import "./PatientDocuments.css";

const Button = ({ children, ...props }) => (
  <CommonBtn
    {...props}
    style={{
      backgroundColor: "rgba(167, 176, 69, 0.9)",
      marginRight: "4px"
    }}
  >
    {children}
  </CommonBtn>
);

const DocumentHeader = ({ onSearch, value, onChange }) => (
  <header className="document-header">
    <h1 className="header-title">Patient Documents</h1>
    <TextBox
      value={value}
      onChange={onChange}
      placeholder="Search Documents"
      style={{ width: "30%" }}
      suffix={() => (
        <>
          <InputClearValue onClick={onSearch}>
            <SvgIcon icon={searchIcon} />
          </InputClearValue>
          <InputSeparator />
        </>
      )}
    />
    <div className="header-buttons">
      <Button icon="download">Download</Button>
      <Button icon="upload">Upload</Button>
      <Button icon="barcode-scanner">Scan</Button>
      <Button startIcon={<DentalIcon />}>Imaging</Button>
    </div>
  </header>
);

const DirectoryList = ({ directories, onSelect }) => (
  <aside className="document-sidebar">
    <ul>
      {directories.map(({ id, name }) => (
        <li key={id} onClick={() => onSelect(id)}>
          <Icon size="large" name="folder" style={{ color: "rgba(167, 176, 69, 1)" }} />
          {name}
        </li>
      ))}
    </ul>
  </aside>
);

const FileList = ({ files }) => (
  <div className="main-documents">
    {files.map(({ id, name, date }) => (
      <div className="document-item" key={id}>
        <img src={docFolderIcon} alt="Document" className="document-image" />
        <div className="document-details">
          <span className="document-name">{name}</span>
          <span className="menu-icon">
            <Icon name="more-vertical" />
          </span>
        </div>
        <div className="document-date">
          <span>{date}</span>
        </div>
      </div>
    ))}
  </div>
);

const PatientDocuments = () => {
  const [searchValue, setSearchValue] = useState("");
  const [directories, setDirectories] = useState([]);
  const [directoryFiles, setDirectoryFiles] = useState([]);
  const [selectedDir, setSelectedDir] = useState(null);

  useEffect(() => {
    DocumentsService.getDirectories().then(setDirectories);
  }, []);

  useEffect(() => {
    if (selectedDir) {
      DocumentsService.getDirectoryFiles(selectedDir).then(setDirectoryFiles);
    }
  }, [selectedDir]);

  return (
    <div className="content-wrapper">
      <div className="sidebar">
        <SchedulerDrawer />
      </div>
      <div className="main-content">
        <DocumentHeader
          onSearch={() => console.log("searchValue", searchValue)}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div className="body-section">
          <DirectoryList directories={directories} onSelect={setSelectedDir} />
          <FileList files={directoryFiles} />
        </div>
      </div>
    </div>
  );
};

export default PatientDocuments;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { InsurancePlanService } from "../../../services/AdminService";

const AdminInsurancePlans = () => {
  const [insurancePlans, setInsurancePlans] = useState([]);

  useEffect(() => {
    const data = InsurancePlanService.getInsurancePlans();
    setInsurancePlans(data);
  }, []);

  const columns = [
    { field: "employer", title: "Employer" },
    { field: "insurance", title: "Insurance" },
    { field: "phone", title: "Phone" },
    { field: "address", title: "Address" },
    { field: "city", title: "City" },
    { field: "state", title: "State" },
    { field: "zip", title: "Zip" },
    { field: "groupname", title: "Group Name" },
    { field: "groupid", title: "Group ID" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    }
  ];

  const statuses = ["Active", "Inactive"];

  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Insurance Plans"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      extraBtnLabel={["Merge"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={insurancePlans}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminInsurancePlans;

import React, { useEffect, useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { CaseRankingService } from "src/services/SettingsService";

const SettingsCaseRanking = () => {
  const [caseRankings, setCaseRankings] = useState([]);
  useEffect(() => {
    const data = CaseRankingService.getCaseRanking();
    setCaseRankings(data);
  }, []);

  const columns = [
    {
      field: "caseRanking",
      title: "Case Ranking",
      cell: (props) => (
        <td>
          <span style={{ marginLeft: "10px" }}>{props.dataItem.caseRanking}</span>
        </td>
      )
    },
    {
      field: "color",
      title: "Color",
      cell: (props) => (
        <td>
          <span
            style={{
              backgroundColor: props.dataItem.color,
              width: "70px",
              height: "25px",
              display: "flex"
            }}
          ></span>
        </td>
      )
    },
    {
      field: "inactive",
      title: "Inactive",
      cell: (props) => (
        <td>
          <span>{props.dataItem.inactive ? "Yes" : "-"}</span>
        </td>
      )
    }
  ];

  const actions = [
    {
      icon: "edit",
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.caseRanking}`)
    },
    {
      icon: "delete",
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.caseRanking}`)
    }
  ];

  return (
    <DatatableLayout
      title="Case Rankings"
      onAdd={() => console.log("Add new case ranking")}
      addBtnLabel="Case Ranking"
    >
      <DynamicGrid
        columns={columns}
        rows={caseRankings}
        allowCheckable={true}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default SettingsCaseRanking;

import React, { useEffect, useState } from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartLegend,
  ChartTooltip
} from "@progress/kendo-react-charts";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import NavbarTemplate from "../templates/NavbarTemplate";
import "./Accounts.css";
import { Checkbox } from "@progress/kendo-react-inputs";
import tooth from "../../assets/images/tooth.png";
import users from "../../assets/images/users.png";
import building from "../../assets/images/building.png";
import burger from "../../assets/images/burger.png";
import bookmark from "../../assets/images/bookmark.png";
import filePlusShield from "../../assets/images/filePlusShield.png";
import dollar from "../../assets/images/dollar.png";
import radio from "../../assets/images/radio.png";
import plusMinus from "../../assets/images/plusMinus.png";

import InputField from "../atoms/InputField";
import Dropdown from "../atoms/Dropdown";
import { AccountLedgerService, FinancingService } from "src/services/SettingsService";
import CustomPanel from "../molecules/CustomPanel";
import DynamicGrid from "../organisms/DynamicGrid";
import Label from "../atoms/Label";

const Accounts = () => {
  const [view, setView] = useState("All");
  const [accountLedger, setAccountLedger] = useState([]);
  const [financingData, setFinancingData] = useState([]);

  const accountLedgerColumns = [
    { field: "date", title: "Date" },
    { field: "patient", title: "Patient" },
    { field: "type", title: "Type" },
    { field: "activity", title: "Activity" },
    { field: "tooth", title: "Tooth" },
    { field: "amount", title: "Amount" },
    { field: "payment", title: "Payment" },
    { field: "balance", title: "Balance" }
  ];

  const financialDataColumns = [
    { field: "date", title: "Date" },
    { field: "guarantor", title: "Guarantor" },
    { field: "patient", title: "Patient" },
    { field: "title", title: "Title" },
    { field: "planType", title: "Plan Type" },
    { field: "loan", title: "Loan" },
    { field: "total", title: "Total" },
    { field: "paid", title: "Paid" },
    { field: "balance", title: "Balance" },
    { field: "due", title: "Due" }
  ];

  const actions = [
    {
      icon: "edit",
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.orthoElastic || dataItem.abbreviation}`)
    }
  ];

  useEffect(() => {
    const accountdata = AccountLedgerService.getAccountLedgerData();
    const financing = FinancingService.getFinancingData();
    setFinancingData(financing);
    setAccountLedger(accountdata);
  }, []);
  // Sample data
  const balancesData = [
    { category: "0-30", value: 30 },
    { category: "31-40", value: 25 },
    { category: "41-60", value: 20 },
    { category: "61-90", value: 15 },
    { category: "90+", value: 10 }
  ];

  return (
    <NavbarTemplate>
      <div className="accounts-container">
        <div className="accounts-left-column">
          {/* Balances Section */}
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
            <h3>Balances</h3>
            <section style={{ display: "flex", gap: "10px" }}>
              <img src={tooth} alt="Tooth Icon" className="accounts-images" />
              <img src={building} alt="Building Icon" className="accounts-images" />
              <img src={users} alt="Users Icon" className="accounts-images" />
            </section>
          </div>
          <div>
            <Chart chartArea={{ fill: "none !important" }}>
              <ChartSeries>
                <ChartSeriesItem
                  type="pie"
                  data={balancesData}
                  field="value"
                  categoryField="category"
                />
              </ChartSeries>
              <ChartLegend visible={true} />
              <ChartTooltip visible={true} />
            </Chart>
          </div>
          <div style={{ marginTop: 20 }}>
            <section className="accounts-tottal-calculation">
              <p>Insurance</p>
              <p>$7,413.90</p>
            </section>
            <section className="accounts-tottal-calculation">
              <p>Family</p>
              <p>$13,366.84</p>
            </section>
            <section className="accounts-tottal-calculation">
              <p>Patient</p>
              <p>$12,187.09</p>
            </section>
            <hr />
            <section className="accounts-tottal-calculation">
              <p>Total</p>
              <p>$20,780.74</p>
            </section>
          </div>
          {/* Account Note */}
          <div style={{ marginTop: "20px" }}>
            <p>Account Note:</p>
            <InputField />
          </div>
        </div>

        {/* Account Notes and Treatment Financing */}
        <div className="accounts-right-column">
          {/* Account Ledger Section */}
          <div className="account-ledger-header-container">
            <h3>Account Ledger</h3>
            <section style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Label>View:</Label>
              <Dropdown
                options={["All", "Treat Details", "Statements", "Family"]}
                value={view}
                onChange={setView}
                style={{ width: "30%" }}
              />
              <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                <Checkbox />
                <Label htmlFor="treatmentDetails" style={{ marginLeft: "5px", width: "120px" }}>
                  Treatment Details
                </Label>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                <Checkbox />
                <Label htmlFor="statements" style={{ marginLeft: "5px" }}>
                  Statements
                </Label>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                <Checkbox />
                <Label htmlFor="family" style={{ marginLeft: "5px" }}>
                  Family
                </Label>
              </div>

              {/* PNG Images */}
              <img src={filePlusShield} alt="filePlusShield" className="accounts-images" />
              <img src={burger} alt="burger" className="accounts-images" />
              <img src={bookmark} alt="bookmark" className="accounts-images" />
            </section>
          </div>
          <CustomPanel key="All" title="All">
            <DynamicGrid
              columns={accountLedgerColumns}
              rows={accountLedger}
              actions={actions}
              allowCheckable={true}
              allowExpand={false}
            />
          </CustomPanel>
          {/* Treatment Financing */}
          <div className="account-ledger-header-container">
            <h3>Treatment Financing</h3>
            <section style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Label style={{ width: "100px" }}>Order By:</Label>
              <Dropdown
                options={["All", "Treat Details", "Statements", "Family"]}
                value={view}
                onChange={setView}
                style={{ width: "30%" }}
              />
              <Label style={{ width: "150px" }}>Send Bills By:</Label>
              <Dropdown
                options={["All", "Treat Details", "Statements", "Family"]}
                value={view}
                onChange={setView}
                style={{ width: "30%" }}
              />
              {/* PNG Images */}
              <img src={radio} alt="radio" className="accounts-images" />
              <img src={plusMinus} alt="plusMinus" className="accounts-images" />
              <img src={dollar} alt="dollar" className="accounts-images" />
            </section>
          </div>
          <DynamicGrid
            columns={financialDataColumns}
            rows={financingData}
            actions={actions}
            allowCheckable={false}
            allowExpand={false}
          />
        </div>
      </div>
    </NavbarTemplate>
  );
};

export default Accounts;

import React, { useState } from "react";
import CommonPopup from "src/components/molecules/CommonPopup";
import DynamicForm from "src/components/organisms/DynamicForm";
import PreventiveAndDiagnosticTab from "./tabs/PreventiveAndDiagnosticTab";
import CoverageTab from "./tabs/CoverageTab";
import ImportedSpecialBenefitsTab from "./tabs/ImportedSpecialBenefitsTab";
import "./Insurance.css";

const EditInsuranceBenefits = ({ show, onClose }) => {
  const [formData, setFormData] = useState({});

  const handleFormSubmit = (data) => {
    console.log("Form submitted with data:", data);
    setFormData(data);
  };

  const formStructure = {
    title: "Edit Insurance Benefits",
    tabs: [PreventiveAndDiagnosticTab, CoverageTab, ImportedSpecialBenefitsTab]
  };

  return (
    <CommonPopup
      show={show}
      onClose={onClose}
      title="Edit Insurance Benefits"
    >
      <DynamicForm
        tabs={formStructure.tabs}
        handleFormSubmit={handleFormSubmit}
        formData={formData}
        handleInputChange={(value, fieldName) =>
          setFormData((prev) => ({ ...prev, [fieldName]: value }))
        }
      />
    </CommonPopup>
  );
};

export default EditInsuranceBenefits;

import React from "react";
import PatientCarousel from "./PatientCarousel";
import IconButton from "src/components/atoms/IconButton";
import "./PatientCard.css";

const PatientCard = ({ patient }) => {
  return (
    <div className="patient-card">
      {/* Patient Info Section */}
      <div className="patient-info-section">
        <div className="patient-info">
          <div className="patient-image-wrapper">
            <img src={patient.image} alt="Patient" className="patient-image" />
            {patient.isGuarantor && <div className="g-indicator">G</div>}
          </div>
          <div className="patient-name-id">
            <h3>
              {patient.name} ({patient.id})
            </h3>
            <p>
              {patient.dob} ({patient.age}), {patient.gender}
            </p>
            <div className="recare-visit">
              Recare Visit: {patient.recareVisit}
              <IconButton icon="calendar" className="recare-visit-icon" />
            </div>
          </div>
        </div>
      </div>

      {/* Patient Details Section */}
      <div className="patient-details-section">
        <hr className="section-divider" />
        {/* Section 1: Patient Since and Next Visit */}
        <div className="detail-section">
          <div className="detail-item">
            <p className="label">Patient Since</p>
            <p>{patient.patientSince}</p>
          </div>
          <div className="detail-item">
            <p className="label">Next Visit</p>
            <p>
              {patient.nextVisit} <IconButton icon="calendar" className="icon" />
            </p>
          </div>
          <div className="detail-item">
            <p className="label">Source</p>
            <p>{patient.source}</p>
          </div>
          <div className="detail-item">
            <p className="label">Mobile</p>
            <p>
              {patient.mobile} <IconButton icon="email" className="icon" />
              <IconButton icon="email" className="icon" />
            </p>
          </div>
        </div>
        <hr className="section-divider" />

        {/* Section 2: Total Production and Family Balance */}
        <div className="detail-section">
          <div className="detail-item">
            <p className="label">Total Production</p>
            <p>{patient.totalProduction}</p>
          </div>
          <div className="detail-item">
            <p className="label">Insurance</p>
            <p>{patient.insurance}</p>
          </div>
          <div className="detail-item">
            <p className="label">Family Balance</p>
            <p>{patient.familyBalance}</p>
          </div>

          <div className="detail-item">
            <p className="label">Benefits</p>
            <p>{patient.benefits}</p>
          </div>
        </div>
        <hr className="section-divider" />
      </div>

      {/* Family Members Carousel */}
      <div className="family-carousel-section">
        <PatientCarousel familyMembers={patient.familyMembers} />
      </div>
    </div>
  );
};

export default PatientCard;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { InternalPatientReviewService } from "../../../services/AdminService";

const AdminInternalPatientReview = () => {
  const [internalPatientReviews, setInternalPatientReviews] = useState([]);

  useEffect(() => {
    const data = InternalPatientReviewService.getInternalPatientReviews();
    setInternalPatientReviews(data);
  }, []);

  const columns = [
    { field: "date", title: "Date" },
    { field: "time", title: "Time" },
    { field: "clinic", title: "Clinic" },
    { field: "clinician", title: "Clinician" },
    { field: "visitdate", title: "Visit Date" },
    { field: "visittime", title: "Visit Time" },
    { field: "treatment", title: "Treatment" },
    { field: "patient", title: "Patient" },
    { field: "rating", title: "Rating" },
    { field: "comment", title: "Comment" }
  ];

  return (
    <DatatableLayout
      title="Internal Patient Review"
      extraBtnLabel={["Refresh"]} // New button label
      onExtraAction={() => console.log("Refresh")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={internalPatientReviews}
        allowSort={false}
        allowFilter={false}
      />
    </DatatableLayout>
  );
};

export default AdminInternalPatientReview;

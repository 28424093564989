import React, { useState } from "react";
import ActionButton from "src/components/atoms/ActionButton";
import DynamicGrid from "src/components/organisms/DynamicGrid";

const Visits = () => {
  const initialData = [
    { fieldName: "Age", visible: false },
    { fieldName: "Chair", visible: true },
    { fieldName: "Clinicians", visible: false },
    { fieldName: "Treatment", visible: true },
    { fieldName: "Fee", visible: false },
    { fieldName: "Note", visible: true },
    { fieldName: "Status", visible: false },
    { fieldName: "Plan", visible: true },
    { fieldName: "Pending Forms", visible: false }
  ];

  const [data, setData] = useState(initialData);

  const toggleVisibility = (fieldName) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.fieldName === fieldName ? { ...item, visible: !item.visible } : item
      )
    );
  };

  const visitsColumns = [{ field: "fieldName", title: "Field Name" }];

  const actions = [
    {
      title: "Toggle Visibility",
      render: (dataItem) => (
        <ActionButton
          onClick={() => toggleVisibility(dataItem.fieldName)}
          style={{
            backgroundColor: dataItem.visible ? "#F44336" : "#4CAF50",
            color: "#fff",
            borderRadius: "20px",
            minWidth: "60px"
          }}
        >
          {dataItem.visible ? "Hide" : "Show"}
        </ActionButton>
      )
    }
  ];

  return (
    <div className="visits-container">
      <DynamicGrid
        columns={visitsColumns}
        rows={data}
        actions={actions}
        allowSort={false}
        allowFilter={false}
      />
    </div>
  );
};

export default Visits;

import React, { useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "../atoms/Dropdown";
import ActionButton from "../atoms/ActionButton";
import { ReactComponent as ArrowUp } from "src/assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "src/assets/icons/arrow-down.svg";

import "./CustomPanel.css";

const CustomPanel = ({
  title,
  dropdownOptions,
  dropdownValue,
  onDropdownChange,
  buttonLabel,
  onButtonClick,
  children
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`custom-panel ${expanded ? "expanded" : ""}`}>
      <div className="custom-panel-header">
        {/* Title Section */}
        <h3 className="custom-panel-title" onClick={handleToggleExpand}>
          {title}
        </h3>

        {/* Actions Section */}
        <div className="header-actions">
          {dropdownOptions && (
            <Dropdown
              options={dropdownOptions}
              value={dropdownValue}
              onChange={(e) => onDropdownChange(e.target.value)}
              className="status-dropdown"
            />
          )}
          {buttonLabel && (
            <ActionButton icon="plus" className="add-button" onClick={onButtonClick}>
              {buttonLabel}
            </ActionButton>
          )}

          {expanded ? (
            <ArrowUp className="arrow-icon" onClick={handleToggleExpand} />
          ) : (
            <ArrowDown className="arrow-icon" onClick={handleToggleExpand} />
          )}
        </div>
      </div>

      {/* Expandable Content */}
      {expanded && <div className="custom-panel-content">{children}</div>}
    </div>
  );
};

CustomPanel.propTypes = {
  title: PropTypes.string.isRequired,
  dropdownOptions: PropTypes.array.isRequired,
  dropdownValue: PropTypes.string.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default CustomPanel;

import React, { useState } from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Switch } from "@progress/kendo-react-inputs";
import "@progress/kendo-theme-default/dist/all.css";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import Label from "src/components/atoms/Label";
import Dropdown from "src/components/atoms/Dropdown";
import InputField from "src/components/atoms/InputField";
import "./SettingsCasePresenter.css";

const SettingsCasePresenter = () => {
  const [status, setStatus] = useState("Small");
  const [adjustments, setAdjustments] = useState("New Pt Discount");
  const savingsAdjustments = ["New Pt Discount", "Insurance Adjustment", "Other"];
  return (
    <DatatableLayout
      title="Case Presenter"
      saveButton="Save"
      statusOptions={["Small", "Medium", "Large"]}
      status={status}
      onStatusChange={setStatus}
    >
      <div className="case-presenter-container">
        <div>
          <Checkbox label="Tooth Chart on Case Plan" />
          <br />
          <Checkbox label="Completed Tx on Case Plan" />
          <br />
          <Checkbox label="Signed Cases to Documents" />
        </div>

        <div className="case-presenter-sub-container">
          <Label style={{ width: "50%" }}>Savings Adjustment:</Label>
          <Dropdown
            style={{ width: "50%" }}
            options={savingsAdjustments}
            value={adjustments}
            onChange={setAdjustments}
          />
        </div>

        <div className="case-presenter-sub-container">
          <Label style={{ width: "50%" }}>Case Comment:</Label>
          <InputField />
        </div>

        <div className="case-presenter-sub-container">
          <Label>Show Watch phase treatment in Case Presenter:</Label>
          <Switch id="watchPhaseSwitch" onLabel="Yes" offLabel="No" checked={true} />
        </div>
      </div>
    </DatatableLayout>
  );
};

export default SettingsCasePresenter;

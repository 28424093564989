import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { AutomatedChargeService } from "../../../services/AdminService";

const AdminAutomatedCharges = () => {
  const [automatedCharges, setAutomatedCharges] = useState([]);

  useEffect(() => {
    const data = AutomatedChargeService.getAutomatedCharges();
    setAutomatedCharges(data);
  }, []);

  const columns = [
    { field: "name", title: "Name" },
    { field: "patientid", title: "Patient ID" },
    { field: "type", title: "Type" },
    { field: "clinic", title: "Clinic" },
    { field: "financing", title: "Financing" },
    { field: "datecharge", title: "Date Charge" },
    { field: "timecharge", title: "Time Charge" },
    { field: "chargeamt", title: "Charge Amt" },
    { field: "status", title: "Status" },
    { field: "error", title: "Error" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "view", // Delete Icon
      title: "View",
      onClick: (dataItem) => alert(`View ${dataItem.template || dataItem.treatment}`)
    }
  ];

  return (
    <DatatableLayout
      title="Automated Charges"
      extraBtnLabel={["Refresh"]} // New button label
      onExtraAction={() => console.log("Refresh")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={automatedCharges}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminAutomatedCharges;

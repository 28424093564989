import React, { useEffect, useState } from "react"
import DynamicGrid from '../../../../organisms/DynamicGrid';
import FormActionsBar from '../../../../organisms/FormActionBar';
import Button from '../../../../atoms/Button';
import { getVitals } from "../../../../../services/health-info";

const VitalSigns = () => {
  const [vitals, setVitals] = useState([]);

  useEffect(() => {
    getVitals().then(setVitals);
  }, []);

  const columns = [
    {
        field:"date",
        title:"Date",
        width:"250px"
      },
      {
        field:"height",
        title:"Height",
        width:"250px"
      },
      {
        field:"weight",
        title:"Weight",
        width:"250px"
      },
      {
        field:"bmi",
        title:"BMI",
        width:"250px"
      },
      {
        field:"bpm",
        title:"BPM",
        width:"250px"
      },
      {
        field:"bloodpressur",
        title:"Blood Pressur",
        width:"250px"
      },
  ]
  return <div style={{height:'500px'}}>
    <FormActionsBar>
      <Button icon="plus" themeColor="primary"> Vital Signs </Button>
    </FormActionsBar>
    <DynamicGrid
      columns={columns}
      rows={vitals}
      key={vitals.length || 0}
      >
    </DynamicGrid>
    <div className="k-form-buttons" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginBottom: '10px' }}>
      <Button themeColor="primary">Save</Button>
    </div>
  </div>
}

export default VitalSigns

// redux/alertSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchAlerts, addAlert, editAlert, deleteAlert } from "../actions/alertAction";

const alertSlice = createSlice({
  name: "alerts",
  initialState: {
    alerts: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlerts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAlerts.fulfilled, (state, action) => {
        state.loading = false;
        state.alerts = action.payload;
      })
      .addCase(fetchAlerts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(addAlert.fulfilled, (state, action) => {
        state.alerts.push(action.payload);
      })
      .addCase(editAlert.fulfilled, (state, action) => {
        const updatedAlert = action.payload;
        const index = state.alerts.findIndex((alert) => alert.id === updatedAlert.id);
        if (index !== -1) state.alerts[index] = updatedAlert;
      })
      .addCase(deleteAlert.fulfilled, (state, action) => {
        state.alerts = state.alerts.filter((alert) => alert.id !== action.payload);
      });
  }
});

export default alertSlice.reducer;

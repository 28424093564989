import React from "react";
import DynamicGrid from "../../../organisms/DynamicGrid";
import { Button } from "@progress/kendo-react-buttons";

const PatientVisitsGrid = ({ gridRef, data = [] }) => {
  console.log("data", data, gridRef);
  // Dynamic columns configuration for the parent grid
  const parentColumns = [
    { field: "name", title: "Family Member", width: "200px" },
    { field: "age", title: "Age", width: "50px", sortable: true, filterable: true },
    { field: "gender", title: "Gender", width: "100px", sortable: true, filterable: true },
    { field: "recareDue", title: "Recare Due", width: "100px", sortable: true, filterable: false },
    { field: "schedule", title: "Schedule", width: "150px", sortable: true, filterable: true }
  ];

  // Dynamic columns configuration for the nested grid (Detail Grid)
  const detailColumns = [
    { field: "date", title: "Date", width: "120px" },
    { field: "time", title: "Time", width: "100px" },
    { field: "chair", title: "Chair", width: "100px" },
    { field: "duration", title: "Duration", width: "120px" },
    { field: "status", title: "Status", width: "120px" },
    { field: "clinician", title: "Clinician", width: "100px" },
    { field: "treatment", title: "Treatment", width: "200px" },
    { field: "note", title: "Note", width: "200px" }
  ];

  // Dynamic rows data including visit data for each patient
  const rows = [
    {
      id: 1,
      name: "Jenny Joshi",
      age: 26,
      gender: "Female",
      recareDue: "-",
      schedule: "6/15/2024",
      visits: [
        {
          date: "11/28/2022",
          time: "10:00 AM",
          chair: "Hyg1",
          duration: "60 min",
          status: "Complete",
          clinician: "NS",
          treatment: "CmpEx, 4BW, Pro",
          note: "Note about appointment"
        },
        {
          date: "11/28/2022",
          time: "03:20 PM",
          chair: "Hyg1",
          duration: "60 min",
          status: "Complete",
          clinician: "NS",
          treatment: "CmpEx, 4BW, Pro",
          note: "Note about appointment"
        }
      ]
    },
    {
      id: 2,
      name: "Nini Joshi",
      age: 26,
      gender: "Female",
      recareDue: "-",
      schedule: "6/15/2024",
      visits: []
    },
    {
      id: 3,
      name: "Rani Joshi",
      age: 26,
      gender: "Female",
      recareDue: "-",
      schedule: "6/15/2024",
      visits: []
    }
  ];

  // Custom action buttons for the action column
  const actions = [
    {
      title: "Edit",
      icon: "edit",
      onClick: (dataItem) => alert(`Editing ${dataItem.name}`)
    },
    {
      title: "Schedule",
      icon: "calendar",
      onClick: (dataItem) => alert(`Scheduling for ${dataItem.name}`)
    }
  ];

  // Use DynamicGrid as both parent and detail grid
  return (
    <DynamicGrid
      ref={gridRef}
      columns={parentColumns}
      rows={data}
      actions={actions}
      allowExpand={true} // Enable row expand/collapse
      allowSort={true} // Enable sorting
      DetailComponent={(props) => (
        <DynamicGrid
          columns={detailColumns} // Dynamic columns for detail view
          rows={props.dataItem.visits} // Pass nested visit data
          allowSort={true}
          actions={actions} // Optionally, you could pass separate actions for nested grid
        />
      )}
    />
  );
};

export default PatientVisitsGrid;

import React, { useState } from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import "./HygieneRecare.css";
import ActionButton from "src/components/atoms/ActionButton";

const HygieneRecare = () => {
  const [scheduledHygiene, setScheduledHygiene] = useState(100);
  const [averageHygieneProduction, setAverageHygieneProduction] = useState(150);
  const [perioVisits, setPerioVisits] = useState(30);
  const [hygieneReAppointment, setHygieneReAppointment] = useState(40);
  const [newPatientHygieneReAppointment, setNewPatientHygieneReAppointment] =
    useState(50);
  const [fluoridesAndSealants, setFluoridesAndSealants] = useState(60);

  const handleSave = () => {
    alert("Settings saved");
  };

  return (
    <div className="hygiene-recare-container">
      <div className="hygiene-recare-form">
        <p>Hygiene</p>
        <div className="hygiene-recare-form-input-group">
          <label>Scheduled Hygiene</label>

          <NumericTextBox
            value={scheduledHygiene}
            onChange={(e) => setScheduledHygiene(e.value)}
            format="p0"
            width="30%"
          />
        </div>

        <div className="hygiene-recare-form-input-group">
          <label>Average Hygiene Production</label>

          <NumericTextBox
            value={averageHygieneProduction}
            onChange={(e) => setAverageHygieneProduction(e.value)}
            format="n0"
            width="30%"
          />
        </div>

        <div className="hygiene-recare-form-input-group">
          <label>Perio Visits</label>

          <NumericTextBox
            value={perioVisits}
            onChange={(e) => setPerioVisits(e.value)}
            format="p0"
            width="30%"
          />
        </div>

        <div className="hygiene-recare-form-input-group">
          <label>Hygiene Re-Appointment</label>

          <NumericTextBox
            value={hygieneReAppointment}
            onChange={(e) => setHygieneReAppointment(e.value)}
            format="p0"
            width="30%"
          />
        </div>

        <div className="hygiene-recare-form-input-group">
          <label>New Patient Hygiene Re-Appointment</label>

          <NumericTextBox
            value={newPatientHygieneReAppointment}
            onChange={(e) => setNewPatientHygieneReAppointment(e.value)}
            format="p0"
            width="30%"
          />
        </div>

        <div className="hygiene-recare-form-input-group">
          <label>Fluorides and Sealants</label>

          <NumericTextBox
            value={fluoridesAndSealants}
            onChange={(e) => setFluoridesAndSealants(e.value)}
            format="p0"
            width="30%"
          />
        </div>
      </div>
      <ActionButton
        onClick={handleSave}
        primary={true}
        className="hygiene-recare-save-button"
      >
        Save
      </ActionButton>
    </div>
  );
};

export default HygieneRecare;

import React, { useState } from "react";
import CommonPopup from "../../../molecules/CommonPopup";
import DynamicForm from "../../../organisms/DynamicForm";
import FamilyMemberFields from "../../../molecules/FamilyMemberFields";
import InsurancePlanFields from "../../../molecules/InsurancePlanFields";

const NewFamilyPopup = ({ show, onClose }) => {
  const [formData, setFormData] = useState({});

  const handleFormSubmit = (data) => {
    console.log("Family data submitted:", data);
    setFormData(data);
  };

  const formStructure = {
    title: "New Family",
    tabs: [
      {
        name: "Family Members",
        sections: [
          {
            sectionName: "Guarantor",
            fields: FamilyMemberFields("Guarantor_")
          },
          {
            sectionName: "Family Member",
            allowDuplicate: true,
            fields: FamilyMemberFields("FamilyMember_")
          }
        ]
      },
      {
        name: "Address",
        sections: [
          {
            sectionName: "Address",
            fields: [
              { label: "Address", name: "address", type: "input", placeholder: "Enter Location" },
              { label: "City", name: "city", type: "input" },
              {
                label: "State",
                name: "state",
                type: "select",
                options: [{ label: "None", value: "None" }]
              },
              { label: "Zip", name: "zip", type: "input" }
            ]
          }
        ]
      },
      {
        name: "Contact Details",
        sections: [
          {
            sectionName: "Contact Details",
            fields: [
              { label: "Mobile Phone", name: "mobilePhone", type: "input" },
              {
                label: "Send Texts",
                name: "sendTexts",
                type: "radio",
                options: [
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
                  { label: "Ask", value: "ask" }
                ]
              },
              { label: "Home Phone", name: "homePhone", type: "input" },
              { label: "Work Phone", name: "workPhone", type: "input" },
              {
                label: "Referred By",
                name: "referredBy",
                type: "composite",
                subFields: [
                  { label: "", name: "referredByText", type: "input", placeholder: "Referred By" },
                  {
                    label: "",
                    name: "addIcon",
                    type: "iconButton",
                    icon: "plus",
                    onClick: () => console.log("Add clicked")
                  },
                  {
                    label: "",
                    name: "deleteIcon",
                    type: "iconButton",
                    icon: "trash",
                    onClick: () => console.log("Delete clicked")
                  }
                ]
              },
              { label: "Contact Notes", name: "contactNotes", type: "textarea" }
            ]
          }
        ]
      },
      {
        name: "Insurance",
        sections: [
          {
            sectionName: "Insurance Plan 1",
            fields: InsurancePlanFields("insurancePlan1_")
          },
          {
            sectionName: "Insurance Plan 2",
            fields: InsurancePlanFields("insurancePlan2_")
          }
        ]
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="New Family">
      <DynamicForm
        tabs={formStructure.tabs}
        handleFormSubmit={handleFormSubmit}
        formData={formData}
        handleInputChange={(value, fieldName) =>
          setFormData((prev) => ({ ...prev, [fieldName]: value }))
        }
      />
    </CommonPopup>
  );
};

export default NewFamilyPopup;

const DocumentsService = {
  getDirectories: async () => {
    return Promise.resolve([
      { id: 1, name: "Profile Picture" },
      { id: 2, name: "Tooth Charts" },
      { id: 3, name: "Insurance" },
      { id: 4, name: "Perio Chart" },
      { id: 5, name: "Clinical 1" },
      { id: 6, name: "Perio Charts" },
      { id: 7, name: "Health History" },
      { id: 8, name: "Claim Attachments" },
      { id: 9, name: "Miscellaneous" },
      { id: 10, name: "Patient License" }
    ]);
  },

  getDirectoryFiles: async (directoryId) => {
    // return files for the given directory id
    return Promise.resolve(
      [
        { id: 1, name: "Profile Photo 1", date: "04/23/2024", directoryId: 1 },
        { id: 2, name: "Profile Photo 2", date: "04/22/2024", directoryId: 1 },
        { id: 3, name: "Profile Photo 3", date: "04/21/2024", directoryId: 1 },
        { id: 4, name: "Profile Photo 4", date: "04/20/2024", directoryId: 1 },
        { id: 5, name: "Profile Photo 5", date: "04/19/2024", directoryId: 1 },
        { id: 6, name: "Tooth Chart 1", date: "04/23/2024", directoryId: 2 },
        { id: 7, name: "Tooth Chart 2", date: "04/22/2024", directoryId: 2 },
        { id: 8, name: "Tooth Chart 3", date: "04/21/2024", directoryId: 2 },
        { id: 9, name: "Tooth Chart 4", date: "04/20/2024", directoryId: 2 },
        { id: 10, name: "Tooth Chart 5", date: "04/19/2024", directoryId: 2 },
        { id: 11, name: "Insurance Doc 1", date: "04/23/2024", directoryId: 3 },
        { id: 12, name: "Insurance Doc 2", date: "04/22/2024", directoryId: 3 },
        { id: 13, name: "Insurance Doc 3", date: "04/21/2024", directoryId: 3 },
        { id: 14, name: "Insurance Doc 4", date: "04/20/2024", directoryId: 3 },
        { id: 15, name: "Insurance Doc 5", date: "04/19/2024", directoryId: 3 },
        { id: 16, name: "Perio Chart 1", date: "04/23/2024", directoryId: 4 },
        { id: 17, name: "Perio Chart 2", date: "04/22/2024", directoryId: 4 },
        { id: 18, name: "Perio Chart 3", date: "04/21/2024", directoryId: 4 },
        { id: 19, name: "Perio Chart 4", date: "04/20/2024", directoryId: 4 },
        { id: 20, name: "Perio Chart 5", date: "04/19/2024", directoryId: 4 },
        { id: 21, name: "Clinical Doc 1", date: "04/23/2024", directoryId: 5 },
        { id: 22, name: "Clinical Doc 2", date: "04/22/2024", directoryId: 5 },
        { id: 23, name: "Clinical Doc 3", date: "04/21/2024", directoryId: 5 },
        { id: 24, name: "Clinical Doc 4", date: "04/20/2024", directoryId: 5 },
        { id: 25, name: "Clinical Doc 5", date: "04/19/2024", directoryId: 5 }
      ].filter((file) => file.directoryId === directoryId)
    );
  }
};

export default DocumentsService;

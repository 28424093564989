import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { NoteTemplateService } from "../../../services/AdminService";

const AdminNoteTemplates = () => {
  const [noteTemplates, setNoteTemplates] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const data = NoteTemplateService.getNoteTemplates();
    setNoteTemplates(data);
  }, []);

  useEffect(() => {
    const data = NoteTemplateService.getGroups();
    setGroups(data);
  }, []);

  const columnNote = [
    { field: "template", title: "Template" },
    { field: "scheduled", title: "Scheduled" }
  ];

  const columnGroup = [{ field: "treatment", title: "Treatment" }];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    ,
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  const statuses = ["Active", "InActive"];

  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Note Templates"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new Note")}
      addBtnLabel="Note"
      extraBtnLabel={["AI Text Generator", "Manage Fast Fills"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columnNote}
        rows={noteTemplates}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
      <DynamicGrid
        columns={columnGroup}
        rows={groups}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminNoteTemplates;

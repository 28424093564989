import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { PrescriptionService } from "../../../services/AdminService";

const AdminPrescriptions = () => {
  const [prescriptions, setPrescriptions] = useState([]);

  useEffect(() => {
    const data = PrescriptionService.getPrescriptions();
    setPrescriptions(data);
  }, []);

  const columns = [
    { field: "medication", title: "Medication" },
    { field: "controlled", title: "Controlled" },
    { field: "instructions", title: "Instructions" },
    { field: "quantity", title: "Quantity" },
    { field: "refills", title: "Refills" },
    { field: "notes", title: "Notes" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  return (
    <DatatableLayout
      title="Prescriptions"
      onAdd={() => console.log("Add new Prescription")}
      addBtnLabel="Prescription"
      extraBtnLabel={["Copy"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={prescriptions}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminPrescriptions;

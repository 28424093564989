import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const PatientCarousel = ({ familyMembers }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // Slide one item at a time
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  const CustomLeftArrow = ({ onClick }) => (
    <button onClick={onClick} className="custom-arrow custom-left-arrow">
      <div className="arrow-shape"></div>
    </button>
  );

  const CustomRightArrow = ({ onClick }) => (
    <button onClick={onClick} className="custom-arrow custom-right-arrow">
      <div className="arrow-shape"></div>
    </button>
  );

  return (
    <div className="patient-carousel">
      <Carousel
        responsive={responsive}
        infinite={false}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        showDots={false}
        arrows
        autoPlay={true}
        keyBoardControl
        containerClass="carousel-container"
        itemClass="carousel-item"
      >
        {familyMembers.map((member, index) => (
          <div key={index} className="carousel-item">
            <img src={member.image} alt={member.name} className="family-member-image" />
            <p className="family-member-name">{member.name}</p>
            <p className="family-member-details">{member.ageGender}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default PatientCarousel;

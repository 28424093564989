import React, { useState } from "react";
import { Switch, NumericTextBox } from "@progress/kendo-react-inputs";
import "./RecareReminders.css";
import Label from "src/components/atoms/Label";

const RecareReminderSettings = () => {
  const [daysPrevious, setDaysPrevious] = useState(60);
  const [daysFuture, setDaysFuture] = useState(14);
  const [maxReminders, setMaxReminders] = useState(15);
  const [months, setMonths] = useState(6);

  return (
    <div className="recare-reminders-container">
      {/* Unscheduled Recare Due Section */}
      <div className="recare-reminders-sub-container">
        <h4>Unscheduled Recare Due</h4>
        <div className="recare-reminders-label-plus-content">
          <Label style={{ width: "200px" }}>Send Recare Reminders:</Label>
          <Switch defaultChecked={true} />
        </div>
        <div className="recare-reminders-label-plus-content">
          <Label style={{ width: "120px" }}>Check Previous:</Label>
          <NumericTextBox
            style={{ width: "150px" }}
            value={daysPrevious}
            onChange={(e) => setDaysPrevious(e.value)}
            format="n0"
            spinners={true}
          />
          <span style={{ marginLeft: "10px" }}>Days</span>
        </div>
        <div className="recare-reminders-label-plus-content">
          <Label style={{ width: "120px" }}>Check Future:</Label>
          <NumericTextBox
            style={{ width: "150px" }}
            value={daysFuture}
            onChange={(e) => setDaysFuture(e.value)}
            format="n0"
            spinners={true}
          />
          <span style={{ marginLeft: "10px" }}>Days</span>
        </div>
      </div>

      {/* Reminders Per Patient Section */}
      <div className="recare-reminders-sub-container">
        <h4>Reminders Per Patient</h4>
        <div className="recare-reminders-label-plus-content">
          <Label style={{ width: "120px" }}>Max Reminders:</Label>
          <NumericTextBox
            style={{ width: "150px" }}
            value={maxReminders}
            onChange={(e) => setMaxReminders(e.value)}
            format="n0"
            spinners={true}
          />
        </div>
        <div className="recare-reminders-label-plus-content">
          <Label style={{ width: "50px" }}>Every:</Label>
          <NumericTextBox
            style={{ width: "150px" }}
            value={months}
            onChange={(e) => setMonths(e.value)}
            format="n0"
            spinners={true}
          />
          <span style={{ marginLeft: "10px" }}>Months</span>
        </div>
        <p style={{ marginTop: "10px" }}>Note: Recare Reminders are texted on Monday afternoon.</p>
      </div>
    </div>
  );
};

export default RecareReminderSettings;

import React, { useState, useRef } from "react";
import DynamicForm from "../../../organisms/DynamicForm";
import DynamicGrid from "../../../organisms/DynamicGrid";
import CommonPopup from "../../../molecules/CommonPopup";
import { useGridPrint, useGridExportToExcel } from "src/hooks/useGridActions";

const FillChairFollowUpPopup = ({ show, onClose }) => {
  const [formData, setFormData] = useState({});
  const gridRef = useRef(null);

  const handlePrint = useGridPrint(gridRef);
  const handleExportToExcel = useGridExportToExcel(gridRef);

  const gridData = [
    {
      id: 1,
      patient: "Jenny Joshi",
      planDate: "05/14/2024",
      followUp: "Scheduled",
      clinician: "NS",
      treatment: "RCTMol #2",
      mobile: "(916) 883-3675",
      comment: "-"
    },
    {
      id: 2,
      patient: "Jenny Joshi",
      planDate: "05/14/2024",
      followUp: "Scheduled",
      clinician: "NS",
      treatment: "RCTMol #2",
      mobile: "(916) 883-3675",
      comment: "-"
    }
  ];

  const handleSearch = () => console.log("Searching with criteria:", formData);

  const columns = [
    { field: "patient", title: "Patient", width: "100px" },
    { field: "planDate", title: "Plan Date", width: "100px" },
    { field: "followUp", title: "Follow Up", width: "100px" },
    { field: "clinician", title: "Clinician", width: "80px" },
    { field: "treatment", title: "Treatment", width: "100px" },
    { field: "mobile", title: "Mobile", width: "100px" },
    { field: "comment", title: "Comment", width: "80px" }
  ];
  // Define grid action buttons to be passed as a prop
  const gridActions = [
    { icon: "edit", title: "Edit", onClick: (dataItem) => console.log("Edit clicked", dataItem) },
    {
      icon: "calendar",
      title: "Schedule",
      onClick: (dataItem) => console.log("Schedule clicked", dataItem)
    },
    { icon: "phone", title: "Call", onClick: (dataItem) => console.log("Call clicked", dataItem) }
  ];
  const actionButtons = [
    { title: "Mass Text", icon: "comment", onClick: () => console.log("Mass Text clicked") },
    { title: "Export to Excel", icon: "file-excel", onClick: handleExportToExcel },
    { title: "Print", icon: "print", onClick: handlePrint }
  ];

  const formStructure = {
    title: "Follow Up",
    tabs: [
      {
        name: "Follow Up",
        sections: [
          {
            sectionName: "Search",
            fields: [
              { label: "Dates", name: "dates", type: "daterangepicker" },
              {
                label: "Order By",
                name: "orderBy",
                type: "select",
                options: [{ label: "Status", value: "status" }]
              },
              {
                label: "Clinician or Dept",
                name: "clinicianDept",
                type: "select",
                options: [{ label: "All", value: "all" }]
              },
              { name: "search", type: "searchbutton", icon: "search", onClick: handleSearch },
              { label: "Missed Visits", name: "missedVisits", type: "checkbox" }
            ]
          },
          {
            sectionHeader: {
              right: actionButtons.map((action) => ({
                type: "button",
                text: action.title,
                icon: action.icon,
                onClick: action.onClick
              }))
            },
            customSection: (
              <DynamicGrid ref={gridRef} columns={columns} rows={gridData} allowCheckable={true} />
            )
          }
        ]
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Follow Up">
      <DynamicForm
        tabs={formStructure.tabs}
        handleFormSubmit={(data) => setFormData(data)}
        formData={formData}
        handleInputChange={(value, fieldName) =>
          setFormData((prev) => ({ ...prev, [fieldName]: value }))
        }
      />
    </CommonPopup>
  );
};

export default FillChairFollowUpPopup;

import React, { useEffect, useState } from "react"
import { Checkbox } from "@progress/kendo-react-inputs"
import DynamicGrid from '../../../../organisms/DynamicGrid';
import FormActionsBar from '../../../../organisms/FormActionBar';
import Button from '../../../../atoms/Button';
import { getMedications } from "../../../../../services/health-info";

const Medications = () => {
  const [medications, setMedications] = useState([]);

  useEffect(() => {
    // if (clinicId && patientId) {
      getMedications().then(setMedications);
    // }
  }, []);

  const columns = [
    {
      field: "medication",
      title: "Medication",
      width: "250px",
    },
    {
      field: "prescription",
      title: "Prescription",
      width: "250px",
    },
    {
      field: "comment",
      title: "Comment",
      width: "250px",
    },
    {
      field: "status",
      title: "Status",
      width: "250px",
    }
  ];

  return <div style={{height:'500px'}}>
    <FormActionsBar>
      <Checkbox label={"Inactive"} />
      <Button icon="plus" themeColor="primary"> Medications </Button>
    </FormActionsBar>
    <DynamicGrid style={{ height: "500px" }}
      rows={medications}
      columns={columns}
      key={medications.length}
    >
    </DynamicGrid>
    <div className="k-form-buttons" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginBottom: '10px' }}>
      <Button themeColor="primary">Save</Button>
    </div>
  </div>

}

export default Medications

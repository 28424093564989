import { useState } from "react";

const usePopupVisibility = () => {
  const [popups, setPopups] = useState({});

  const openPopup = (name) => setPopups((prev) => ({ ...prev, [name]: true }));
  const closePopup = (name) => setPopups((prev) => ({ ...prev, [name]: false }));

  return {
    popups,
    openPopup,
    closePopup
  };
};

export default usePopupVisibility;

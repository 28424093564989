import React, { useState } from "react";
import {
  Input,
  TextArea,
  Checkbox,
  NumericTextBox,
  RadioGroup
} from "@progress/kendo-react-inputs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import IconButton from "../atoms/IconButton";
import ActionButton from "../atoms/ActionButton";
import InputField from "../atoms/InputField";
import "./DynamicFormFields.css";
import Button from "../atoms/Button";
import DateRangePicker from "../atoms/DateRangePicker";
import {  ColorPicker } from "@progress/kendo-react-inputs";

// Password Field Component
const PasswordFieldComponent = ({ field, fieldName, fieldValue, handleInputChange }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <div className="password-field-container">
      <InputField
        type={isPasswordVisible ? "text" : "password"}
        name={fieldName}
        value={fieldValue}
        placeholder={field.placeholder}
        required={field.required}
        onChange={(e) => handleInputChange(e.target.value, fieldName)}
      />
      <IconButton
        icon={isPasswordVisible ? "eye-slash" : "eye"}
        onClick={() => {
          setIsPasswordVisible(!isPasswordVisible);
        }}
        className="visibility-toggle"
        title={isPasswordVisible ? "Hide" : "Show"}
        type="button"
      />
    </div>
  );
};

// Composite Field Component
const CompositeComponent = ({ field, handleInputChange, formData, fieldName }) => (
  <div className="composite-field">
    {field.subFields.map((subField, idx) => (
      <div key={idx} className="composite-subfield">
        {RenderField(subField, handleInputChange, formData)}
      </div>
    ))}
  </div>
);

// Render Field Component
const RenderField = (field, handleInputChange, formData, uniquePrefix) => {
  const fieldName = uniquePrefix ? `${uniquePrefix}_${field?.name}` : field?.name;
  const fieldValue = formData?.[fieldName] !== undefined ? formData[fieldName] : "";

  const updateCheckboxGroup = (checked, value) => {
    const updatedValue = checked
      ? [...(fieldValue || []), value]
      : (fieldValue || []).filter((val) => val !== value);
    handleInputChange(updatedValue, fieldName);
  };

  switch (field?.type) {
    case "input":
      return (
        <InputField
          type={field.inputType || "text"}
          name={fieldName}
          value={fieldValue}
          placeholder={field.placeholder}
          required={field.required}
          onChange={(e) => handleInputChange(e.target.value, fieldName)}
        />
      );

    case "textarea":
      return (
        <div className="form-group full-width-textarea-group">
          <label className="textarea-label">
            {field.label + ":"}
            {field.required && <span style={{ color: "red" }}>*</span>}
          </label>
          <TextArea
            rows={field.rows || 1}
            className="full-width-textarea"
            name={field.name}
            value={fieldValue || ""}
            placeholder={field.placeholder}
            required={field.required}
            onChange={(e) => handleInputChange(e.target.value, field.name)}
          />
        </div>
      );

    case "select":
      return (
        <DropDownList
          data={field?.options}
          textField="label"
          dataItemKey="value"
          name={fieldName}
          value={field.options?.find((option) => option.value === fieldValue) || null}
          required={field.required}
          defaultItem={{ label: field.placeholder || "Select an option", value: "" }}
          onChange={(e) => handleInputChange(e.target.value.value, fieldName)}
          toggle={true}
        />
      );

    case "multiselect":
      return (
        <MultiSelect
          data={field.options}
          textField="label"
          dataItemKey="value"
          name={fieldName}
          value={field.options.filter((option) => fieldValue.includes(option.value))}
          placeholder={field.placeholder}
          onChange={(e) =>
            handleInputChange(
              e.target.value.map((item) => item.value),
              fieldName
            )
          }
        />
      );

    case "date":
      return (
        <DatePicker
          name={fieldName}
          value={fieldValue || null}
          onChange={(e) => handleInputChange(e.value, fieldName)}
          required={field.required}
        />
      );
    case "daterangepicker":
      return (
        <DateRangePicker
          value={fieldValue || { start: null, end: null }}
          onChange={(e) => handleInputChange(e.value, fieldName)}
          required={field.required}
          placeholder={field.placeholder || "Select date range"}
          startDateInputSettings={{ label: "" }}
          endDateInputSettings={{ label: "" }}
          style={{
            alignSelf: "center"
          }}
        />
      );
    case "numerictextbox":
      return (
        <div className={field.icon ? "numeric numeric-textbox-container" : "numeric"}>
          <NumericTextBox
            className="numeric-textbox"
            name={fieldName}
            value={fieldValue || 0}
            min={field.min}
            max={field.max}
            step={field.step || 1}
            placeholder={field.placeholder}
            onChange={(e) => handleInputChange(e.value, fieldName)}
          />
          {field?.icon && (
            <IconButton type="button" className="numeric-textbox-button" icon={field.icon || ""} />
          )}
        </div>
      );

    case "checkbox":
      return (
        <div className="checkbox-class">
          <Checkbox
            name={fieldName}
            checked={Boolean(fieldValue)}
            label={field.labelPosition === "internal" ? field.label : ""}
            onChange={(e) => handleInputChange(e.target.value, fieldName)}
          />
        </div>
      );

    case "checkboxGroup":
      return (
        <div className="checkbox-group">
          <div className="checkbox-group-container">
            {field.options.map((option, idx) => (
              <div key={idx} className="checkbox-group-item">
                <Checkbox
                  name={`${fieldName}_${option.value}`}
                  checked={(fieldValue || []).includes(option.value)}
                  onChange={(e) => updateCheckboxGroup(e.target.value, option.value)}
                />
                <span>{option.label}</span>
              </div>
            ))}
          </div>
        </div>
      );

    case "radio":
      return (
        <RadioGroup
          data={field.options}
          name={fieldName}
          value={fieldValue}
          onChange={(e) => handleInputChange(e.value, fieldName)}
        />
      );

    case "password":
      return (
        <PasswordFieldComponent
          field={field}
          fieldName={fieldName}
          fieldValue={fieldValue}
          handleInputChange={handleInputChange}
        />
      );

    case "label":
      return <span className="label-value">{field.value || fieldValue}</span>;

    case "button":
      return (
        <ActionButton
          className="add-button"
          type="button"
          onClick={field.onClick || (() => console.log("Button clicked"))}
          icon={field.icon || ""}
          iconPosition={field.iconPosition || "before"}
        >
          {field.text}
        </ActionButton>
      );

    case "iconButton":
      return (
        <IconButton
          type="button"
          icon={field.icon || ""}
          onClick={field.onClick || (() => console.log("Icon button clicked"))}
          className={field.className || ""}
          title={field.title || ""}
        />
      );

    case "searchbutton":
      return (
        <ActionButton
          type="button"
          icon="search"
          onClick={field.onClick || (() => console.log("Search button clicked"))}
          className={field.className || "add-button"}
          // title={field.title || "Search"}
        />
      );

    case "composite":
      return (
        <CompositeComponent
          field={field}
          handleInputChange={handleInputChange}
          formData={formData}
          fieldName={fieldName}
        />
      );
    case "time":
      return (
        <TimePicker
          name={fieldName}
          value={fieldValue || null}
          placeholder={field.placeholder || "Select time"}
          onChange={(e) => handleInputChange(e.value, fieldName)}
          required={field.required}
        />
      );

    case "color":
      return (
        <ColorPicker
          value={fieldValue || null}
          onChange={(e) => handleInputChange(e.value, fieldName)}
          name={fieldName}
          // palette={field.palette || "basic"} // Options: 'basic', 'websafe', etc.
          gradient={field.gradient || false} // Enable gradient picker if needed
        />
      );
    case "custom":
      return field.component;

    default:
      return null;
  }
};

export default RenderField;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { EmployeeService } from "../../../services/AdminService";

const AdminEmployees = () => {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const data = EmployeeService.getEmployees();
    setEmployees(data);
  }, []);

  const columns = [{ field: "employer", title: "Employer" }];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  const statuses = ["Active", "Inactive"];

  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Employees"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new Employee")}
      addBtnLabel="Employee"
      extraBtnLabel={["Merge"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={columns}
        rows={employees}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminEmployees;

import React from "react";
import ActionButton from "../../atoms/ActionButton";
import Dropdown from "../../atoms/Dropdown";
import NavbarTemplate from "../NavbarTemplate";
import "./DatatableLayout.css";

const DatatableLayout = ({
  title,
  statusOptions,
  status,
  onStatusChange,
  onAdd,
  addBtnLabel,
  extraBtnLabel = [],
  onExtraAction,
  saveButton,
  onSave,
  children
}) => {
  return (
    <NavbarTemplate>
      <div className="datatable-layout">
        <div className="datatable-header">
          <h1>{title}</h1>
          <div className="header-actions">
            {statusOptions && (
              <Dropdown options={statusOptions} value={status} onChange={onStatusChange} />
            )}
            {onAdd && (
              <ActionButton icon="plus" onClick={onAdd} className="add-button">
                {addBtnLabel}
              </ActionButton>
            )}
            {extraBtnLabel.length > 0 &&
              extraBtnLabel.map((label, index) => (
                <ActionButton
                  key={index}
                  icon="user"
                  onClick={() => onExtraAction(label)}
                  className="add-button"
                >
                  {label}
                </ActionButton>
              ))}
          </div>
        </div>
        <div className="content">{children}</div>
        {saveButton && (
          <ActionButton onClick={onSave} className="save-button">
            {saveButton}
          </ActionButton>
        )}
      </div>
    </NavbarTemplate>
  );
};

export default DatatableLayout;

import React, { useEffect, useState } from "react"
import DynamicGrid from "../../../organisms/DynamicGrid"
import PatientService from "../../../../services/patient-service"
import { Checkbox } from "@progress/kendo-react-inputs";
import Button from "../../../atoms/Button";
import FormActionsBar from "../../../organisms/FormActionBar"
import DateRangePicker from "../../../atoms/DateRangePicker";

const DateRangeSection = () => (
  <div style={{ display: 'flex', flexDirection: 'row', flex: .7, height: '100%', marginRight: '20px'}}>
    <div style={{width: '70px', alignSelf: 'center'}}> Dates :</div>
    <DateRangePicker 
      startDateInputSettings={{ label: '' }} 
      endDateInputSettings={{ label: '' }} 
      style={{alignSelf: 'center'}}  
    />
  </div>
);

const DateFilterButtons = () => (
  <div style={{ display: 'flex', flexDirection: 'row', height: '100%'}}>
    <DateFilterButton label="All" />
    <DateFilterButton label="12 Months" />
  </div>
);

const DateFilterButton = ({ label }) => (
  <div style={{ verticalAlign: 'middle', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', marginRight: '50px' }}>
    <span className="k-icon k-font-icon k-i-calendar" style={{ marginRight: '10px' }}></span>
    <span>{label}</span>
  </div>
);

const ActionButtons = () => (
  <div style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
    <Button icon="refresh" themeColor="primary" style={{ marginRight: '10px' }}></Button>
    <Button icon="plus" themeColor="primary">Conversation</Button>
  </div>
);

const ConversationsGrid = ({ conversations }) => {
  const columns = [
    { field: "date", title: "Date", width: "100px" },
    { field: "time", title: "Time", width: "100px" },
    { field: "patient", title: "Patient", width: "150px" },
    { field: "office", title: "Office", width: "150px" },
    { field: "source", title: "Source", width: "150px" },
    { field: "user", title: "User", width: "150px" },
    { field: "topic", title: "Topic", width: "150px" },
    { field: "method", title: "Method", width: "150px" },
    { field: "conversation", title: "Conversation", width: "250px" }
  ];

  return (
    <DynamicGrid
      columns={columns}
      rows={conversations}
      key={conversations.length || 0}
    />
  );
};

const Conversations = () => {
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    PatientService.fetchPatientConversations().then(setConversations);
  }, []);

  return (
    <div>
      <FormActionsBar>
        <div style={{ marginLeft: '10px', width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'start', height: '50px'}}>
          <DateRangeSection />
          <DateFilterButtons />
        </div>
        <div style={{ width: '320px', height: '50px', display: 'flex', height: '100%', alignSelf: 'center', paddingRight: '0px'}}>
          <div style={{marginRight: '10px', alignSelf: 'center'}}>
            <Checkbox label={"Family Conversations"} />
          </div>
          <ActionButtons />
        </div>
      </FormActionsBar>
      <ConversationsGrid conversations={conversations} />
    </div>
  );
};

export default Conversations

import React, { useState, useRef } from "react";
import DynamicForm from "src/components/organisms/DynamicForm";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import CommonPopup from "src/components/molecules/CommonPopup";
import { useGridPrint, useGridExportToExcel } from "src/hooks/useGridActions";

const CasesAndBenefitsPopup = ({ show, onClose }) => {
  const [formData, setFormData] = useState({});
  const gridRef = useRef(null);

  const handlePrint = useGridPrint(gridRef);
  const handleExportToExcel = useGridExportToExcel(gridRef);

  const gridData = [
    {
      id: 1,
      patient: "Jenny Joshi",
      fees: "$0.00",
      discount: "$0.00",
      insShare: "$0.00",
      ptShare: "$0.00",
      remIns1: "$1,500.00",
      remIns2: "$0.00",
      mobile: "None",
      details: [
        {
          planDate: "11/28/2022",
          code: "D1575",
          procedure: "distal shoe spacer...",
          tooth: "-",
          area: "-",
          fee: "$0.00",
          discount: "$0.00",
          insShare: "$0.00",
          ptShare: "$0.00"
        }
        // Add more rows as needed
      ]
    }
    // Add more main rows if needed
  ];

  const columns = [
    { field: "patient", title: "Patient", width: "150px" },
    { field: "fees", title: "Fees", width: "120px" },
    { field: "discount", title: "Discount", width: "120px" },
    { field: "insShare", title: "Ins Share", width: "120px" },
    { field: "ptShare", title: "Pt Share", width: "120px" },
    { field: "remIns1", title: "Rem Ins 1", width: "120px" },
    { field: "remIns2", title: "Rem Ins 2", width: "120px" },
    { field: "mobile", title: "Mobile", width: "120px" }
  ];

  const detailComponent = ({ dataItem }) => (
    <div className="detail-component">
      {dataItem.details && (
        <DynamicGrid
          columns={[
            { field: "planDate", title: "Plan Date", width: "120px" },
            { field: "code", title: "Code", width: "80px" },
            { field: "procedure", title: "Procedure", width: "150px" },
            { field: "tooth", title: "Tooth", width: "80px" },
            { field: "area", title: "Area", width: "80px" },
            { field: "fee", title: "Fee", width: "120px" },
            { field: "discount", title: "Discount", width: "120px" },
            { field: "insShare", title: "Ins Share", width: "120px" },
            { field: "ptShare", title: "Pt Share", width: "120px" }
          ]}
          rows={dataItem.details}
        />
      )}
    </div>
  );

  const formStructure = {
    title: "Cases and Benefits",
    tabs: [
      {
        name: "Cases and Benefits",
        sections: [
          {
            sectionName: "Search Criteria",
            fields: [
              {
                label: "Cases Dates",
                name: "caseDates",
                type: "daterangepicker"
              },
              {
                label: "Treat Range",
                name: "treatRange",
                type: "multiselect",
                options: [
                  { label: "No Show", value: "noShow" },
                  { label: "Completed", value: "completed" }
                  // Add more options as needed
                ]
              },
              {
                label: "Clinician",
                name: "clinician",
                type: "multiselect",
                options: [
                  { label: "All", value: "all" },
                  { label: "Dr. Smith", value: "drSmith" }
                  // Add more options as needed
                ]
              },
              {
                label: "Rem Ins 1 Benefits",
                name: "remIns1Benefits",
                type: "numerictextbox",
                placeholder: "0"
              },
              {
                label: "Rem Ins 2 Benefits",
                name: "remIns2Benefits",
                type: "numerictextbox",
                placeholder: "0"
              },
              {
                label: "Preset Range",
                name: "presetRange",
                type: "multiselect",
                options: [
                  { label: "All", value: "all" },
                  { label: "Range 1", value: "range1" }
                  // Add more options as needed
                ]
              },
              { label: "No Future Visit", name: "noFutureVisit", type: "checkbox" },
              { label: "Cash Patient", name: "cashPatient", type: "checkbox" },
              {
                name: "search",
                type: "searchbutton",
                icon: "search",
                onClick: () => console.log("Search clicked")
              }
            ]
          },
          {
            sectionHeader: {
              right: [
                {
                  type: "button",
                  text: "Mass Text",
                  icon: "sms",
                  onClick: () => console.log("Mass Text clicked")
                },
                {
                  type: "button",
                  text: "Export to Excel",
                  icon: "file-excel",
                  onClick: handleExportToExcel
                },
                {
                  type: "button",
                  text: "Print",
                  icon: "print",
                  onClick: handlePrint
                }
              ]
            },
            customSection: (
              <DynamicGrid
                ref={gridRef}
                columns={columns}
                rows={gridData}
                DetailComponent={detailComponent}
                allowExpand={true}
                allowCheckable={true}
              />
            )
          }
        ]
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Cases and Benefits">
      <DynamicForm
        tabs={formStructure.tabs}
        handleFormSubmit={(data) => setFormData(data)}
        formData={formData}
        handleInputChange={(value, fieldName) =>
          setFormData((prev) => ({ ...prev, [fieldName]: value }))
        }
      />
    </CommonPopup>
  );
};

export default CasesAndBenefitsPopup;

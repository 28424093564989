import React, { useState } from "react";
import { Switch } from "@progress/kendo-react-inputs";
import { RadioButton } from "@progress/kendo-react-inputs";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import "./SettingsOnlineReviews.css";
import Label from "src/components/atoms/Label";
import InputField from "src/components/atoms/InputField";
import Dropdown from "src/components/atoms/Dropdown";

const SettingsOnlineReviews = () => {
  const [reviewType, setReviewType] = useState("");
  const [happinessFilter, setHappinessFilter] = useState(true);
  const [minRating, setMinRating] = useState("");
  const [reviewFrequency, setReviewFrequency] = useState(0);
  const [sendInviteType, setSendInviteType] = useState("Manually");
  const [rotationType, setRotationType] = useState("Fixed");
  const [selectedPlatform, setSelectedPlatform] = useState("Google");
  const [links, setLinks] = useState({
    googleURL: "",
    yelpURL: "",
    facebookURL: "",
    googlePlaceID: "",
    yelpBizID: "",
    facebookPageID: ""
  });

  const handleLinkChange = (key, value) => {
    setLinks({ ...links, [key]: value });
  };

  const reviewTypes = ["Type 1", "Type 2", "Type 3"];
  const ratings = [1, 2, 3, 4, 5];

  return (
    <DatatableLayout title="Online Reviews" saveButton="Save">
      <div className="online-reviews-container">
        <div className="online-reviews-sub-container">
          <div className="online-reviews-label-plus-component">
            <Label style={{ width: "180px" }}>Review Type:</Label>
            <Dropdown options={reviewTypes} value={reviewType} onChange={setReviewType} />
          </div>
          <div className="online-reviews-label-plus-component">
            <Label>Happiness Filter:</Label>
            <Switch checked={happinessFilter} onChange={(e) => setHappinessFilter(e.value)} />
          </div>
        </div>
        <div className="online-reviews-sub-container">
          <div className="online-reviews-label-plus-component">
            <Label>Send Review Invites:</Label>
            <div>
              <RadioButton
                name="sendInvite"
                value="Manually"
                checked={sendInviteType === "Manually"}
                label="Manually"
                onChange={(e) => setSendInviteType(e.value)}
              />
              <RadioButton
                name="sendInvite"
                value="Prompt"
                checked={sendInviteType === "Prompt"}
                label="Prompt"
                onChange={(e) => setSendInviteType(e.value)}
              />
              <RadioButton
                name="sendInvite"
                value="Automatically"
                checked={sendInviteType === "Automatically"}
                label="Automatically"
                onChange={(e) => setSendInviteType(e.value)}
              />
            </div>
          </div>
          <div className="online-reviews-label-plus-component">
            <Label style={{ width: "340px" }}>Min Rating for Review Link:</Label>
            <Dropdown options={ratings} value={minRating} onChange={setMinRating} />
          </div>
        </div>
        <div className="online-reviews-sub-container">
          <div className="online-reviews-label-plus-component">
            <Label style={{ width: "200px" }}>Limit to Once Every:</Label>
            <NumericTextBox
              value={reviewFrequency}
              onChange={(e) => setReviewFrequency(e.value)}
              format="n0"
            />
          </div>
        </div>

        <div className="social-media-links-container">
          <h3>Social Media Links</h3>
          <div className="social-media-links-label-plus-component">
            <Label>Send Review Invites:</Label>
            <RadioButton
              name="rotation"
              value="Fixed"
              checked={rotationType === "Fixed"}
              label="Fixed"
              onChange={(e) => setRotationType(e.value)}
            />
            <RadioButton
              name="rotation"
              value="Rotate"
              checked={rotationType === "Rotate"}
              label="Rotate"
              onChange={(e) => setRotationType(e.value)}
            />
          </div>
          <div className="social-media-links-label-plus-component">
            <Label>Send Review Invites:</Label>
            <RadioButton
              name="platform"
              value="Google"
              checked={selectedPlatform === "Google"}
              label="Google"
              onChange={(e) => setSelectedPlatform(e.value)}
            />
            <RadioButton
              name="platform"
              value="Yelp"
              checked={selectedPlatform === "Yelp"}
              label="Yelp"
              onChange={(e) => setSelectedPlatform(e.value)}
            />
            <RadioButton
              name="platform"
              value="Facebook"
              checked={selectedPlatform === "Facebook"}
              label="Facebook"
              onChange={(e) => setSelectedPlatform(e.value)}
            />
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
            <div className="social-media-links-label-plus-component">
              <Label style={{ width: "175px" }}>Google Review URL:</Label>
              <InputField
                value={links.googleURL}
                onChange={(e) => handleLinkChange("googleURL", e.target.value)}
              />
            </div>
            <div className="social-media-links-label-plus-component">
              <Label style={{ width: "170px" }}>Yelp Review URL:</Label>
              <InputField
                value={links.yelpURL}
                onChange={(e) => handleLinkChange("yelpURL", e.target.value)}
              />
            </div>
            <div className="social-media-links-label-plus-component">
              <Label style={{ width: "175px" }}>Facebook Review URL:</Label>
              <InputField
                value={links.facebookURL}
                onChange={(e) => handleLinkChange("facebookURL", e.target.value)}
              />
            </div>

            <div className="social-media-links-label-plus-component">
              <Label style={{ width: "170px" }}>Google Place ID:</Label>
              <InputField
                value={links.googlePlaceID}
                onChange={(e) => handleLinkChange("googlePlaceID", e.target.value)}
              />
            </div>
            <div className="social-media-links-label-plus-component">
              <Label style={{ width: "170px" }}>Yelp Biz ID:</Label>
              <InputField
                value={links.yelpBizID}
                onChange={(e) => handleLinkChange("yelpBizID", e.target.value)}
              />
            </div>
            <div className="social-media-links-label-plus-component">
              <Label style={{ width: "170px" }}>Facebook Page ID:</Label>
              <InputField
                value={links.facebookPageID}
                onChange={(e) => handleLinkChange("facebookPageID", e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </DatatableLayout>
  );
};

export default SettingsOnlineReviews;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPatient } from "src/store/slices/patientSlice";
import { fetchPatient } from "src/store/actions/patientAction";
import usePopupVisibility from "../../hooks/usePopupVisibility"; // Path to the custom hook
import PatientVisitPopup from "./Patient/PopUps/PatientVisit";
import NoteTemplateManager from "./Patient/PopUps/NoteTemplates";
import PatientVisitsGrid from "./Patient/PopUps/PatientVisitsGrid";
import HygieneRecarePopup from "./Patient/PopUps/HygineRecare";
import EditInsurancePlan from "./Patient/PopUps/Insurance/InsurancePlan";
import FillChairHygieneRecarePopup from "./Schedule/FillChair/FillChairHygieneRecare";
import FillChairToSchedulePopup from "./Schedule/FillChair/FillChairToSchedule";
import FillChairFollowUpPopup from "./Schedule/FillChair/FillChairFollowUp";
import InsuranceVerificationPopup from "./Schedule/InsuranceVerificationPopup";
import LabWorkPopup from "./Schedule/LabWorkPopup";
import OpenSlotsPopup from "./Schedule/OpenSlotsPopup";
import VisitSummariesPopup from "./Schedule/VisitSummariesPopup";
import AppointmentConfirmationPopup from "./Schedule/ConfirmationPopup";
import NewFamilyPopup from "./Patient/PopUps/NewFamilyPopup";
import AddAlertPopup from "./Patient/PopUps/AlertPopup";
import AlertListComponent from "./Patient/PopUps/AlertListComponent";
import FastFillComponent from "./Patient/PopUps/FastFillComponent";
import InsuranceGrid from "./Patient/PopUps/Insurance/InsuranceGrid"; // Adjust path if needed
import UploadInsuranceCardPopup from "./Patient/PopUps/Insurance/UploadInsuranceCardPopup"; // Import the new component
import CasesAndBenefitsPopup from "./Patient/PopUps/Insurance/CasesAndBenefitsPopup";
import { patients } from "./PatientData";
import PatientCard from "./Patient/PatientPanel/PatientCard";
import ScheduleAppointment from "./ScheduleAppointment/ScheduleAppointment";

const ParentComponent = ({ patientId }) => {
  const dispatch = useDispatch();

  const { popups, openPopup, closePopup } = usePopupVisibility();

  // Popup names and components
  const popupConfigurations = [
    {
      name: "PatientVisit",
      component: (
        <PatientVisitPopup
          show={popups.PatientVisit}
          onClose={() => closePopup("PatientVisit")}
          patients={patients}
        />
      )
    },
    {
      name: "NoteTemplateManager",
      component: (
        <NoteTemplateManager
          show={popups.NoteTemplateManager}
          onClose={() => closePopup("NoteTemplateManager")}
        />
      )
    },
    {
      name: "HygieneRecare",
      component: (
        <HygieneRecarePopup
          show={popups.HygieneRecare}
          onClose={() => closePopup("HygieneRecare")}
        />
      )
    },
    {
      name: "EditInsurancePlan",
      component: (
        <EditInsurancePlan
          show={popups.EditInsurancePlan}
          onClose={() => closePopup("EditInsurancePlan")}
        />
      )
    },
    {
      name: "FillChairHygieneRecare",
      component: (
        <FillChairHygieneRecarePopup
          show={popups.FillChairHygieneRecare}
          onClose={() => closePopup("FillChairHygieneRecare")}
        />
      )
    },
    {
      name: "FillChairToSchedule",
      component: (
        <FillChairToSchedulePopup
          show={popups.FillChairToSchedule}
          onClose={() => closePopup("FillChairToSchedule")}
        />
      )
    },
    {
      name: "FillChairFollowUp",
      component: (
        <FillChairFollowUpPopup
          show={popups.FillChairFollowUp}
          onClose={() => closePopup("FillChairFollowUp")}
        />
      )
    },
    {
      name: "InsuranceVerification",
      component: (
        <InsuranceVerificationPopup
          show={popups.InsuranceVerification}
          onClose={() => closePopup("InsuranceVerification")}
        />
      )
    },
    {
      name: "LabWork",
      component: <LabWorkPopup show={popups.LabWork} onClose={() => closePopup("LabWork")} />
    },
    {
      name: "OpenSlots",
      component: <OpenSlotsPopup show={popups.OpenSlots} onClose={() => closePopup("OpenSlots")} />
    },
    {
      name: "VisitSummaries",
      component: (
        <VisitSummariesPopup
          show={popups.VisitSummaries}
          onClose={() => closePopup("VisitSummaries")}
        />
      )
    },
    {
      name: "AppointmentConfirmation",
      component: (
        <AppointmentConfirmationPopup
          show={popups.AppointmentConfirmation}
          onClose={() => closePopup("AppointmentConfirmation")}
        />
      )
    },
    {
      name: "NewFamily",
      component: <NewFamilyPopup show={popups.NewFamily} onClose={() => closePopup("NewFamily")} />
    },
    {
      name: "AddAlert",
      component: <AddAlertPopup show={popups.AddAlert} onClose={() => closePopup("AddAlert")} />
    },
    {
      name: "AlertListComponent",
      component: (
        <AlertListComponent
          show={popups.AlertListComponent}
          onClose={() => closePopup("AlertListComponent")}
        />
      )
    },
    {
      name: "FastFillComponent",
      component: (
        <FastFillComponent
          show={popups.FastFillComponent}
          onClose={() => closePopup("FastFillComponent")}
        />
      )
    },
    {
      name: "InsuranceGrid",
      component: (
        <InsuranceGrid show={popups.InsuranceGrid} onClose={() => closePopup("InsuranceGrid")} />
      )
    },
    {
      name: "UploadInsuranceCard",
      component: (
        <UploadInsuranceCardPopup
          show={popups.UploadInsuranceCard}
          onClose={() => closePopup("UploadInsuranceCard")}
        />
      )
    },
    {
      name: "CasesAndBenefitsPopup",
      component: (
        <CasesAndBenefitsPopup
          show={popups.CasesAndBenefitsPopup}
          onClose={() => closePopup("CasesAndBenefitsPopup")}
        />
      )
    },
    {
      name: "ScheduleAppointment",
      component: (
        <ScheduleAppointment
          show={popups.ScheduleAppointment}
          onClose={() => closePopup("ScheduleAppointment")}
        />
      )
    }
  ];
  const patient = useSelector(selectPatient);
  console.log("patiee", patient);

  useEffect(() => {
    // Dispatch fetchPatient action on component mount
    dispatch(fetchPatient(patientId));
  }, [dispatch, patientId]);
  // Usage

  return (
    <div style={{height:"100vh"}}>
      {patient && <PatientCard patient={patient} />}
      {/* Render buttons to open each popup */}
      {popupConfigurations.map(({ name }) => (
        <button key={name} onClick={() => openPopup(name)}>
          Open {name} Popup
        </button>
      ))}

      {/* Render each popup component conditionally */}
      {popupConfigurations.map(({ name, component }) => popups[name] && component)}
    </div>
  );
};

export default ParentComponent;

import React, { useEffect, useState } from "react";

import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { loginSuccess, logout } from "../../../store/slices/authSlice";
import "./HomePageHeader.css";
import dentelLogo from "../../../assets/images/dentelLogo.png";
import { loginRequest } from "../../../authConfig";
import { Icon, popup } from "@progress/kendo-react-common";
import ProfileBox from "./profileBox";
import patientData from "../../../utils/patient-data.json";
import SearchPatientGrid from "../../molecules/SearchPatientGrid";
import AddPatient from "../../pages/Patient/PopUps/AddPatient";
import { useNavigate } from "react-router-dom";
import Button from "../../atoms/Button";
import NewFamilyPopup from "src/components/pages/Patient/PopUps/NewFamilyPopup";
import { setClinic } from "src/store/slices/clinicSlice";
import { fetchClinics } from "src/store/actions/clinicAction";
import ExpandableMenu from "../ExpandableMenu";
import PatientVisitPopup from "src/components/pages/Patient/PopUps/PatientVisit";
import { patients } from "../../pages/PatientData";
import CommonPopup from "src/components/molecules/CommonPopup";
import HealthInfoTabs from "src/components/pages/Patient/HealthInfo";
import Conversations from "src/components/pages/Patient/Conversations";
import usePatient from "src/hooks/use-patient";
import { PatientForms } from "src/components/pages/Patient/Forms";
import Letters from "src/components/pages/Patient/Letters";
import FillChairHygieneRecarePopup from "src/components/pages/Schedule/FillChair/FillChairHygieneRecare";
import FillChairToSchedulePopup from "src/components/pages/Schedule/FillChair/FillChairToSchedule";
import NoteTemplateManager from "src/components/pages/Patient/PopUps/NoteTemplates";
import CasesAndBenefitsPopup from "src/components/pages/Patient/PopUps/Insurance/CasesAndBenefitsPopup";
import FillChairFollowUpPopup from "src/components/pages/Schedule/FillChair/FillChairFollowUp";
import AppointmentsConfirmationPopup from "src/components/pages/Schedule/ConfirmationPopup";
import InsuranceVerificationPopup from "src/components/pages/Schedule/InsuranceVerificationPopup";
import LabWorkPopup from "src/components/pages/Schedule/LabWorkPopup";
import OpenSlotsPopup from "src/components/pages/Schedule/OpenSlotsPopup";
import VisitSummariesPopup from "src/components/pages/Schedule/VisitSummariesPopup";

const HomePageHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const patient = usePatient();

  const menus = {
    settings: [
      {
        title: "Practice",
        children: [
          { title: "Office", route: "/settings/office" },
          { title: "Access", route: "/settings/access" },
          { title: "Clinicians", route: "/settings/clinicians" },
          { title: "Staff", route: "/settings/staff" },
          { title: "Audit Logs", route: "/settings/auditlog" },
          { title: "Office Goals", route: "/settings/goals" },
          { title: "Office Documents", route: "/settings/officeDocs" },
          { title: "Pat Portal News", route: "/settings/notes" },
          { title: "Time Punches", route: "/settings/timePunches" },
          { title: "Pat Portal and Check-in", route: "/settings/checkin" },
          { title: "Notifications", route: "/settings/notification" },
          { title: "Theme Colors", route: "/settings/theme" }
        ]
      },
      {
        title: "Schedule",
        children: [
          { title: "Chairs", route: "/settings/chair" },
          { title: "Hours and Views", route: "/settings/access" },
          { title: "Time Blocks", route: "/settings/timeblock" },
          { title: "Dental Visits", route: "/settings/dentalvisit" },
          { title: "Ortho Visits", route: "/settings/orthovisit" },
          { title: "Hygiene Tracks", route: "/settings/hygienetrack" },
          { title: "Recare Follow-Up", route: "/settings/officeDocs" },
          { title: "Unconfirmed", route: "/settings/notes" },
          { title: "Required Fields", route: "/settings/timePunches" },
          { title: "Online Scheduling", route: "/settings/health-issues" },
          { title: "Forms Automation", route: "/settings/patientformautomation" },
          { title: "Patient Check-in", route: "/settings/checkin" },
          { title: "Patient Documents", route: "/settings/patientdocument" }
        ]
      },
      {
        title: "Clinical",
        children: [
          { title: "Profile", route: "/settings/profile" },
          { title: "Case Presenter", route: "/settings/casepresenter" },
          { title: "Case Rankings", route: "/settings/caseRanking" },
          { title: "Treatment Favourites", route: "/settings/treatmentFavorites" },
          { title: "Dental and Perio Charts", route: "/settings/dentalperiochart" },
          { title: "Ortho Lists", route: "/settings/orthoLists" },
          { title: "X-ray Imaging", route: "/settings/xray" },
          { title: "ePrescribing", route: "/settings/ePrescribing" },
          { title: "Patient Education", route: "/settings/patienteducation" }
        ]
      },
      {
        title: "Patient Communication",
        children: [
          { title: "Text Templates", route: "/settings/textTemplate" },
          { title: "Auto Reminders", route: "/settings/automatedReminders" },
          { title: "Online Reviews", route: "/settings/onlineReviews" }
        ]
      },
      {
        title: "Insurance",
        children: [
          { title: "Plans and Fees", route: "/settings/office" },
          { title: "Verification", route: "/settings/insuranceVerification" },
          { title: "Procedure Groups", route: "/settings/procedure" },
          { title: "eClaims", route: "/settings/staff" },
          { title: "Treatment Intervals", route: "/settings/treatmentInterval" }
        ]
      },
      {
        title: "Financial",
        children: [
          { title: "Payments and Claims", route: "/settings/payment" },
          { title: "Adjustments", route: "/settings/adjustment" },
          { title: "Statements", route: "/settings/statements" },
          { title: "Treatment Favourites", route: "/settings/treatmentFavorites" },
          { title: "Dental Products", route: "/settings/dentalProducts" },
          { title: "CC and PBT Setup", route: "/settings/goals" },
          { title: "Phone Integration", route: "/settings/phone" },
          { title: "Membership Plans", route: "/settings/membership" }
        ]
      }
    ],
    schedule: [
      {
        title: "Fill Chairs",
        children: [
          { title: "Hygiene Recare", popup: <FillChairHygieneRecarePopup /> },
          { title: "To Schedule", popup: <FillChairToSchedulePopup /> },
          { title: "Note Templates", popup: <NoteTemplateManager /> },
          { title: "Cases and Benifits", popup: <CasesAndBenefitsPopup /> },
          { title: "Follow Up", popup: <FillChairFollowUpPopup /> },
          { title: "Sooner", route: "/allergies" }
        ]
      },
      {
        title: "Unconfirmed",
        popup: <AppointmentsConfirmationPopup />
      },
      {
        title: "Insurance",
        popup: <InsuranceVerificationPopup />
      },
      {
        title: "Lab",
        popup: <LabWorkPopup />
      },
      {
        title: "Time Blocks"
      },
      {
        title: "Open Slots",
        popup: <OpenSlotsPopup />
      },
      {
        title: "Visit Summaries",
        popup: <VisitSummariesPopup />
      }
    ],
    //patient menus with popups
    patient: [
      {
        title: "General",
        children: [
          { title: "Profile", route: "/patient" },
          { title: "Visits", popup: <PatientVisitPopup patients={patients} /> },
          {
            title: "Health Info",
            popup: (
              <CommonPopup title={"Health Info"}>
                <HealthInfoTabs />
              </CommonPopup>
            )
          },
          { title: "Documents", route: "/documents" },
          {
            title: "Conversations",
            popup: (
              <CommonPopup title={"Conversations"}>
                <Conversations />
              </CommonPopup>
            )
          },
          {
            title: "Forms",
            popup: (
              <CommonPopup title={`Patient Forms For ${patient?.name}`}>
                <PatientForms />
              </CommonPopup>
            )
          },
          {
            title: "Letters",
            popup: (
              <CommonPopup title={"Letters"}>
                <Letters />
              </CommonPopup>
            )
          }
        ]
      },
      {
        title: "Clinical"
      },
      {
        title: "Financial"
      }
    ],
    admin: [
      {
        title: "Clinical",
        children: [
          { title: "Forms Builder", route: "/admin/formlist" },
          { title: "Treatments and Fees", route: "/treatments" },
          { title: "Note Templates", route: "/notes" },
          { title: "Prescriptions", route: "/admin/prescriptions" },
          { title: "Health Issues", route: "/admin/healthissues" },
          { title: "Allergies", route: "/admin/allergies" },
          { title: "Medications", route: "/admin/medications" }
        ]
      },
      {
        title: "Insurance",
        children: [
          { title: "Insurance Companies", route: "/admin/insurancecompanies" },
          { title: "Insurance Plans", route: "/insurance-plans" },
          { title: "Insurance Policies", route: "/insurance-policies" }
        ]
      },
      {
        title: "Marketing",
        children: [
          { title: "Referral Sources" },
          { title: "Marketing Campaigns" },
          { title: "Marketing Materials" }
        ]
      },
      {
        title: "Billing",
        children: [
          { title: "Paid Claims Without EOBs", route: "/admin/paidclaimwoeob" },
          { title: "Invoicing Fees", route: "/network" },
          { title: "Automated Charges", route: "/network" }
        ]
      },
      {
        title: "Employees",
        route: "/admin/employees"
      },
      {
        title: "Network and other",
        children: [
          { title: "Connections", route: "/network" },
          { title: "Pharmacies", route: "/admin/pharmacies" },
          { title: "Labs", route: "/admin/laboratories" },
          { title: "Referral Sources", route: "/specialties" },
          { title: "Office News", route: "/admin/officenews" }
        ]
      }
    ]
  };

  const { instance, accounts, inProgress } = useMsal();
  const [showProfileBox, setShowProfileBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(patientData);
  const [show, setShow] = useState(false);
  const [showFamily, setShowFamily] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [selectedButton, setSelectedButton] = useState(0); // State for selected button
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const clinics = useSelector((state) => state.clinics.clinics);
  const selectedClinic = useSelector((state) => state.clinics.selectedClinic);
  const elementRef = React.useRef(null);
  const [menuData, setMenuData] = useState([]);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const [hoveredButton, setHoveredButton] = useState(null);

  useEffect(() => {
    if (inProgress === "none" && accounts?.length > 0 && !isAuthenticated) {
      const account = accounts[0];
      let profile = account?.idTokenClaims;
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        })
        .then((x) => {
          const user = {
            displayName: profile.given_name + " " + profile.family_name,
            email: profile.emails[0] || profile.userPrincipalName,
            photoUrl: "",
            accessToken: x.accessToken
          };

          dispatch(loginSuccess(user));
        });
    }

    // Automatically trigger login if the user is not authenticated
    if (!isAuthenticated && inProgress === "none" && accounts?.length === 0) {
      handleLogin();
    }
  }, [inProgress, isAuthenticated, accounts, instance, dispatch]);

  useEffect(() => {
    dispatch(fetchClinics());
  }, []);

  useEffect(() => {
    if (clinics && clinics.length > 0) {
      dispatch(setClinic(clinics[0]));
    }
  }, [clinics]);

  const handleLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest);
      localStorage.setItem("isLoggedIn", "true");
    } catch (error) {
      console.error("Login redirect failed:", error);
    }
  };

  const handleOnClick = () => {
    setShow(!show);
    setShowGrid(false);
  };

  const handleOnClickFamily = () => {
    setShowFamily(!showFamily);
    setShowGrid(false);
  };

  const handleLogout = async () => {
    try {
      await instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      dispatch(logout());
      navigate("/");
    } catch (error) {
      console.error("Logout redirect failed:", error);
    }
  };

  const toggleProfileBox = () => {
    setShowProfileBox(!showProfileBox);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.trim() !== "") {
      const filtered = patientData.filter((patient) =>
        patient["Patient Name"].toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
      setShowGrid(filtered.length > 0);
    } else {
      setShowGrid(false);
    }
  };

  const handleButtonClick = (buttonIndex, route) => {
    setSelectedButton(buttonIndex); // Set the clicked button as selected
    navigate(route); // Navigate to the respective route
  };

  const handleAdminMenuClick = () => {
    setShowDropdownMenu(true);
  };

  const onClinicSelect = (e) => {
    let clinicId = e.target.value;
    dispatch(setClinic(clinicId));
  };

  return (
    <div className="menu-container">
      <div className="menu-left">
        <img src={dentelLogo} alt="Logo" className="header-logo" />
        {isAuthenticated && (
          <>
            <DropDownList
              data={clinics}
              textField="text"
              dataItemKey="id"
              onChange={(e) => onClinicSelect(e)}
              value={selectedClinic}
              className="header-dropdown"
              style={{
                width: "150px"
              }}
            />
            <div className="search-plus-grid-container">
              <Input
                placeholder="Search patients"
                className="header-search"
                value={searchTerm}
                onChange={handleSearch}
              />
              {showGrid && (
                <SearchPatientGrid
                  patientData={filteredData}
                  handleOnClick={handleOnClick}
                  handleOnClickFamily={handleOnClickFamily}
                />
              )}
            </div>
          </>
        )}
      </div>

      {/* Button Group with selected button state */}
      <div className="button-group">
        <Button
          look="flat"
          icon="calendar"
          className={`buttons-container-button ${selectedButton === 0 ? "selected" : ""}`}
          onMouseEnter={(e) => {
            elementRef.current = e.currentTarget.querySelector("span");
            handleAdminMenuClick(e);
            setSelectedButton(0);
            setHoveredButton(0);
            setMenuData(menus.schedule);
          }}
          onClick={() => handleButtonClick(3, "/home")}
          onMouseLeave={() => {
            if (hoveredButton !== 0) {
              setMenuData([]);
            }
          }}
        >
          <span className="schedule">Schedule</span>
        </Button>
        <Button
          look="flat"
          icon="user"
          className={`buttons-container-button ${selectedButton === 1 ? "selected" : ""}`}
          onMouseEnter={(e) => {
            elementRef.current = e.currentTarget.querySelector("span");
            handleAdminMenuClick(e);
            setSelectedButton(1);
            setHoveredButton(1);
            setMenuData(menus.patient);
          }}
          onMouseLeave={() => {
            if (hoveredButton !== 1) {
              elementRef.current = null;
              setMenuData([]);
            }
          }}
        >
          <span className="anchor content">Patient</span>
        </Button>
        <Button
          look="flat"
          icon="user"
          // get ref of button
          className={`buttons-container-button ${selectedButton === 2 ? "selected" : ""}`}
          onMouseEnter={(e) => {
            elementRef.current = e.currentTarget.querySelector("span");
            handleAdminMenuClick(e);
            setSelectedButton(2);
            setHoveredButton(2);
            setMenuData(menus.admin);
          }}
          onMouseLeave={() => {
            if (hoveredButton !== 2) {
              elementRef.current = null;
              setMenuData([]);
            }
          }}
        >
          <span className="anchor content">Admin</span>
        </Button>
        <Button
          look="flat"
          icon="chart-line-markers"
          className={`buttons-container-button ${selectedButton === 3 ? "selected" : ""}`}
          onClick={() => handleButtonClick(3, "/analyzer")}
        >
          Analyzer
        </Button>
        <Button
          look="flat"
          icon="flag"
          className={`buttons-container-button ${selectedButton === 4 ? "selected" : ""}`}
          onClick={() => handleButtonClick(4, "/hurdle")}
        >
          Hurdle
        </Button>
      </div>

      <div className="menu-right">
        <div className="icon-group">
          <Icon name="bell" className="header-icon" />
          <Icon name="comment" className="header-icon" />
          <Icon
            name="gear"
            className="header-icon"
            onMouseEnter={(e) => {
              elementRef.current = e.target.element.querySelector("span");
              handleAdminMenuClick(e);
              setMenuData(menus.settings);
            }}
          >
            <span className="menu"></span>
          </Icon>
          <Icon name="question-circle" className="header-icon" />
        </div>

        {isAuthenticated && user ? (
          <div className="user-section">
            <img
              src={user?.photoUrl || "/placeholder.jpg"}
              alt="User Avatar"
              className="user-avatar"
              onClick={toggleProfileBox}
            />
            {showProfileBox && <ProfileBox user={user} handleLogout={handleLogout} />}
          </div>
        ) : (
          <Button look="flat" onClick={handleLogin}>
            Login
          </Button>
        )}
      </div>
      <div style={{ display: "none" }}>
        {show && <AddPatient show={show} onClose={handleOnClick} />}
      </div>
      <div style={{ display: "none" }}>
        {showFamily && <NewFamilyPopup show={showFamily} onClose={handleOnClickFamily} />}
      </div>

      <div style={{ display: "none" }}>
        <ExpandableMenu
          data={menuData}
          anchor={elementRef}
          show={showDropdownMenu}
          parentId={hoveredButton}
          onClose={() => {
            setShowDropdownMenu(false);
          }}
        />
      </div>
    </div>
  );
};

export default HomePageHeader;

import React, { useEffect, useState } from "react";
import Button from "src/components/atoms/Button";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import FormActionsBar from "src/components/organisms/FormActionBar";
import { getFamilyHistory } from "src/services/health-info";

const FamilyHealth = () => {
  const [familyHealth, setFamilyHealth] = useState([]);

  useEffect(() => {
    getFamilyHistory().then(setFamilyHealth);
  }, []);

  const columns = [
    { field: "familymember", title: "Family Member", width: "250px" },
    { field: "patrelation", title: "Pat Relation", width: "250px" },
    { field: "healthissue", title: "Health Issue", width: "250px" }
  ];

  return (
    <div className="family-health">
      <FormActionsBar>
        <Button icon="plus" themeColor="primary">
          Add Family Health
        </Button>
      </FormActionsBar>

      <DynamicGrid
        columns={columns}
        rows={familyHealth}
        noRecordMessage="There are no family health issues"
      />

      <div className="actions">
        <Button themeColor="primary">Save</Button>
      </div>
    </div>
  );
};

export default FamilyHealth;

import React from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Switch } from "@progress/kendo-react-inputs";
import "./PatientsAndVisits.css";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import ActionButton from "src/components/atoms/ActionButton";

const PatientsAndVisits = () => {
  const productionGoals = [
    { clinician: "Jack Patel", goal: 150.0 },
    { clinician: "Sara Lee", goal: 200.0 },
    { clinician: "John Doe", goal: 180.0 },
    { clinician: "Mira Smith", goal: 220.0 },
    { clinician: "Leo Johnson", goal: 160.0 }
  ];

  const productionGoalsColumns = [
    { field: "clinician", title: "clinician" },
    { field: "goal", title: "goal" }
  ];

  const departmentData = [
    { department: "456" },
    { department: "456" },
    { department: "Test Department 3" },
    { department: "Test Department 4" },
    { department: "Test Department 5" }
  ];

  const departmentColumns = [{ field: "department", title: "department" }];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  return (
    <div className="container">
      <div style={{ display: "flex", gap: "30px" }}>
        {/* Production Goals Section */}
        <div className="section production-goals">
          <div className="section-header">
            <p>Production Goals</p>
            <ActionButton
              style={{
                background: "rgba(167, 176, 69, 1)",
                color: "black",
                padding: "4 10 4 10px"
              }}
              icon="plus"
            >
              Staff
            </ActionButton>
          </div>
          <DynamicGrid
            columns={productionGoalsColumns}
            rows={productionGoals}
            actions={actions}
            allowSort={false}
            allowFilter={false}
          />
        </div>

        {/* Patients and Visits Section */}
        <div className="section patients-visits">
          <div className="section-header">
            <p>Patients and Visits</p>
          </div>
          <div className="patients-visits-input-group">
            <label>Average Visit Production</label>
            <NumericTextBox defaultValue={200} />
          </div>
          <div className="patients-visits-input-group">
            <label>Active Patients</label>
            <NumericTextBox defaultValue={1500} />
          </div>
          <h4>New Patients</h4>
          {["Daily", "Weekly", "Monthly", "Yearly"].map((period) => (
            <div className="patients-visits-input-group" key={period}>
              <label>{period}</label>
              <NumericTextBox defaultValue={1500} />
            </div>
          ))}

          <div className="patients-visits-input-group">
            <label>Treatment Acceptance</label>
            <NumericTextBox defaultValue={80} format="p0" />
          </div>
          <div className="patients-visits-input-group">
            <label>Pre-Appointment</label>
            <NumericTextBox defaultValue={90} format="p0" />
          </div>
          <div className="patients-visits-input-group">
            <label>Visit Wait Time (in min)</label>
            <NumericTextBox defaultValue={5} />
          </div>
          <div className="patients-visits-input-group">
            <label>Missed Visits</label>
            <NumericTextBox defaultValue={2} format="p0" />
          </div>
        </div>

        {/* Departments Section */}
        <div className="section departments">
          <div className="section-header">
            <p>Departments</p>
            <ActionButton
              style={{
                background: "rgba(167, 176, 69, 1)",
                color: "black",
                padding: "4 10 4 10px"
              }}
              icon="plus"
            >
              Department
            </ActionButton>
          </div>
          <DynamicGrid
            columns={departmentColumns}
            rows={departmentData}
            actions={actions}
            allowSort={false}
            allowFilter={false}
          />
        </div>
      </div>

      {/* Switches */}
      <div className="switches-plus-save-button">
        <div className="switches">
          <div className="switch-item">
            <label>Use Net Production for Schedule Total and Huddle:</label>
            <Switch />
          </div>
          <div className="switch-item">
            <label>No Double Booking for Unscheduled Time:</label>
            <Switch checked />
          </div>
        </div>

        <ActionButton primary={true} className="patients-visits-save-button">
          Save
        </ActionButton>
      </div>
    </div>
  );
};

export default PatientsAndVisits;

import React, { useState } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Switch } from "@progress/kendo-react-inputs";
import "./SettingsAccess.css";
import DynamicGrid from "src/components/organisms/DynamicGrid";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import ActionButton from "src/components/atoms/ActionButton";

const settingsAccessData = [
  {
    username: "Newstaff",
    teamMember: "Jenny Joshi",
    role: "Administrators",
    email: "web@practice.com",
    mobile: "(530) 306-7541",
    office: "Smiles Clinic, Eastern Clinic, Western Clinic..."
  },
  {
    username: "Newstaff",
    teamMember: "Jenny Joshi",
    role: "Administrators",
    email: "web@practice.com",
    mobile: "(530) 306-7541",
    office: "Smiles Clinic, Eastern Clinic, Western Clinic..."
  },
  {
    username: "Newstaff",
    teamMember: "Jenny Joshi",
    role: "Administrators",
    email: "web@practice.com",
    mobile: "(530) 306-7541",
    office: "Smiles Clinic, Eastern Clinic, Western Clinic..."
  },
  {
    username: "Newstaff",
    teamMember: "Jenny Joshi",
    role: "Administrators",
    email: "web@practice.com",
    mobile: "(530) 306-7541",
    office: "Smiles Clinic, Eastern Clinic, Western Clinic..."
  },
  {
    username: "Newstaff",
    teamMember: "Jenny Joshi",
    role: "Administrators",
    email: "web@practice.com",
    mobile: "(530) 306-7541",
    office: "Smiles Clinic, Eastern Clinic, Western Clinic..."
  },
  {
    username: "Newstaff",
    teamMember: "Jenny Joshi",
    role: "Administrators",
    email: "web@practice.com",
    mobile: "(530) 306-7541",
    office: "Smiles Clinic, Eastern Clinic, Western Clinic..."
  }
  // Add more rows as needed
];

const settingsAccessColumns = [
  { field: "username", title: "username" },
  { field: "teamMember", title: "teamMember" },
  { field: "role", title: "role" },
  { field: "email", title: "email" },
  { field: "mobile", title: "mobile" },
  { field: "office", title: "office" }
];

const actions = [
  {
    icon: "edit", // Edit Icon
    title: "Edit",
    onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
  }
];

const SettingsAccess = () => {
  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Access"
      statusOptions={["Active", "Inactive"]}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new chair")}
      addBtnLabel="Roles"
      extraBtnLabel={["User"]} // New button label
      onExtraAction={() => console.log("Export data")} // New button action
    >
      <DynamicGrid
        columns={settingsAccessColumns}
        rows={settingsAccessData}
        actions={actions}
        allowSort={false}
        allowFilter={false}
      />

      <div className="admin-access">
        <h3 style={{ color: "#fff" }}>Administrators Access</h3>
        <div className="status-buttons">
          <label className="status-label">Two Factor Authentication:</label>
          <Switch onLabel="On" offLabel="Off" checked={true} />
          <ActionButton style={{ background: "rgba(167, 176, 69, 1)", color: "black" }} icon="lock">
            Data Protection
          </ActionButton>
        </div>
      </div>

      <PanelBar className="settings-access">
        <PanelBarItem title="Schedule">
          <div className="schedule-options" style={{ padding: "10px" }}>
            <label>
              <input type="checkbox" /> All
            </label>
            <label>
              <input type="checkbox" /> Visits No Lock
            </label>
            <label>
              <input type="checkbox" /> Time Blocks
            </label>
            <label>
              <input type="checkbox" /> Team Coverage
            </label>
          </div>
        </PanelBarItem>
        <PanelBarItem title="Patient and Insurance" />
        <PanelBarItem title="Clinical" />
        <PanelBarItem title="Financial" />
        <PanelBarItem title="Admin" />
        <PanelBarItem title="Analyzer and Huddle" />
        <PanelBarItem title="Setting" />
        <PanelBarItem title="Access Limits" />
      </PanelBar>

      <ActionButton className="access-save-button">Save</ActionButton>
    </DatatableLayout>
  );
};

export default SettingsAccess;

import React, { useEffect, useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { DentalProductService } from "src/services/SettingsService";


const SettingsDentalProduct = () => {
    const [dentalProducts, setDentalProducts] = useState([]);

    useEffect(() => {
      const data = DentalProductService.getDentalProducts();
      setDentalProducts(data);
    }, []);

  const columns = [
    { field: "dentalProduct", title: "Dental Product" },
    { field: "procedureCodes", title: "Procedure Codes" },
    { field: "inactive", title: "Inactive" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];
  return (
    <DatatableLayout
      title="Dental Product"
      onAdd={() => console.log("Add new chair")}
      addBtnLabel="Dental Products"
      saveButton="Save"
    >
      <DynamicGrid
        columns={columns}
        rows={dentalProducts}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default SettingsDentalProduct;

// src/store/actions/fastFillActions.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import fastFillService from "../../services/fastFillService";

// Action to fetch Fast Fills
export const fetchFastFills = createAsyncThunk(
  "fastFill/fetchFastFills",
  async (_, { rejectWithValue }) => {
    try {
      const data = await fastFillService.fetchFastFills();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Action to add a new Fast Fill
export const addFastFill = createAsyncThunk(
  "fastFill/addFastFill",
  async (fastFill, { rejectWithValue }) => {
    try {
      const data = await fastFillService.addFastFill(fastFill);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Action to update an existing Fast Fill
export const updateFastFill = createAsyncThunk(
  "fastFill/updateFastFill",
  async (fastFill, { rejectWithValue }) => {
    try {
      const data = await fastFillService.updateFastFill(fastFill);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Action to delete a Fast Fill
export const deleteFastFill = createAsyncThunk(
  "fastFill/deleteFastFill",
  async (id, { rejectWithValue }) => {
    try {
      await fastFillService.deleteFastFill(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

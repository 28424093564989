import React, { useState } from "react";
import pinIcon from "../../../../../assets/images/pin.png";
import chatIcon from "../../../../../assets/images/chat.png";
import smileyIcon from "../../../../../assets/images/smile.png";
import hatIcon from "../../../../../assets/images/hat.png";
import "./AutomatedText.css";
import Dropdown from "src/components/atoms/Dropdown";
import Label from "src/components/atoms/Label";
import InputField from "src/components/atoms/InputField";
import Button from "src/components/atoms/Button";
import { Hint } from "@progress/kendo-react-labels";

const AutomatedText = () => {
  const max = 300;
  const [value, setValue] = useState("");
  const mergeOptions = ["Merge 1", "Merge 2"];
  const [mergeField, setMergeField] = useState("Merge 1");

  const handleChange = (e) => {
    setValue(e.value);
  };

  const renderIcons = () => (
    <div className="icons-container">
      <img src={pinIcon} alt="Pin" className="icon" title="Pin" />
      <img src={chatIcon} alt="Chat" className="icon" title="Chat" />
      <img src={smileyIcon} alt="Smiley" className="icon" title="Smiley" />
      <img src={hatIcon} alt="Hat" className="icon" title="Hat" />
    </div>
  );

  return (
    <div className="automated-text-container">
      <div className="automated-text-group">
        <Label style={{ width: "120px" }}>Text Template:</Label>
        <InputField />
      </div>

      <div className="automated-text-group">
        <Label style={{ width: "60px" }}>Clinic:</Label>
        <InputField />
      </div>

      <div className="language-section">
        <h4 style={{ top: "283px" }}>English Text</h4>
        <div className="automated-text-group">
          <section className="automated-text-label-plus-dropdown">
            <Label style={{ width: "120px" }}>Merge Fields:</Label>
            <Dropdown
              style={{ width: "200px" }}
              options={mergeOptions}
              value={mergeField}
              onChange={setMergeField}
            />
          </section>

          {renderIcons()}
        </div>
        <div style={{ padding: "10px" }}>
          <InputField
            maxLength={max}
            value={value}
            onChange={handleChange}
          />
          <Hint direction={"end"}>
            {value.length} / {max}
          </Hint>

          <Button className="add-template" icon="plus">
            Translate
          </Button>
        </div>
      </div>

      <div className="language-section">
        <h4 style={{ top: "488px" }}>Spanish Text</h4>
        <div className="automated-text-group">
          <section className="automated-text-label-plus-dropdown">
            <Label style={{ width: "120px" }}>Merge Fields:</Label>
            <Dropdown
              style={{ width: "200px" }}
              options={mergeOptions}
              value={mergeField}
              onChange={setMergeField}
            />
          </section>
          {renderIcons()}
        </div>
        <div style={{ padding: "10px" }}>
          <InputField
            maxLength={max}
            value={value}
            onChange={handleChange}
          />
          <Hint direction={"end"}>
            {value.length} / {max}
          </Hint>

          <Button className="add-template" icon="plus">
            Translate
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AutomatedText;

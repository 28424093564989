import React, { useEffect, useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { PhoneIntegrationService } from "../../../services/SettingsService";
import InputField from "src/components/atoms/InputField";
import Button from "src/components/atoms/Button";

const SettingssPhoneIntegration = () => {
  const [phoneIntegrationData, setPhoneIntegrationData] = useState([]);

  useEffect(() => {
    const data = PhoneIntegrationService.getPhoneIntegration();
    setPhoneIntegrationData(data);
  }, []);

  const columns = [
    { field: "extension", title: "Extension" },
    { field: "name", title: "Extension Name" },
    { field: "users", title: "Users" },
    { field: "description", title: "Description" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "copy", // Delete Icon
      title: "Copy",
      onClick: (dataItem) => alert(`Copied ${dataItem.template || dataItem.treatment}`)
    }
  ];
  return (
    <DatatableLayout
      title="Phone Integration"
      onAdd={() => console.log("Add new chair")}
      addBtnLabel="Extension"
      saveButton="Save"
    >
      <DynamicGrid
        columns={columns}
        rows={phoneIntegrationData}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
      <div style={{ marginTop: "20px", fontSize: "12px", color: "#ccc" }}>
        Note: Automated Reminders are sent via text and email around 8 am in your time zone.
      </div>

      <div style={{ marginTop: "10px", color: "white", fontSize: "14px" }}>
        <strong>This User's Extension to Call Out:</strong>
      </div>

      <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
        <label style={{ marginRight: "10px", color: "white" }}>Mango API Key:</label>
        <InputField
          value="6lt-RnyiDMyvL-FYA==/Kac3I8dl2EWCajDxBvNmUdboeTTJM9_ZPjCXuYn"
          style={{ width: "350px" }}
          disabled
        />
        <Button icon="edit" look="flat" style={{ marginLeft: "10px" }} />
      </div>
    </DatatableLayout>
  );
};

export default SettingssPhoneIntegration;

import { Popup } from "@progress/kendo-react-popup";
import "./Expandable.css";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@progress/kendo-react-common";

const ExpandableMenu = ({ anchor, data, show, onClose, parentId }) => {
  const [childrens, setChildrens] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const childref = useRef(null);
  const navigate = useNavigate();
  const [childrendDirection, setChildrendDirection] = useState("right");
  const elementRef = useRef(null);
  const [activePopup, setActivePopup] = useState(null); // Track active popup component
  const [showPopup, setShowPopup] = useState(false); // Toggle visibility of popup
  const timeoutRef = useRef(null); // To store the timeout ID

  const closePopup = () => {
    setActivePopup(null); // Clear the active popup
    setShowPopup(false); // Hide the popup
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    childref.current = null;
    setChildrens([]);
  }, [parentId]);

  useLayoutEffect(() => {
    if (elementRef.current && elementRef.current._popup) {
      isOnSide(elementRef.current._popup);
    }
    return () => {
      childref.current = null;
      setChildrens([]);
    };
  }, [elementRef, show]);

  const isOnSide = (element) => {
    const rect = element.getBoundingClientRect();
    const right = window.innerWidth - rect.right;
    if (right < 200) {
      setChildrendDirection("left");
    } else {
      setChildrendDirection("right");
    }
  };

  const onRootItemClick = (e, item) => {
    if (item?.route) {
      closePopup();
      setChildrens([]);
      setSelectedItem(null);
      navigate(item.route);
      onClose();
    }
    if (item.children) {
      setSelectedItem(item);
      childref.current = e.currentTarget;
      setChildrens(item.children);
    } else if (item?.popup) {
      setActivePopup(item.popup);
      setShowPopup(true);
      onClose();
    }
  };

  useEffect(() => {
    return () => {
      setChildrens([]);
      childref.current = null;
      onClose();
    };
  }, []);

  const onMouseEnterHandler = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const onMouseLeaveHandler = () => {
    timeoutRef.current = setTimeout(() => {
      setChildrens([]);
      setActivePopup(null);
      setShowPopup(false);
      onClose();
      console.log("indexpage");
    }, 1000);
  };

  const onRootItemHover = (e, item) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (item.children?.length > 0) {
      setSelectedItem(item);
      childref.current = e.currentTarget;
      setChildrens(item.children);
    } else {
      setSelectedItem(null);
      setChildrens([]);
      childref.current = null;
    }
  };

  const onOutClick = () => {
    setChildrens([]);
    childref.current = null;
    onClose();
  };

  const renderChild = () => {
    const data = childrens;
    const anchor = childref;

    return (
      <Popup
        show={childrens.length > 0}
        anchor={anchor && anchor.current}
        onClose={onClose}
        anchorAlign={
          childrendDirection === "right"
            ? { horizontal: "right", vertical: "top" }
            : { horizontal: "left", vertical: "top" }
        }
        popupAlign={
          childrendDirection === "right"
            ? { horizontal: "left", vertical: "top" }
            : { horizontal: "right", vertical: "top" }
        }
        animate={{ openDuration: 0, closeDuration: 0 }}
        popupClass={"expandable-menu"}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        style={{
          width: "210px",
          borderRadius: "10px",
          marginLeft: "10px"
        }}
        positionMode="absolute"
      >
        <div
          className="expandable-menu"
          style={{
            padding: "10px",
            borderRadius: "10px"
          }}
        >
          {data.map((item, index) => (
            <div
              key={index}
              className="expandable-menu-item"
              style={{ cursor: "pointer", padding: "10px", borderRadius: "10px" }}
              onMouseEnter={onMouseEnterHandler}
              onMouseLeave={onMouseLeaveHandler}
              onClick={(e) => onRootItemClick(e, item)}
            >
              <div className="expandable-menu-item-icon" style={{ borderRadius: "10px" }}>
                {item.title}
              </div>
            </div>
          ))}
        </div>
      </Popup>
    );
  };

  return (
    <div>
      <Popup
        ref={elementRef}
        show={show}
        anchor={anchor && anchor.current}
        onClose={onClose}
        animate={{ openDuration: 0.2, closeDuration: 0.2 }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        anchorAlign={
          childrendDirection === "right"
            ? { horizontal: "right", vertical: "top" }
            : { horizontal: "left", vertical: "top" }
        }
        popupAlign={
          childrendDirection === "right"
            ? { horizontal: "left", vertical: "top" }
            : { horizontal: "right", vertical: "top" }
        }
        style={{
          width: "200px",
          padding: "10px",
          borderRadius: "10px",
          marginTop: childrendDirection === "left" ? "15px" : "25px",
          border: "none",
          marginLeft: childrendDirection === "left" ? "35px" : "-35px"
        }}
        positionMode="absolute"
        popupClass={"expandable-menu-popup"}
        onMouseDownOutside={onOutClick}
      >
        <div
          className="expandable-menu"
          style={{
            padding: "10px",
            borderRadius: "10px"
          }}
        >
          {data.map((item, index) => (
            <div
              key={index}
              className="expandable-menu-item"
              style={{ cursor: "pointer", padding: "10px", borderRadius: "10px" }}
              onMouseLeave={onMouseLeaveHandler}
              onClick={(e) => onRootItemClick(e, item)}
              onMouseEnter={(e) => onRootItemHover(e, item)}
            >
              <div
                className="expandable-menu-item-icon"
                style={{
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <span style={{}}>
                  <span
                    style={{
                      height: "7px",
                      width: "7px",
                      borderRadius: "50%",
                      backgroundColor: selectedItem === item ? "green" : "darkgray",
                      display: "inline-block",
                      marginRight: "10px"
                    }}
                  ></span>
                  {item.title}
                </span>
                {item?.children?.length > 0 ? (
                  <span style={{ alignSelf: "end" }}>
                    <Icon name="chevron-right"></Icon>
                  </span>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </Popup>
      {childrens.length > 0 ? renderChild() : null}
      {activePopup && showPopup && (
        <div className="popup-wrapper" onClick={(e) => e.stopPropagation()}>
          {React.cloneElement(activePopup, { show: showPopup, onClose: closePopup })}
        </div>
      )}
    </div>
  );
};

export default ExpandableMenu;

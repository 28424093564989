import React, { useEffect, useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { EPrescribingService } from "src/services/SettingsService";

const SettingsePrescribing = () => {
  const [ePrescribing, setePrescribing] = useState([]);

  useEffect(() => {
    const data = EPrescribingService.getEPrescribing();
    setePrescribing(data);
  }, []);
  const columns = [
    { field: "office", title: "Office" },
    { field: "officeID", title: "Office ID" },
    { field: "officeKey", title: "Office Key" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    }
  ];
  return (
    <DatatableLayout title="ePrescribing">
      <DynamicGrid
        columns={columns}
        rows={ePrescribing}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default SettingsePrescribing;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { LaboratoryService } from "../../../services/AdminService";

const AdminLaboratories = () => {
  const [laboratories, setLaboratories] = useState([]);

  useEffect(() => {
    const data = LaboratoryService.getLaboratories();
    setLaboratories(data);
  }, []);

  const columns = [
    { field: "laboratory", title: "Laboratory" },
    { field: "address", title: "Address" },
    { field: "city", title: "City" },
    { field: "state", title: "State" },
    { field: "zip", title: "Zip" },
    { field: "phone", title: "Phone" },
    { field: "fax", title: "Fax" },
    { field: "email", title: "Email" },
    { field: "comment", title: "Comment" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  const statuses = ["Active", "Inactive"];

  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Laboratory"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new Laboratory")}
      addBtnLabel="Laboratory"
    >
      <DynamicGrid
        columns={columns}
        rows={laboratories}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminLaboratories;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { PaidClaimWoEOBService } from "../../../services/AdminService";

const AdminPaidClaimWoEOB = () => {
  const [paidClaimWoEOB, setPaidClaimWoEOB] = useState([]);

  useEffect(() => {
    const data = PaidClaimWoEOBService.getPaidClaimsWoEOB();
    setPaidClaimWoEOB(data);
  }, []);

  const columns = [
    { field: "patient", title: "Patient" },
    { field: "insurance", title: "Insurance" },
    { field: "servicedate", title: "Service Date" },
    { field: "receiveddate", title: "Received Date" },
    { field: "amount", title: "Amount" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  return (
    <DatatableLayout
      title="Paid Claims without EOBs"
      onAdd={() => console.log("Add new EOB")}
      addBtnLabel="EOB"
    >
      <DynamicGrid
        columns={columns}
        rows={paidClaimWoEOB}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminPaidClaimWoEOB;

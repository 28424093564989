import React, { useState, useEffect, useImperativeHandle, forwardRef, useCallback } from "react";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
  getSelectedState
} from "@progress/kendo-react-grid";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";
import IconButton from "../atoms/IconButton";
import { getter } from "@progress/kendo-react-common";
import "@progress/kendo-theme-default/dist/all.css";
import "./DynamicGrid.css";

const SELECTED_FIELD = "selected"; // Field for tracking selected state

const DynamicGrid = forwardRef(
  (
    {
      columns = [],
      rows = [],
      actions = [],
      dataItemKey = "id",
      allowCheckable = false,
      allowExpand = false,
      allowSort = true,
      allowFilter = false,
      DetailComponent,
      pageSizes = [5, 10, 20],
      buttonCount = 5,
      info = true,
      type = "numeric",
      pageSize = 10,
      pageable = false,
      noRecordMessage = "There are no records",
      onSelectedRowsChange,
      className = "",
      ...rest
    },
    ref
  ) => {
    const idGetter = getter(dataItemKey);
    const [dataState, setDataState] = useState({
      skip: 0,
      take: pageSize,
      sort: [],
      filter: []
    });
    const [data, setData] = useState(rows);
    const [selectedState, setSelectedState] = useState({});
    const _export = React.useRef(null);

    useEffect(() => {
      setData(rows.map((item) => ({ ...item  })));
    }, [rows]);

    useImperativeHandle(ref, () => ({
      printGrid,
      exportExcel
    }));

    const printGrid = () => {
      window.print();
    };

    const exportExcel = () => {
      if (_export.current) {
        _export.current.save();
      }
    };

    const expandChange = (event) => {
      const newData = data.map((item) => {
        if (item[dataItemKey] === event.dataItem[dataItemKey]) {
          item.expanded = !event.dataItem.expanded;
        }
        return item;
      });
      setData(newData);
    };

    const onDataStateChange = (event) => {
      setDataState(event.dataState);
    };

    const onSelectionChange = useCallback(
      (event) => {
        const newSelectedState = getSelectedState({
          event,
          selectedState: selectedState,
          dataItemKey: dataItemKey
        });
        setSelectedState(newSelectedState);
        onSelectedRowsChange &&
          onSelectedRowsChange(Object.keys(newSelectedState).filter((id) => newSelectedState[id]));
      },
      [selectedState, onSelectedRowsChange, dataItemKey]
    );

    const onHeaderSelectionChange = useCallback(
      (event) => {
        const checked = event.syntheticEvent.target.checked;
        const newSelectedState = {};
        data.forEach((item) => {
          newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
        onSelectedRowsChange &&
          onSelectedRowsChange(Object.keys(newSelectedState).filter((id) => newSelectedState[id]));
      },
      [data, onSelectedRowsChange, idGetter]
    );

    const processedData = process(data, dataState);

    const renderActions = (props) => {
      const dataItem = props.dataItem;
      return (
        <td>
          {actions &&
            actions.map((action, index) =>
              action.render ? (
                <div key={index}>{action.render(dataItem)}</div>
              ) : (
                <IconButton
                  key={index}
                  icon={action.icon}
                  onClick={() => action.onClick(dataItem)}
                />
              )
            )}
        </td>
      );
    };

    return (
      <>
        <ExcelExport data={data} ref={_export}>
          {columns.map((col, idx) => (
            <ExcelExportColumn key={idx} field={col.field} title={col.title} width={col.width} />
          ))}
        </ExcelExport>
        <div id="printable-grid-container">
          <Grid
            className={className}
            data={
              allowCheckable
                ? processedData.data.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)]
                  }))
                : processedData.data
            }
            sortable={allowSort}
            filterable={allowFilter}
            pageable={
              pageable && {
                pageSizes: pageSizes,
                buttonCount: buttonCount,
                info: info,
                type: type
              }
            }
            dataItemKey={dataItemKey}
            selectedField={SELECTED_FIELD}
            selectable={
              allowCheckable
                ? {
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple"
                  }
                : false
            }
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            skip={dataState.skip}
            take={dataState.take}
            total={data.length}
            onDataStateChange={onDataStateChange}
            detail={
              allowExpand && DetailComponent
                ? (props) => <DetailComponent dataItem={props.dataItem} />
                : null
            }
            expandField={allowExpand ? "expanded" : undefined}
            onExpandChange={allowExpand ? expandChange : undefined}
            {...dataState}
            {...rest}
          >
            <GridNoRecords>{noRecordMessage}</GridNoRecords>

            {allowCheckable && (
              <Column
                field={SELECTED_FIELD}
                width="50px"
                headerSelectionValue={data.every((item) => selectedState[idGetter(item)])}
              />
            )}

            {columns.map((col, idx) => (
              <Column
                key={idx}
                field={col.field}
                title={col.title}
                width={col.width}
                sortable={col.sortable !== undefined ? col.sortable : allowSort}
                filterable={col.filterable !== undefined ? col.filterable : allowFilter}
                cell={col.cell}
                {...col}
              />
            ))}

            {actions.length > 0 && actions.length > 0 && <Column title="Actions" width="150px" cell={renderActions} />}
          </Grid>
        </div>
      </>
    );
  }
);

export default DynamicGrid;

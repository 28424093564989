import React, { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import "./SettingsOfficeGoals.css"; // Import custom styles
import SchedulerDrawer from "../../../organisms/Drawer/HomePageDrawer";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import Visits from "./SubPages/Visits";
import PatientsAndVisits from "./SubPages/PatientsAndVisits";
import HygieneRecare from "./SubPages/HygieneRecare";
import CollectionsAndBilling from "./SubPages/CollectionsAndBilling";
import Dropdown from "src/components/atoms/Dropdown";

const SettingsOfficeGoals = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [clinicians, setClinicians] = useState("Dr. John Doe");

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  const clinicianOptions = ["Dr. John Doe", "Dr. Jane Smith", "Dr. Alex Brown"];

  return (
    <div className="content-wrapper">
      <div className="sidebar">
        <SchedulerDrawer />
      </div>

      {/* Main content */}
      <div className="settings-goal-main-content">
        <div className="tab-buttons-container">
          <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
            <TabStripTab title="Visits" />
            <TabStripTab title="Patients and Visits" />
            <TabStripTab title="Hygiene Recare" />
            <TabStripTab title="Collections and billing" />
          </TabStrip>
          <div className="settings-goal-dropdown-container">
            <label className="settings-goal-dropdown-label" htmlFor="clinician-dropdown">
              Clinician
            </label>
            <Dropdown options={clinicianOptions} value={clinicians} onChange={setClinicians}/>
          </div>
        </div>

        {/* Tab Content */}
        <div className="settings-goal-tab-content">
          {selectedTab === 0 && <Visits />}
          {selectedTab === 1 && <PatientsAndVisits />}
          {selectedTab === 2 && <HygieneRecare />}
          {selectedTab === 3 && <CollectionsAndBilling />}
        </div>
      </div>
    </div>
  );
};

export default SettingsOfficeGoals;

import React, { useState, useEffect } from "react";
import CommonPopup from "../../../molecules/CommonPopup";
import DynamicForm from "../../../organisms/DynamicForm";
import "./Alert.css"
const AlertPopup = ({ show, onClose, onSubmit, initialData }) => {
  const [formData, setFormData] = useState({
    patient: "",
    applyTo: "",
    createdDate: "",
    createdUser: "",
    alertText: "",
    paused: false
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData); // Set form data to initialData if editing
    } else {
      setFormData({
        patient: "",
        applyTo: "",
        createdDate: "",
        createdUser: "",
        alertText: "",
        paused: false
      }); // Reset form data for a new alert
    }
  }, [initialData]);

  const handleFormSubmit = () => {
    onSubmit(formData);
  };

  const formStructure = {
    title: "Alert",
    tabs: [
      {
        name: "Alert",
        sections: [
          {
            sectionName: "Alerts",
            fields: [
              { label: "Patient", name: "patient", type: "label", value: formData.patient },
              { label: "Apply To", name: "applyTo", type: "input" },
              { label: "Created", name: "createdDate", type: "label", value: formData.createdDate },
              { label: "Created", name: "createdUser", type: "label", value: formData.createdUser },
              { label: "Alert", name: "alertText", type: "textarea" },
              { label: "Paused", name: "paused", type: "checkbox" }
            ]
          }
        ],
        footer: {
          right: [
            {
              type: "button",
              icon: "trash",
              className: "delete-button",
              text: "Delete",
              onClick: () => console.log("Delete")
            },
            {
              type: "button",
              icon: "save",
              className: "save-button",
              text: "Save",
              onClick: handleFormSubmit
            }
          ]
        }
      }
    ]
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Alert" className="alert">
      <DynamicForm
        tabs={formStructure.tabs}
        formData={formData}
        handleFormSubmit={handleFormSubmit}
        handleInputChange={(value, fieldName) =>
          setFormData((prev) => ({ ...prev, [fieldName]: value }))
        }
      />
    </CommonPopup>
  );
};

export default AlertPopup;

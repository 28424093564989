import React from "react";
import InputField from "src/components/atoms/InputField";
import Label from "src/components/atoms/Label";
import DatatableLayout from "src/components/templates/Layout/DatatableLayout";
import "./SettingsXRayImaging.css";

const SettingsPatientEducation = () => {
  return (
    <DatatableLayout title="Patient Education" saveButton="Save">
      <div style={{ display: "flex", color: "white", margin: "10px",width:"700px" }}>
        <Label style={{ width: "50%" }}>Patient Education Office ID:</Label>
        <InputField type="text" />
      </div>
    </DatatableLayout>
  );
};

export default SettingsPatientEducation;

import React, { useState, useEffect } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { ConnectionService } from "../../../services/AdminService";

const AdminConnections = () => {
  const [connections, setConnections] = useState([]);

  useEffect(() => {
    const data = ConnectionService.getConnections();
    setConnections(data);
  }, []);

  const columns = [
    { field: "name", title: "Name" },
    { field: "address", title: "Address" },
    { field: "city", title: "City" },
    { field: "state", title: "State" },
    { field: "zip", title: "Zip" },
    { field: "phone", title: "Phone" },
    { field: "fax", title: "Fax" },
    { field: "email", title: "Email" },
    { field: "comment", title: "Comment" },
    { field: "group", title: "Group" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];

  const statuses = ["Active", "Inactive"];

  const [status, setStatus] = useState("Active");

  return (
    <DatatableLayout
      title="Connections"
      statusOptions={statuses}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new Connection")}
      addBtnLabel="Connection"
    >
      <DynamicGrid
        columns={columns}
        rows={connections}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default AdminConnections;

import React, { useState } from 'react';
import CommonPopup from '../../../molecules/CommonPopup'; // Assuming CommonPopup is reusable
import DynamicForm from '../../../organisms/DynamicForm'; // Assuming DynamicForm is reusable
import IntervalSection from '../../../atoms/IntervalSection';

const HygieneRecarePopup = ({ show, onClose }) => {
  // Initial form data state
  const [formData, setFormData] = useState({
    treatment: 'option1',  // Default option
    previous: null,
    recareDue: null,
    intervalYears: 0,
    intervalMonths: 0,
    intervalWeeks: 0,
    intervalDays: 0,
    scheduled: null,
    editRecare: null,
    followUp: '',
    comment: '',
    permanentHold: false,
    balanceAbove: '',
    endDate: null,
  });

  // Handle form submission when the "Save" button is clicked
  const handleSaveClick = () => {
    console.log('Form data on save:', formData);
    // Add your save logic here
  };

  // Update formData when form inputs change
  const handleInputChange = (value, fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const formStructure = {
    title: "Hygiene Recare",
    tabs: [
      {
        name: "Recare",
        header: null, // No header in this form
        sections: [
          {
            sectionName: "Recare",
            fields: [
              {
                label: "Treatment",
                name: "treatment",
                type: "select",
                placeholder: "Select Treatment",
                options: [
                  { label: "Option 1", value: "option1" },
                  { label: "Option 2", value: "option2" },
                ],
              },
              {
                label: "Previous",
                name: "previous",
                type: "date",
                placeholder: "month/day/year",
              },
              {
                label: "Recare Due",
                name: "recareDue",
                type: "date",
                placeholder: "month/day/year",
              },
              {
                label: "Interval",
                name: "interval",
                type: "custom",
                component: (
                  <IntervalSection />
                ),
              },
              {
                label: "Scheduled",
                name: "scheduled",
                type: "date",
                placeholder: "month/day/year",
              },
              {
                label: "Edit Recare",
                name: "editRecare",
                type: "date",
                placeholder: "month/day/year",
              },
              {
                label: "Follow Up",
                name: "followUp",
                type: "input",
                placeholder: "",
              },
              {
                label: "Comment",
                name: "comment",
                type: "input",
                placeholder: "",
              },
            ],
          },
          {
            sectionName: "Stop Recare",
            fields: [
              {
                label: "Permanent Hold",
                name: "permanentHold",
                type: "checkbox",
              },
              {
                label: "Balance Above",
                name: "balanceAbove",
                type: "input",
                placeholder: "",
              },
              {
                label: "End Date",
                name: "endDate",
                type: "date",
                placeholder: "month/day/year",
              },
            ],
          },
        ],
        footer: {
          right: [
            {
              type: "button",
              text: "Delete",
              onClick: () => alert("Delete clicked"),
              icon: "delete",
            },
            {
              type: "button",
              text: "Save",
              onClick: handleSaveClick, // Use the save click handler
              icon: "save",
            },
          ],
        },
      },
    ],
  };

  return (
    <CommonPopup show={show} onClose={onClose} title="Hygiene Recare">
      <DynamicForm
        tabs={formStructure.tabs}
       formData={formData} // Pass the formData to DynamicForm
        handleInputChange={handleInputChange} // Pass handleInputChange to update formData
      />
    </CommonPopup>
  );
};

export default HygieneRecarePopup;

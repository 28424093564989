import React, { useState } from 'react';
import CommonPopup from '../../molecules/CommonPopup';
import DynamicForm from '../../organisms/DynamicForm';
import { Button } from '@progress/kendo-react-buttons';

const VisitSummariesPopup = ({ show, onClose }) => {
    const [formData, setFormData] = useState({});

    const handleFormSubmit = (data) => {
        console.log("Form submitted with data:", data);
        setFormData(data);
    };

    // Toggle function for button states
    const toggleButtonState = (fieldName) => {
        setFormData((prev) => ({
            ...prev,
            [fieldName]: !prev[fieldName], // Toggle true/false
        }));
    };

    const formStructure = {
      title: "Visit Summaries",
      tabs: [
        {
          name: "Visit Summaries",
          sections: [
            {
              sectionName: "Search",
              fields: [
                {
                  label: "Dates",
                  name: "dates",
                  type: "daterangepicker" // Use a custom type to identify the DateRangePicker
                },
                //   {
                //     type: "button",
                //     text: "",
                //     icon: "calendar",
                //     onClick: () => toggleButtonState("today"),
                //     tooltip: "Today",
                //     labelPosition: "internal",
                //   },
                //   {
                //     type: "button",
                //     text: "",
                //     icon: formData.displayed ? "calendar-check" : "calendar",
                //     onClick: () => toggleButtonState("displayed"),
                //     tooltip: "Displayed",
                //     labelPosition: "internal",
                //   },
                {
                  label: "Order By",
                  name: "orderBy",
                  type: "select",
                  options: [
                    { label: "By Chair", value: "byChair" },
                    { label: "By Clinician", value: "byClinician" }
                  ]
                },
                {
                  label: "Clinician",
                  name: "clinician",
                  type: "select",
                  options: [
                    { label: "All", value: "all" }
                    // Add specific clinician options here if needed
                  ]
                }
              ]
            }
          ],
          footer: {
            right: [
              {
                type: "button",
                text: "Generate",
                icon: "generate",
                onClick: () => console.log("Generate clicked")
              },
              {
                type: "button",
                text: "Print",
                icon: "print",
                onClick: () => console.log("Print clicked")
              }
            ]
          }
        }
      ]
    };

    return (
        <CommonPopup show={show} onClose={onClose} title="Visit Summaries">
            <DynamicForm
                tabs={formStructure.tabs}
                handleFormSubmit={handleFormSubmit}
                formData={formData}
                handleInputChange={(value, fieldName) => setFormData((prev) => ({ ...prev, [fieldName]: value }))}
            />
        </CommonPopup>
    );
};

export default VisitSummariesPopup;

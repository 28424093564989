import React from "react";
import { Switch } from "@progress/kendo-react-inputs";
import Label from "src/components/atoms/Label";

const BirthdayWishes = () => {
  return (
    <div
      style={{
        padding: "20px",
        color: "#fff",
        border: "1px solid #444",
        borderRadius: "8px",
        width: "fit-content",
        margin:"20px"
      }}
    >
      <h4 style={{ position: "absolute", top: "168px" }}>Birthday Wishes</h4>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Label>Send Birthday Wishes:</Label>
        <Switch defaultChecked={true} />
      </div>
    </div>
  );
};

export default BirthdayWishes;

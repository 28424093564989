import React, { useState } from "react";
import DynamicForm from "../../../organisms/DynamicForm"; // Import DynamicForm component
import HealthInfo from "./Info";
import HealthIssues from "./HealthIssues";
import Medications from "./Medications";
import Allergies from "./Allergies";
import FamilyHealth from "./FamilyHealth";
import VitalSigns from "./VitalSigns";
import './healthinfo.css';

const HealthInfoTabs = () => {
    // Define form structure with tabs and sections, including custom components
    const formStructure = {
        title: "Health Information",
        tabs: [
            {
                name: "Health Info",
                sections: [
                    {
                        fields: [
                            {
                                label: "Pre-medication",
                                name: "preMedication",
                                type: "checkbox",
                            },
                            {
                                label: "Default response",
                                name: "defaultResponse",
                                type: "textarea",
                                rows: 3,
                                placeholder: "Enter default response",
                            },

                            {
                                label: "Health Alert",
                                name: "healthAlert",
                                type: "textarea",
                                rows: 3,
                                placeholder: "Enter health alert information",
                            },

                            {
                                label: "Special Care",
                                name: "specialCare",
                                type: "textarea",
                                rows: 3,
                                placeholder: "Enter special care instructions",
                            },
                        ],
                    },
                ],

            },
            {
                name: "Health Issue",
                header: {
                    left: [
                        {
                            label: "Inactive",
                            name: "Inactive",
                            type: "checkbox",
                            labelPosition: "internal"
                        },
                    ],
                    right: [
                      
                        {
                            type: "button",
                            text: "Health Issues",
                            icon: "plus",
                            onClick: () => alert("Family Recare clicked"),
                        }

                    ],
                },
                sections: [
                    {
                        customSection:
                            <HealthIssues />,


                    },

                ],


            },
            {
                name: "Medication",
                sections: [
                    {
                        customSection: <Medications />, // Custom component for Medications tab
                    },
                ],
            },
            {
                name: "Allergies",
                sections: [
                    {
                        customSection: <Allergies />, // Custom component for Allergies tab
                    },
                ],
            },
            {
                name: "Family Health",
                sections: [
                    {
                        customSection: <FamilyHealth />, // Custom component for Family Health tab
                    },
                ],
            },
            {
                name: "Vital Signs",
                sections: [
                    {
                        customSection: <VitalSigns />, // Custom component for Vital Signs tab
                    },
                ],
            },
        ],
    };

    // Function to handle form submission
    const handleFormSubmit = (data) => {
        console.log("Form submitted with data:", data);
    };

    return (
        <DynamicForm
            tabs={formStructure.tabs}
            handleFormSubmit={handleFormSubmit}
        />
    );
}

export default HealthInfoTabs;

import React, { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import SchedulerDrawer from "../../../organisms/Drawer/HomePageDrawer";
import VisitReminderSettings from "./SubPages/VisitReminders";
import RecareReminderSettings from "./SubPages/RecareReminders";
import BirthdayWishes from "./SubPages/BirthdayWishes";

const SettingsAutomatedReminders = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  return (
    <div className="content-wrapper">
      <div className="sidebar">
        <SchedulerDrawer />
      </div>

      {/* Main content */}
      <div className="settings-goal-main-content">
        <div className="tab-buttons-container">
          <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
            <TabStripTab title="Visit Reminders and Confirmation" />
            <TabStripTab title="Recare Reminders" />
            <TabStripTab title="Birthday Wishes" />
          </TabStrip>
        </div>

        {/* Tab Content */}
        <div className="settings-goal-tab-content">
          {selectedTab === 0 && <VisitReminderSettings />}
          {selectedTab === 1 && <RecareReminderSettings />}
          {selectedTab === 2 && <BirthdayWishes />}
        </div>
      </div>
    </div>
  );
};

export default SettingsAutomatedReminders;

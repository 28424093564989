import React, { useEffect, useState } from "react";
import DatatableLayout from "../../templates/Layout/DatatableLayout";
import DynamicGrid from "../../organisms/DynamicGrid";
import { ChairsService } from "../../../services/SettingsService";

const SettingsChairs = () => {
  const [chairs, setChairs] = useState([]);
  const [status, setStatus] = useState("Active");

  useEffect(() => {
    const data = ChairsService.getChairs();
    setChairs(data);
  }, []);

  const columns = [
    { field: "chair", title: "Chair" },
    { field: "abbreviation", title: "Abbreviation" },
    { field: "clinician", title: "Clinician" },
    { field: "hygienist", title: "Hygienist" },
    { field: "hygiene", title: "Hygiene" }
  ];

  const actions = [
    {
      icon: "edit", // Edit Icon
      title: "Edit",
      onClick: (dataItem) => alert(`Edit ${dataItem.template || dataItem.treatment}`)
    },
    {
      icon: "delete", // Delete Icon
      title: "Delete",
      onClick: (dataItem) => alert(`Delete ${dataItem.template || dataItem.treatment}`)
    }
  ];
  return (
    <DatatableLayout
      title="Chairs"
      statusOptions={["Active", "Inactive"]}
      status={status}
      onStatusChange={setStatus}
      onAdd={() => console.log("Add new chair")}
      addBtnLabel="Chairs"
    >
      <DynamicGrid
        columns={columns}
        rows={chairs}
        allowSort={false}
        allowFilter={false}
        actions={actions}
      />
    </DatatableLayout>
  );
};

export default SettingsChairs;
